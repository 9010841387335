import {HeroDisplayWithImage} from "../components/layout.js";
import {Card, Grow} from "@mui/material";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import CreateFirstProject from "../user/CreateFirstProject.js";
import ProjectCard from "./ProjectCard.js";
import React, {useEffect, useState} from "react";
import properties from "../general/properties.js";
import axios from "axios";
import Loading from "../general/Loading.js";

export const UserProjects = () => {

    const [loading, setLoading] = useState(true);

    const [userProjectsData, setUserProjectsData] = useState({});

    const id = localStorage.getItem("profileId");

    useEffect(() => {

        let endpoints = [properties.backendServer + '/sg/user/projects/' + id];

        axios.all(endpoints.map((endpoint) => axios.get(endpoint))).then((data) => {
            setUserProjectsData(data[0].data);
            setLoading(false);
        },)

    }, [id]);


    if (loading) {
        return <Loading/>
    } else return (
        <>
            <HeroDisplayWithImage>
                <Grow in={true} timeout={4000} direction={"left"}>
                    <Box sx={{padding: '20'}}>
                        <Grid container padding={{xs: "5px", md: "50px"}} justifyContent="space-around"
                              alignItems="space-around"
                              spacing={2}>

                            <CreateFirstProject data={userProjectsData}/>

                            {userProjectsData.map((value, id) => (
                                <Grid key={id} item xs={12} sm={9} md={6} lg={6} xl={4}>

                                    <Card item={value.id} variant="outlined">
                                        <ProjectCard projectData={value}/>
                                    </Card>

                                </Grid>))}

                        </Grid>
                    </Box>
                </Grow>
            </HeroDisplayWithImage>
        </>)
}
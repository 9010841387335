import {HeroDisplayStretch} from "../components/layout.js";
import directing from "../directorschair.png";
import Container from "@mui/material/Container/Container";
import Box from "@mui/material/Box/Box";
import SmallCenteredFormHeader from "../components/SmallCenteredFormHeader.js";
import anger from "../londonCalling.jpg";
import SmallActionButtons from "../components/SmallActionButtons.js";
import React, {useContext} from "react";
import axios from "axios";
import properties from "./properties.js";
import {useNavigate} from "react-router-dom";
import UserContext from "../user/UserContext.js";
import {isAuthenticated} from "./api.js";

let ErrorPage = () => {

    const navigate = useNavigate();
    const {logoutUser} = useContext(UserContext);

    const cancel = () => {

        navigate('/userView/' + localStorage.getItem("profileId"));

    }

    const home = () => {

        navigate('/');

    }

    const logout = (event) => {

        axios({
            method: 'get', url: properties.backendServer + '/logout',
        }).then(() => {
            logoutUser();
            navigate('/');
        }).catch(() => {
            logoutUser();
            navigate('/userView/' + localStorage.getItem("profileId"));
        });

        event.preventDefault();
    }

    return (<HeroDisplayStretch sx={{backgroundImage: `url(${directing})`}}>
        <Container component="main" maxWidth="xs" sx={{borderRadius: "5px"}}>
            <Box sx={{
                flexDirection: 'column',
                alignItems: 'center',
                backgroundColor: '#F0F8FF',
                padding: "20px",
                width: '100%'
            }}>
                <SmallCenteredFormHeader img={anger} title={"Oops"}/>

                {isAuthenticated() ?
                    <SmallActionButtons firstText={"Logout"} firstFunction={logout} secondText={"My Profile"}
                                        secondFunction={cancel} direction={"column"}/> :
                    <SmallActionButtons firstText={"Home page"} firstFunction={home}/>}
            </Box>
        </Container>
    </HeroDisplayStretch>);

}

export default ErrorPage;
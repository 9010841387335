import React, {useEffect, useState} from "react";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import {Dialog, DialogActions, DialogContent, DialogTitle, InputLabel, Stack, Typography} from "@mui/material";
import Button from "@mui/material/Button";


const UserFilterModal = ({isFilterOpen, setFilterOpen, handlePageReload, isFilterOn, setFilterOn}) => {


    let [filter, setFilter] = useState({
        "page": 0, "nameSearchText": "", "sortBy": ""
    });

    const [isMasterFilterOn, setMasterFilterOn] = useState(false);

    function onClose() {

        setFilterOpen(false);
    }

    const handleFilterClose = () => {
        setFilterOpen(!isFilterOpen);
    }


    useEffect(() => {

        const filterStore = JSON.parse(localStorage.getItem('userFilter'));

    }, [isFilterOpen]);

    const handleFilterSelect = () => {


        localStorage.setItem('userFilter', JSON.stringify(filter));
        setFilterOpen(false);
        setFilterOn(true);
        handlePageReload();

    }

    const handleMasterFilterToggle = () => {

        filter.page = 0;
        filter.sortBy = ""; // TODO prob wants to be default sort
        filter.titleSearchText = "";

        localStorage.setItem('projectFilter', JSON.stringify(filter));
        setFilterOpen(false);
        setFilterOn(false);
        handlePageReload();
        setMasterFilterOn(false)

    }


    return (
        <Dialog
            fullWidth
            maxWidth="md"
            open={isFilterOpen}
            onClose={() => onClose()}
        >
            <DialogTitle><Stack justifyContent="space-between" direction="row"><Typography>Set
                Filters {isMasterFilterOn} </Typography> {isMasterFilterOn ? <Button variant="contained"
                                                                                     color="primary"
                                                                                     onClick={handleMasterFilterToggle}>clear
                filter</Button> : <></>}
            </Stack></DialogTitle>
            <DialogContent>
                <Stack spacing={2}>
                    <InputLabel id="sortOrder">sort order</InputLabel>
                    <Select
                        id="sortOrder"
                        value={filter.sort}
                    >
                        <MenuItem value={10}>Title</MenuItem>
                        <MenuItem value={20}>ScriptVender Order</MenuItem>
                        <MenuItem value={30}>Writer Location</MenuItem>
                    </Select>
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button variant="contained"
                        color="primary" onClick={handleFilterSelect}>Set Filter</Button>
                <Button variant="contained"
                        color="primary" onClick={handleFilterClose}>Cancel</Button>
            </DialogActions>
        </Dialog>
    );
}

export default UserFilterModal;
import TextField from "@mui/material/TextField";
import React from "react";

const EmailAndName = ({user, handleChange, emailValidation, nameValidation,}) => {


    return (<>
        <TextField
            name="name"
            variant="outlined"
            required
            fullWidth
            id="name"
            label="Name"
            autoFocus
            onChange={handleChange}
            value={user.firstName}
            error={nameValidation.error}
            helperText={nameValidation.message}
        />
        <TextField
            variant="outlined"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            onChange={handleChange}
            value={user.email}
            error={emailValidation.error}
            helperText={emailValidation.message}
        />
    </>)
}

export default EmailAndName;
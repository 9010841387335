import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import {getProjectRequestStructure, loadProjectAndSet, putProject} from "../general/api.js";
import ProjectUpdate from "./ProjectUpdate.js";
import Loading from "../general/Loading.js";


export default function ProjectEdit() {


    const {id} = useParams();
    const navigate = useNavigate();

    let [loadedProject, setLoadedProject] = useState({created: false, id: 0});

    let [project, setProject] = useState(getProjectRequestStructure);

    useEffect(() => {

        loadProjectAndSet(id, setProject, setLoadedProject);

    }, [id]);


    let save = (e) => {

        putProject(id, project)
            .then(() => navigate('/projectView/' + id));

        e.preventDefault();
    }

    let cancel = (e) => {

        navigate('/projectView/' + id);

        e.preventDefault();
    }

    if (!loadedProject.created) {
        return <Loading/>
    } else {
        return (
            <ProjectUpdate project={project} setProject={setProject} saveData={save} cancel={cancel}/>
        );
    }
}

import React, {useEffect, useState} from 'react';
import directing from "../directorschair.png";
import Avatar from "@mui/material/Avatar";
import MovieCreationIcon from '@mui/icons-material/MovieCreation';
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import {DropzoneArea} from "material-ui-dropzone";
import Box from "@mui/material/Box";
import {getProject} from "../general/api.js";
import axios from "axios";
import {Navigate, useParams} from "react-router-dom";
import SaveCancel from "../general/SaveCancel.js";
import {HeroDisplayStretch} from "../components/layout.js";
import properties from "../general/properties.js";
import {Stack} from "@mui/material";
import TextField from "@mui/material/TextField";

export default function ScreenplayExtract() {

    const {id} = useParams();

    const [files, setFiles] = useState([]);

    const [project, setProject] = useState([]);

    const [endPage, setEndPage] = useState({})

    const [extractData, setExtractData] = useState({
        "startPage": 0, "endPage": 0
    })

    const [saveEnabled, setSaveEnabled] = useState(true);

    let [redirectProject, setRedirectProject] = useState({created: false, id: 0});

    const [loading, setLoading] = useState(false)

    function fileAdded() {

        setSaveEnabled(false);


    }

    useEffect(() => {

        getProject(id)
            .then(response => {
                setProject(response.data);
            });

    }, [id]);


    let save = (e) => {

        const url = properties.backendServer + '/sg/project/screenplay/extract/' + id;

        let formData = new FormData();
        formData.append("file", files[0], project.titleEllipsis + Date.now() + '.png');
        formData.append('extractBody', JSON.stringify(extractData));

        axios.post(url, formData, {
            transformRequest: () => formData,
        }).then(result => setRedirectProject({created: true, id: id})).catch(err => {
            if (err.response.status === 400) {
                err.response.data.formErrors.forEach(v => {

                    if (v.field === 'endPage') {
                        setEndPage({message: v.message, error: true});
                    }

                });
            }
            setSaveEnabled(false);
            console.error(err);
        });

        e.preventDefault();
    }

    let cancel = () => {

        setRedirectProject({created: true, id: id})

    };

    let handleUpload = (files) => {
        setFiles(files);
    }

    const handleChange = (event) => {

        // TODO do this neat handleChange everywhere
        setExtractData({...extractData, [event.target.name]: event.target.value})

        if (event.target.value !== '') {
            if (event.target.id === 'endPage') {
                setEndPage({message: '', error: false})
            }
        }

    }


    if (redirectProject.created) {
        return <Navigate to={`/projectView/${id}`}/>
    } else {
        return (<HeroDisplayStretch sx={{backgroundImage: `url(${directing})`, color: 'black'}}>
            <Box mt={8} p={3}
                 sx={{display: 'flex', flexDirection: 'column', alignItems: 'center', backgroundColor: '#F0F8FF'}}>
                <Avatar m={1} sx={{backgroundColor: 'palette.action'}}>
                    <MovieCreationIcon/>
                </Avatar>
                <Typography component="h1" variant="h5">
                    Upload Pdf Extract for {project.title}
                </Typography>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                        <DropzoneArea onChange={handleUpload}
                                      acceptedFiles={['application/pdf']}
                                      showPreviews={false}
                                      showPreviewsInDropzone={false}
                                      showAlerts={false}
                                      maxFileSize={5000000}
                                      filesLimit={1}
                                      onDrop={fileAdded}
                                      dropzoneText={'Drag and drop screenplay as a pdf, or click'}/>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Box
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                        >
                            <Box component={"img"} sx={{width: "80%", paddingBottom: "20px;", borderRadius: "5px"}}
                                 src={files[0] != null ? URL.createObjectURL(files[0]) : 'test'}/>
                        </Box>
                        <Stack gap={2}>
                            <TextField
                                name="startPage"
                                variant="outlined"
                                required
                                fullWidth
                                id="startPage"
                                label="Extract start page"
                                onChange={handleChange}
                                value={extractData.startPage}
                                disabled={saveEnabled}
                                helperText={"page on the uploaded pdf you wish extract to start"}
                            />
                            <TextField
                                name="endPage"
                                variant="outlined"
                                required
                                fullWidth
                                id="endPage"
                                label="Extract end page"
                                onChange={handleChange}
                                value={extractData.endPage}
                                disabled={saveEnabled}
                                helperText={"page on the uploaded pdf you wish extract to end"}
                                error={endPage.error}
                            />
                        </Stack>

                    </Grid>
                    <Grid item xs={12}>
                        <SaveCancel save={save} cancel={cancel} saveEnabled={saveEnabled}
                                    setSaveEnabled={setSaveEnabled} loading={loading} setLoading={setLoading}/>
                    </Grid>

                </Grid>

            </Box>
        </HeroDisplayStretch>);
    }
}



import React, {useEffect, useState} from 'react';
import Grid from '@mui/material/Grid';
import {Card, Grow} from "@mui/material";
import Typography from "@mui/material/Typography";
import directing from "../directorschair.png";
import Box from "@mui/material/Box";
import axios from "axios";
import {HeroDisplayWithImage} from "../components/layout";
import SgPagination from "../components/SgPagination.js";
import HeaderBanner from "../components/HeaderBanner.js";
import properties from "../general/properties.js";
import UserCard from "./UserCard.js";
import UserFilter from "./UserFilter.js";
import UserFilterModal from "./UserFilterModal.js";
import Loading from "../general/Loading.js";


export default function UserList() {

    const handlePageChange = (event, value) => {
        setPage(value - 1);

        userRequest.page = value - 1;

        setUserRequest({...userRequest, userRequest});

    };

    function handlePageReload() {
        setReload(!reload);
    }

    const [data, setData] = useState([]);

    const [loading, setLoading] = useState(true);

    const [pageTotal, setPageTotal] = useState(0);

    const [page, setPage] = useState(0);

    const [reload, setReload] = useState(false);

    const [isFilterOn, setFilterOn] = useState(false);

    const [isFilterOpen, setFilterOpen] = useState(false);

    let [userRequest, setUserRequest] = useState({
        "page": 0, "nameSearchText": "", "sortBy": "", "country": ""
    });

    let handleSearchChange = (event) => {

        setUserRequest({...userRequest, [event.target.name]: event.target.value})

        if (event.target.name === 'nameSearchText' && event.target.value.length === 0) {
            setReload(!reload);
            return;
        }

        if (event.target.name === 'nameSearchText' && event.target.value.length < 3) {

        } else {
            setReload(!reload);
        }

    }

    let clearSearchText = () => {
        userRequest.nameSearchText = "";

        setUserRequest({...userRequest, userRequest});

        setReload(!reload);

    }

    useEffect(() => {


        userRequest.type = 1;

        axios({
            method: 'post', url: properties.backendServer + '/sg/users', data: userRequest,
        }).then(response => {
            setData(response.data);
            setLoading(false);
            setPageTotal(Math.ceil(data.total / 10));

        });

    }, [page, reload]);

    if (loading) {
        return (<Loading/>)
    } else if (data.users.map == null) {
        return (<div>None found</div>)
    } else return (<>
            <HeaderBanner mainText={"Writers"} secondaryText={"visible to you"}>
                <UserFilter userRequest={userRequest} handleSearchChange={handleSearchChange}
                            clearSearchText={clearSearchText}
                            isFilterOpen={isFilterOpen} setFilterOpen={setFilterOpen} isFilterOn={isFilterOn}
                            setFilterOn={setFilterOn}/>
            </HeaderBanner>
            <HeroDisplayWithImage backgroundimg={`url(${directing})`} backgroundRep={'repeat-y'}>
                <Grow in={true} timeout={4000} direction={"left"}>
                    <Box sx={{padding: '20'}}>
                        <Grid container padding={{xs: "5px", md: "50px"}} justifyContent="space-around"
                              alignItems="space-around"
                              spacing={2}>

                            {data.users.map((value) => (<Grid item xs={12} sm={9} md={6} lg={6} xl={4}>

                                <Card item={value.id} variant="outlined">
                                    <UserCard userData={value}/>
                                </Card>

                            </Grid>))}

                        </Grid>
                        <SgPagination handlePageChange={handlePageChange} pageTotal={pageTotal} page={page}/>
                    </Box>
                </Grow>
            </HeroDisplayWithImage>
            <UserFilterModal isFilterOpen={isFilterOpen} setFilterOpen={setFilterOpen} isFilterOn={isFilterOn}
                             setFilterOn={setFilterOn} handlePageReload={handlePageReload}/>
            <Typography>{userRequest.nameSearchText}</Typography>
        </>

    )

}

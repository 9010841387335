import React, {Fragment} from "react";
import {Box, Stack, Typography} from "@mui/material";

const ProjectNotes = ({notes}) => {

    return (
        <Fragment>
            <Box sx={{bgcolor: '#F0F8FF', pt: 8, pb: 6, ml: {xs: '5%', lg: '15%'}, mr: {xs: '5%', lg: '15%'}}}>
                <Stack direction="row" gap={2}
                       sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                    <Box>
                        <Typography variant="h5"
                                    style={{whiteSpace: 'pre-line'}} gutterBottom
                        >{notes}</Typography>
                    </Box>
                </Stack>
            </Box>
        </Fragment>
    )

}

export default ProjectNotes;
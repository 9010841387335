import {Box, Typography} from "@mui/material";

const DisplayCard = ({text, icon}) => {


    return (
        <Box backgroundColor={"white"} color={"black"} sx={{width: {sm: '100%', md: '50%', lg: '33%'}}}>

            <Box sx={{display: "flex", justifyContent: "center", mb: 5, width: {sm: '100%'}}}>
                {icon}
            </Box>

            <Typography component="h3" variant="h6" paragraph={true} pl={"5%"} pr={"5%"}>{text}</Typography>


        </Box>

    )

}

export default DisplayCard
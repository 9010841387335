import {Badge, Checkbox, Tooltip} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import FavoriteIcon from "@mui/icons-material/Favorite";
import React from "react";

const Favourite = ({favourite, toggle, industry, favouriteCount}) => {

    return (

        industry ? <Tooltip title="Add to your favourites">
            <IconButton sx={{p: 0, m: 0}} aria-label="add to favorites" onClick={toggle}>
                <Checkbox sx={{p: 0, m: 0}} checked={favourite}
                          icon={<FavoriteBorderIcon sx={{color: "white"}} fontSize="large"/>}
                          checkedIcon={<FavoriteIcon fontSize="large" sx={{color: "red"}}/>}/>
            </IconButton>
        </Tooltip> : favouriteCount > 0 &&
            <Badge badgeContent={favouriteCount} color="primary"><FavoriteIcon fontSize="large"
                                                                               sx={{color: "red"}}/></Badge>

    )

}

export default Favourite;
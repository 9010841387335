import {InputAdornment, Stack, TextField} from "@mui/material";
import Button from "@mui/material/Button";
import {Cancel, Search} from "@mui/icons-material";
import IconButton from "@mui/material/IconButton";
import React from "react";
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';


const ProjectFilter = ({
                           projectRequest,
                           handleSearchChange,
                           clearSearchText,
                           isFilterOn,
                           isFilterOpen,
                           setFilterOpen
                       }) => {


    return (
        <Stack
            sx={{pt: 4}}
            direction="row"
            spacing={2}
            justifyContent="center"
        >
            <Button startIcon={isFilterOn ? <FilterAltIcon/> : <FilterAltOffIcon/>} variant="contained" onClick={() => {
                setFilterOpen(!isFilterOpen)
            }}>Filter {isFilterOpen && 'On'}</Button>
            <TextField name="titleSearchText"
                       id="titleSearchText"
                       label="Search projects..."
                       onChange={handleSearchChange}
                       value={projectRequest.titleSearchText}
                       InputProps={{
                           startAdornment: (<InputAdornment position="start">
                               <Search/>
                           </InputAdornment>), endAdornment: <InputAdornment sx={{color: '#F0F8FF'}} position="end">
                               <IconButton sx={{color: "grey"}}
                                           onClick={() => clearSearchText()}
                               >
                                   <Cancel/>
                               </IconButton>
                           </InputAdornment>,
                       }}/>
        </Stack>
    )
}

export default ProjectFilter;
import React, {useEffect, useState} from 'react';
import axios from "axios";
import {Navigate, useParams} from "react-router-dom";
import ImageUpload from "../components/ImageUpload";
import {getProject, getProjectStructure, handleImageUpload} from "../general/api.js";
import properties from "../general/properties.js";

export default function ProjectImageUpload() {

    const {id} = useParams();

    const [files, setFiles] = useState([]);

    const [project, setProject] = useState(getProjectStructure());

    let [redirectProject, setRedirectProject] = useState({created: false, id: 0});

    useEffect(() => {

        getProject(id)
            .then(response => {
                setProject(response.data);
            });

    }, [id]);

    let handleUpload = (files) => {
        setFiles(files);
    }

    let save = (e) => {

        handleImageUpload(files[0], true).then((file) => {

                let formData = new FormData();
                formData.append("file", file, project.titleEllipsis + Date.now() + '.png');

                const url = properties.backendServer + '/sg/project/' + id + '/image';

                axios.post(url, formData, {
                    transformRequest: () => formData,
                }).then(
                    result => setRedirectProject({created: true, id: id})
                ).catch(error => {
                    console.log(error);
                });
            }
        );

        e.preventDefault();

    }

    let cancel = (e) => {

        setRedirectProject({created: true, id: id})

    };


    if (redirectProject.created) {
        return <Navigate to={`/projectView/${id}`}/>
    } else {
        return (
            <ImageUpload save={save} cancel={cancel} imageFilePath={project.imageFilePath} handleUpload={handleUpload}
                         files={files}/>);
    }
}

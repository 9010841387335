import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import React from "react";

const HeaderBanner = ({mainText, secondaryText, children}) => {

    return (

        <Box
            sx={{
                pt: 8, pb: 6,
            }}
        >
            <Container maxWidth="sm">
                <Typography
                    component="h1"
                    variant="h2"
                    align="center"
                    gutterBottom
                >
                    {mainText}
                </Typography>
                <Typography variant="h5" align="center" color="text.secondary" paragraph>
                    {secondaryText}
                </Typography>
                {children}
            </Container>
        </Box>

    )
}

export default HeaderBanner;
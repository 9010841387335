import React, {useContext, useEffect, useState} from "react";
import makeStyles from '@mui/styles/makeStyles';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import clsx from "clsx";
import {Box, CircularProgress, Stack} from "@mui/material";
import SideDrawer from "./SideDrawer.js";
import UserContext from "../user/UserContext.js";
import ProfileAvatar from "../user/ProfileAvatar.js";
import GeneralHelp from "../general/Help";
import Logout from "../account/Logout.js";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";

const useStyles = makeStyles((theme) => ({
    root: {
        minHeight: 60, backgroundColor: "aliceblue"
    }, menuButton: {
        marginRight: theme.spacing(7),
    }, appBar: {
        transition: theme.transitions.create(['margin', ',width'], {
            easing: theme.transitions.easing.sharp, duration: theme.transitions.duration.leavingScreen,
        }),
    }, appBarShift: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut, duration: theme.transitions.duration.enteringScreen,
        }),
    }, hide: {
        display: 'none', backgroundColor: "blue"
    }, drawer: {
        width: drawerWidth, flexShrink: 0, backgroundColor: "blue"
    }, drawerPaper: {
        width: drawerWidth,
    }
}));

const drawerWidth = 240;

export default function Header() {

    const classes = useStyles();

    const industry = localStorage.getItem('industry');

    const admin = localStorage.getItem('admin');

    const [open, setOpen] = useState(false);

    const [helpOpen, setHelpOpen] = useState(false);

    const [logoutOpen, setLogoutOpen] = useState(false);

    const [isLoading, setLoading] = useState(true);

    const {userLight} = useContext(UserContext);

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        setLoading(false);
    }, [])

    if (isLoading) {
        return (<div className={classes.error}><CircularProgress/></div>)
    } else {
        return (<div className={classes.root}>
            <AppBar
                position="fixed"
                color="transparent" elevation={0}
                className={clsx(classes.appBar, {
                    [classes.appBarShift]: open,
                })}
            >
                <Toolbar sx={{
                    display: 'flex', justifyContent: 'space-between', alignItems: 'center'
                }}>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        edge="start"
                        className={clsx(classes.menuButton, open && classes.hide)}
                        size="large">
                        <MenuIcon color="primary"/>
                    </IconButton>
                    <Typography variant="h6" color="primary">
                        ScriptVender
                    </Typography>
                    <Stack direction="row" sx={{alignItems: 'center'}}>
                        {userLight.authenticated &&
                            <IconButton color="primary" onClick={() => setHelpOpen(!open)}
                                        aria-label="Help"><HelpOutlineIcon color="primary"
                                                                           fontSize="large"/></IconButton>}

                        {userLight.authenticated && <ProfileAvatar avatarData={userLight} logoutOpen={logoutOpen}
                                                                   setLogoutOpen={setLogoutOpen}/>}

                        {!userLight.authenticated && <Box sx={{width: '70px'}}></Box>}

                    </Stack>
                </Toolbar>
            </AppBar>

            <SideDrawer authenticated={userLight.authenticated} industry={industry} admin={admin} open={open}
                        handleDrawerClose={handleDrawerClose} setOpen={setOpen} logoutOpen={logoutOpen}
                        setLogoutOpen={setLogoutOpen}
                        classes={classes}/>

            <GeneralHelp open={helpOpen} setOpen={setHelpOpen}/>
            <Logout open={logoutOpen} setOpen={setLogoutOpen}/>
        </div>);
    }
}
import FormatQuoteIcon from '@mui/icons-material/FormatQuote';
import {Box, Stack, Typography} from "@mui/material";

const Quote = ({quote}) => {

    return (
        <>

            <Box mt={2} minWidth='100%' maxWidth='300px'>
                <Stack>
                    <Stack direction={"row"} spacing={1} sx={{mb: '10px'}}>
                        <FormatQuoteIcon fontSize="large"/><Typography paragraph variant="h6">{quote.quote}</Typography>
                    </Stack>
                    <Typography sx={{pl: '10px', fontStyle: 'italic'}}>{quote.name}</Typography>
                    <Typography sx={{pl: '10px', fontStyle: 'italic'}}>{quote.organisation}</Typography>
                </Stack>
            </Box>
        </>
    )
}

export default Quote;
import {Box, Stack} from "@mui/material";
import ProjectHeader from "./ProjectHeader.js";
import UserInfo from "./UserInfo.js";
import React, {useEffect, useRef, useState} from "react";
import axios from "axios";
import ProjectEnabledActions from "./ProjectEnabledActions.js";
import Favourite from "./Favourite.js";
import {HeroDisplay} from "../components/layout";
import properties from "../general/properties.js";
import ScreenplayWrapper from "../screenplay/ScreenplayWrapper.js";
import Industry from "./Industry.js";
import Heading from "../landing/Heading.js";
import {AnimationOnScroll} from 'react-animation-on-scroll';
import DialogYesNo from "../admin/DialogYesNo.js";
import Button from "@mui/material/Button";
import ProjectTabs from "./ProjectTabs.js";
import {isAuthenticated} from "../general/api.js";
import {useTheme} from "@mui/material/styles";
import ProjectMenu from "./ProjectMenu.js";
import ProjectNotes from "./ProjectNotes.js";

const Project = ({projectData, id, toggleFavourite, extractData}) => {

    const myRef = useRef(null);

    const industry = localStorage.getItem("industry");

    const executeScroll = () => {
        myRef.current.scrollIntoView({behavior: 'smooth'});
    }

    const [reload, setReload] = useState(true);
    const [tabValue, setTabValue] = useState(1);
    const [isDisableOpen, setDisableOpen] = useState(false);
    const admin = localStorage.getItem('admin');
    const [compsLabel, setCompsLabel] = useState("");
    const [quotesLabel, setQuotesLabel] = useState("");
    const [open, setOpen] = useState(false);
    const theme = useTheme();


    useEffect(() => {
        window.scrollTo(0, 0)
        !projectData.editRights && setTabValue(2);
        setCompsLabel("Comps (" + projectData.competitionResponse.length + ")");
        setQuotesLabel("Quotes (" + projectData.quotes.length + ")");
    }, [projectData.competitionResponse.length, projectData.editRights, projectData.quotes.length])

    const getPdf = async (event) => {

        const prefix = projectData.openView ? properties.backendServer + '/op' : properties.backendServer + '/sg';

        const url = prefix + '/project/' + id + '/pdf';

        if (isAuthenticated()) {
            await axios.get(properties.backendServer + '/sg/tokencheck');
        }

        await axios.get(url, {responseType: 'blob'}).then((response) => {
            const href = URL.createObjectURL(response.data);

            const link = document.createElement('a');
            link.href = href;
            link.setAttribute('download', projectData.title + '.pdf');
            document.body.appendChild(link);
            link.click();

            document.body.removeChild(link);
            URL.revokeObjectURL(href);

        })


        event.preventDefault();

    }

    function onClose() {

        setDisableOpen(false);
    }

    const enabledPayload = {
        "enabled": false
    }

    let save = () => {

        const url = properties.backendServer + '/admin/user/' + id + '/enabled';

        enabledPayload.enabled = !projectData.enabled;

        axios.put(url, enabledPayload).then(() => {
            setReload(!reload);
            onClose()
        }).catch(error => {
            console.log(error);
        });


        onClose();
    }

    const handleChange = (event, newValue) => {
        setTabValue(newValue);
    };


    return (<>
        {projectData.editRights && <ProjectMenu open={open} setOpen={setOpen} projectData={projectData}/>}

        <HeroDisplay sx={{backgroundImage: projectData.imageFilePath, pt: {xs: 5, md: 10}}}>
            <Stack justifyContent={"space-between"} direction="row">
                <Box>
                    <Box mb={{xs: 1, md: 2}}>
                        <ProjectHeader data={projectData}/>
                    </Box>
                    <UserInfo authors={projectData.authors}/>
                    <Stack direction={'row'} spacing={2} sx={{mt: 2, mb: 2}}>
                        <Favourite favourite={projectData.favourite} toggle={toggleFavourite}
                                   favouriteCount={projectData.favouriteCount} industry={industry}/>
                        <Industry industryView={projectData.industryView} editRights={projectData.editRights}/>
                    </Stack>

                    <ProjectEnabledActions data={projectData} getPdf={getPdf} executeScroll={executeScroll}/>

                </Box>
                <Box sx={{
                    display: {xs: 'none', md: 'none', lg: 'flex'},
                    borderBottom: 1,
                    borderColor: 'divider',
                    color: "white",
                    minWidth: '400px'
                }}>
                    <ProjectTabs projectData={projectData} tabValue={tabValue} handleChange={handleChange}
                                 compsLabel={compsLabel} quotesLabel={quotesLabel}/>

                </Box>
            </Stack>
        </HeroDisplay>
        <Box
            sx={{mt: '50px', justifyContent: 'center', alignItems: "center", display: {xs: 'flex', lg: 'none'}}}>
            {admin === 'true' && <Button variant="contained" fullWidth
                                         color="primary"
                                         onClick={() => setDisableOpen(true)}>{projectData.enabled === true ? 'Disable' : 'Enable'}</Button>}

            <ProjectTabs projectData={projectData} tabValue={tabValue} handleChange={handleChange}
                         compsLabel={compsLabel} quotesLabel={quotesLabel}/>

        </Box>
        {projectData.pitch && <ProjectNotes notes={projectData.pitch}/>}
        {projectData.extract.showExtract && projectData.extract.extractText &&
            <Box sx={{display: {xs: 'none', lg: 'inline'}}}>
                <AnimationOnScroll animateIn="animate__fadeInRightBig"><Heading
                    title={"Screenplay Extract"}
                    subheader={projectData.extract.extractText}/></AnimationOnScroll>
            </Box>}
        {projectData.extract.showExtract &&
            <Box sx={{pt: '30px'}} ref={myRef}><ScreenplayWrapper executeScroll={executeScroll} id={id}
                                                                  extractData={extractData}/></Box>}
        <DialogYesNo open={isDisableOpen} action={save} handleClose={onClose}
                     title={"Administrator: Enable / Disable"}
                     description={"Are you sure you want to disable the user?"}/>
    </>)

}

export default Project
import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {transitionUp} from "./api.js";
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';


export default function GeneralHelp({open, setOpen}) {

    return (
        <>
            <Dialog
                open={open}
                TransitionComponent={transitionUp}
                keepMounted
                onClose={() => setOpen(false)}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        backgroundColor: '#F0F8FF'
                    }}><HelpOutlineIcon sx={{pr: '5px'}}/>Help</DialogTitle>
                <DialogContent sx={{backgroundColor: '#F0F8FF'}}>
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon/>}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography>Screenwriters: selling your work</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <ul style={{marginTop: "0px"}}>
                                <li>create a project, and complete project details.</li>
                                <li>upload an engaging background image.</li>
                                <li>upload your screenplay.</li>
                                <li>list successes in any major competitions.</li>
                            </ul>
                            <Typography>When you are ready to hit the market, go into <b><i>Settings</i></b> and
                                toggle <b><i>Allow industry to view</i></b>. </Typography>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon/>}
                            aria-controls="panel2a-content"
                            id="panel2a-header"
                        >
                            <Typography>Industry: finding screenplays and writers</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                Currently industry rights are granted on an invitation only basis. However, you can
                                reach out via <a href="mailto:admin@scriptvender.com">admin@scriptvender.com</a>.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                </DialogContent>
                <DialogActions sx={{backgroundColor: '#F0F8FF'}}>
                    <Button onClick={() => setOpen(false)}>Close</Button>
                </DialogActions>
            </Dialog>
        </>
    );
}


import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import directing from "../directorschair.png";
import Box from "@mui/material/Box";
import {HeroDisplayWithImage} from "../components/layout.js";
import axios from "axios";
import properties from "../general/properties.js";
import Loading from "../general/Loading.js";
import {LandingButton} from "../components/buttons.js";
import CompetitionModal from "./CompetitionModal.js";
import CelebrationIcon from '@mui/icons-material/Celebration';
import CompetitionDisplay from "./CompetitionDisplay.js";
import Confetti from "react-confetti";
import {Stack} from "@mui/material";
import useWindowSize from "react-use/lib/useWindowSize";

export default function AddCompetition() {

    const [displayConfetti, setDisplayConfetti] = useState('none');

    const {id} = useParams();

    const [addCompetitionOpen, setAddCompetitionOpen] = useState(false);

    let [competition, setCompetition] = useState({"id": 0, "name": ""});
    let [round, setRound] = useState([{}]);

    const [recycle, setRecycle] = useState(true);

    let [competitionEntryList, setCompetitionEntryList] = useState({
        "competitionId": 0, "roundId": 0, "projectId": 0, "year": 0
    });

    let [competitionEntry, setCompetitionEntry] = useState({
        "competitionId": 0, "roundId": 0, "projectId": 0, "year": new Date().getFullYear(), "visible": true
    });

    const [loading, setLoading] = useState(true);

    let navigate = useNavigate();

    const {width, height} = useWindowSize();

    useEffect(() => {

        loadCompetitions().then(() => setLoading(false));

    }, []);


    const loadCompetitions = async () => {

        await axios({
            method: 'get', url: properties.backendServer + '/sg/competition',
        })
            .then((result) => {
                setCompetition(result.data);
            });


        await axios({
            method: 'get', url: properties.backendServer + '/sg/competition/project/' + id,
        })
            .then((result) => {
                setCompetitionEntryList(result.data);
            });

    }
    const onDisplayConfetti = () => {

        setDisplayConfetti('block');
        setRecycle(true);
        setTimeout(() => {
            setRecycle(false)
        }, 5000);
        setAddCompetitionOpen(false)
    }

    function deleteCompEntry(compEntryId) {

        const url = properties.backendServer + '/sg/competition/' + id + '/' + compEntryId;

        axios.delete(url)
            .then(() => {
                const competitionEntryList2 = competitionEntryList.filter(obj => obj.id !== compEntryId);
                setCompetitionEntryList(competitionEntryList2);
            });
    }

    if (loading) {
        return <Loading/>
    } else {
        return (<>
            <HeroDisplayWithImage sx={{backgroundImage: `url(${directing})`}} backgroundRep={'repeat-y'}>
                <Box
                    sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <Box sx={{display: displayConfetti}}>
                        <Confetti
                            width={width}
                            height={height}
                            recycle={recycle}
                        />
                    </Box>
                    <Stack spacing={1} sx={{mt: '5%'}}>
                        <LandingButton startIcon={<CelebrationIcon/>}
                                       onClick={() => {
                                           setCompetitionEntry({
                                               "competitionId": 0,
                                               "roundId": 0,
                                               "projectId": 0,
                                               "year": new Date().getFullYear(),
                                               "visible": true
                                           });
                                           setAddCompetitionOpen(true)
                                       }}>Add recognised
                            competition </LandingButton>
                        {/*<LandingButton startIcon={<CelebrationIcon/>}*/}
                        {/*               onClick={() => setAddCompetitionOpen(true)}>Add other*/}
                        {/*    competition </LandingButton>*/}
                        <LandingButton
                            onClick={() => navigate('/projectView/' + id)}>Return </LandingButton>
                    </Stack>

                </Box>
                <CompetitionDisplay displayConfetti={displayConfetti} competitionEntryList={competitionEntryList}
                                    competitions={competition}
                                    deleteCompEntry={deleteCompEntry} competitionEntry={competitionEntry}
                                    setCompetitionEntry={setCompetitionEntry} open={addCompetitionOpen}
                                    setOpen={setAddCompetitionOpen}/>

            </HeroDisplayWithImage>
            <CompetitionModal onDisplayConfetti={onDisplayConfetti} setDisplayConfetti={setDisplayConfetti}
                              open={addCompetitionOpen} setOpen={setAddCompetitionOpen}
                              competitionEntryList={competitionEntryList}
                              competition={competition} setCompetitionEntryList={setCompetitionEntryList}
                              setCompetition={setCompetition} competitionEntry={competitionEntry}
                              setCompetitionEntry={setCompetitionEntry} round={round} setRound={setRound}/>
        </>);
    }
}




import React, {useEffect, useState} from 'react';
import Grid from '@mui/material/Grid';
import {Card, Grow} from "@mui/material";
import ProjectCard from "./ProjectCard";
import Typography from "@mui/material/Typography";
import directing from "../directorschair.png";
import Box from "@mui/material/Box";
import axios from "axios";
import ProjectFilterModal from "./ProjectFilterModal.js";
import {HeroDisplayWithImage} from "../components/layout";
import SgPagination from "../components/SgPagination.js";
import ProjectFilter from "./ProjectFilter.js";
import HeaderBanner from "../components/HeaderBanner.js";
import properties from "../general/properties.js";
import Loading from "../general/Loading.js";


export default function ProjectList() {

    const handlePageChange = (event, value) => {
        setPage(value - 1);

        projectRequest.page = value - 1;

        setProjectRequest({...projectRequest, projectRequest});

    };

    function handlePageReload() {
        setReload(!reload);
    }

    const [data, setData] = useState([]);

    const [loading, setLoading] = useState(true);

    const [pageTotal, setPageTotal] = useState(0);

    const [page, setPage] = useState(0);

    const [reload, setReload] = useState(false);

    const [isFilterOn, setFilterOn] = useState(false);

    const [isFilterOpen, setFilterOpen] = useState(false);

    let [projectRequest, setProjectRequest] = useState({
        "page": 0, "titleSearchText": "", "sortBy": "", "genres": []
    });

    let handleSearchChange = (event) => {

        setProjectRequest({...projectRequest, [event.target.name]: event.target.value})

        if (event.target.name === 'titleSearchText' && event.target.value.length === 0) {
            setReload(!reload);
            return;
        }

        if (event.target.name === 'titleSearchText' && event.target.value.length < 3) {

        } else {
            setReload(!reload);
        }

    }

    let clearSearchText = () => {
        projectRequest.titleSearchText = "";

        setProjectRequest({...projectRequest, projectRequest});

        setReload(!reload);

    }

    useEffect(() => {

        const projectFilter = JSON.parse(localStorage.getItem('projectFilter'));

        if (projectFilter != null && projectFilter.genres != null) {
            projectRequest.genres = projectFilter.genres.map((o) => {
                return o.id;
            });

        }

        axios({
            method: 'post', url: properties.backendServer + '/sg/projects', data: projectRequest,
        }).then(response => {
            setData(response.data);
            setLoading(false);
            setPageTotal(Math.ceil(data.total / 10));

        });

    }, [pageTotal, page, reload]);


    if (loading) {
        return <Loading/>
    } else if (data.projects.map == null) {
        return (<div>None found</div>)
    } else return (<>
            <HeaderBanner mainText={"Screenplays"} secondaryText={"visible to you"}>
                <ProjectFilter projectRequest={projectRequest} handleSearchChange={handleSearchChange}
                               clearSearchText={clearSearchText}
                               isFilterOpen={isFilterOpen} setFilterOpen={setFilterOpen} isFilterOn={isFilterOn}
                               setFilterOn={setFilterOn}/>
            </HeaderBanner>
            <HeroDisplayWithImage backgroundimg={`url(${directing})`} backgroundRep={'repeat-y'}>
                <Grow in={true} timeout={4000} direction={"left"}>
                    <Box sx={{padding: '20'}}>
                        <Grid container padding={{xs: "5px", md: "50px"}} justifyContent="space-around"
                              alignItems="space-around"
                              spacing={2}>

                            {data.projects.map((value) => (<Grid item xs={12} sm={9} md={6} lg={6} xl={4}>

                                <Card item={value.id} variant="outlined">
                                    <ProjectCard projectData={value}/>
                                </Card>

                            </Grid>))}

                        </Grid>
                        <SgPagination handlePageChange={handlePageChange} pageTotal={pageTotal} page={page}/>
                    </Box>
                </Grow>
            </HeroDisplayWithImage>
            <ProjectFilterModal isFilterOpen={isFilterOpen} setFilterOpen={setFilterOpen} isFilterOn={isFilterOn}
                                setFilterOn={setFilterOn} handlePageReload={handlePageReload}/>
            <Typography>{projectRequest.titleSearchText}</Typography>
        </>

    )

}

import IndustryLanding from "./industryLanding";

export default function IndustryRecommendedWriters() {

    return (<>
            <IndustryLanding url='/sg/industry/recommended/writers/'/>
        </>

    )


}
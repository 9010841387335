import {Avatar, Box, Card, CardContent, Grid, Typography} from '@mui/material';
import PageviewIcon from '@mui/icons-material/Pageview';
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward.js";

export const Impressions = (props) => {

    const dashboardData = props.data;

    return (<Card
            sx={{height: '100%'}}
            {...props}
        >
            <CardContent>
                <Grid
                    container
                    spacing={3}
                    sx={{justifyContent: 'space-between'}}
                >
                    <Grid item>
                        <Typography
                            color="textSecondary"
                            gutterBottom
                            variant="overline"
                        >
                            Impressions
                        </Typography>
                        <Typography
                            color="textPrimary"
                            variant="h4"
                        >
                            {dashboardData.total}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Avatar
                            sx={{
                                backgroundColor: 'lightblue',
                                height: 56,
                                width: 56
                            }}
                        >
                            <PageviewIcon fontSize="large"/>
                        </Avatar>
                    </Grid>
                </Grid>
                <Box
                    sx={{
                        pt: 2,
                        display: 'flex',
                        alignItems: 'center'
                    }}
                >
                    <ArrowUpwardIcon color="success"/>
                    <Typography
                        color="error"
                        sx={{
                            mr: 1
                        }}
                        variant="body2"
                    >
                        {/*12%*/}
                    </Typography>
                    <Typography
                        color="textSecondary"
                        variant="caption"
                    >
                        Appeared in search
                    </Typography>
                </Box>
            </CardContent>
        </Card>
    );
}

import React, {useEffect, useState} from 'react';
import {Card, Grow, Typography} from "@mui/material";
import {useParams} from "react-router-dom";
import Grid from "@mui/material/Grid";
import ProjectCard from "../project/ProjectCard";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import {getUserStructure} from "../general/api.js";
import axios from "axios";
import Button from "@mui/material/Button";
import {HeroDisplayWithImage} from "../components/layout.js";
import DialogYesNo from "../admin/DialogYesNo.js";
import properties from "../general/properties.js";
import PinnedProjectWrapper from "../project/PinnedProjectWrapper.js";
import CreateFirstProject from "./CreateFirstProject.js";
import Loading from "../general/Loading.js";
import {LandingButton} from "../components/buttons.js";
import PushPinOutlinedIcon from "@mui/icons-material/PushPinOutlined";
import FirstEditProfileDetails from "./FirstEditProfileDeails";

export default function UserView() {

    const {id} = useParams();

    const admin = localStorage.getItem('admin');

    let [data, setData] = useState(getUserStructure());

    let [userProjectsData, setUserProjectsData] = useState([]);

    const enabledPayload = {
        "enabled": false
    }

    const [loading, setLoading] = useState(true);

    const [reload, setReload] = useState(true);

    const [isDisableOpen, setDisableOpen] = useState(false);


    function onClose() {

        setDisableOpen(false);
    }

    let save = () => {

        const url = properties.backendServer + '/admin/user/' + id + '/enabled';

        enabledPayload.enabled = !data.enabled;

        axios.put(url, enabledPayload).then(() => {
            setReload(!reload);
            onClose()
        }).catch(error => {
            console.log(error);
        });


        onClose();
    }

    useEffect(() => {

        let endpoints = [
            properties.backendServer + '/sg/user/' + id,
            properties.backendServer + '/sg/user/projects/' + id
        ];

        axios.all(endpoints.map((endpoint) => axios.get(endpoint))).then(
            (data) => {
                setData(data[0].data);
                setUserProjectsData(data[1].data);
                setLoading(false);
            },
        )

    }, [reload, id]);

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])


    if (loading) {
        return <Loading/>
    } else return (<>
            <Box sx={{bgcolor: '#F0F8FF', pb: 6}}>
                <Container maxWidth="sm">
                    <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                        <Avatar alt={data.name} aria-label={data.name} sx={{width: 80, height: 80}}
                                src={data.imageFilePath}/>
                    </Box>
                    <Typography
                        component="h1"
                        variant="h3"
                        align="center"
                        color="text.primary"
                    >
                        {data.name}
                    </Typography>
                    <Typography
                        align="center"
                        color="text.primary"
                        gutterBottom
                    >
                        {data.country}
                    </Typography>
                    {data.enabled === false ?
                        <Typography variant="h5" color="red" align="center" gutterBottom>Disabled</Typography> :
                        <div></div>}
                    <Typography variant="h5" align="center" color="text.secondary" paragraph>
                        {data.logline}
                    </Typography>
                    {data.pinnedProjectId != null && data.pinnedProjectId !== 0 &&
                        <Grow in={true} timeout={4000} direction={"left"}>
                            <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                <LandingButton
                                    sx={{display: 'flex', justifyContent: 'center', backgroundColor: 'black'}}
                                    startIcon={<PushPinOutlinedIcon color="success"/>} onClick={() => {
                                    console.log('test')
                                }}>Pinned Project</LandingButton>
                            </Box>
                        </Grow>
                    }
                    {admin === 'true' && <Button variant="contained" fullWidth
                                                 color="primary"
                                                 onClick={() => setDisableOpen(true)}>{data.enabled === true ? 'Disable' : 'Enable'}</Button>}

                </Container>
            </Box>
            <HeroDisplayWithImage backgroundimg={data.backgroundProfileImage}>
                <Grow in={true} timeout={4000} direction={"left"}>
                    <Box sx={{padding: '20'}}>
                        <Grid container padding={{xs: "5px", md: "50px"}} justifyContent="space-around"
                              alignItems="space-around"
                              spacing={2}>

                            <FirstEditProfileDetails data={data}/>
                            <CreateFirstProject data={userProjectsData}/>

                            {userProjectsData.map((value, id) => (
                                <Grid key={id} item xs={12} sm={9} md={6} lg={6} xl={4}>

                                    <Card item={value.id} variant="outlined">
                                        <ProjectCard projectData={value}/>
                                    </Card>

                                </Grid>))}

                        </Grid>
                    </Box>
                </Grow>
            </HeroDisplayWithImage>
            {data.pinnedProjectId != null && data.pinnedProjectId !== 0 &&
                <PinnedProjectWrapper projectId={data.pinnedProjectId}/>}
            <DialogYesNo open={isDisableOpen} action={save} handleClose={onClose}
                         title={"Administrator: Enable / Disable"}
                         description={"Are you sure you want to disable the user?"}/>
        </>

    )
}


import React, {useContext, useEffect, useRef} from "react";
import Features from "./Features.js";
import LandingHeader from "./LandingHeader.js";
import {Box} from "@mui/material";
import Visibility from "./Visibility.js";
import LandingDashBoard from "./LandingDashBoard.js";
import LandingProject from "./LandingProject.js";
import Heading from "./Heading.js";
import "animate.css/animate.min.css";
import Visibility2 from "./Visibility2.js";
import SignUp from "../account/SignUp.js";
import UserContext from "../user/UserContext.js";
import LandingHeaderBottom from "./LandingHeaderBottom.js";

const LandingPage = () => {

    const myRef = useRef(null);

    const {userLight} = useContext(UserContext);

    const executeScroll = () => {
        myRef.current.scrollIntoView({behavior: 'smooth'});
    }

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (<Box>
        <LandingHeader executeScroll={executeScroll}/>
        <Box ref={myRef}>
            <Features/>
        </Box>
        <Visibility/>
        <Visibility2/>
        <Heading title={"Example Project"}
                 subheader={"Draw industry eyeballs to your work."}/>
        <LandingProject/>
        <Heading title={"Your traffic analysis"}
                 subheader={"Gain insight into industry traffic."}/>
        <LandingDashBoard/>
        {!userLight.authenticated && <><Heading title={"Create a free account"}
                                                subheader={"Sign up here, and start marketing those screenplays"}/>
            <LandingHeaderBottom executeScroll={executeScroll}/>
        </>
        }
    </Box>)


}

export default LandingPage
import ProjectWrapper from "./ProjectWrapper.js";
import {Fragment} from "react";
import {Box, Stack, Typography} from "@mui/material";
import Container from "@mui/material/Container";
import PushPinOutlinedIcon from '@mui/icons-material/PushPinOutlined';

const PinnedProjectWrapper = ({projectId}) => {

    return (
        <Fragment>
            <Box sx={{bgcolor: '#F0F8FF', pt: 8, pb: 6}}>
                <Container maxWidth="sm">
                    <Stack direction="row" gap={2}
                           sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                        <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                            <PushPinOutlinedIcon color="success"/>
                        </Box>
                        <Typography variant="h5" align="center" color="text.success" paragraph
                                    sx={{mt: '10px', color: 'green'}}>
                            pinned project
                        </Typography>
                    </Stack>
                </Container>
            </Box>
            <ProjectWrapper projectId={projectId}/>
        </Fragment>
    )

}

export default PinnedProjectWrapper;
import React, {useState} from 'react';
import 'react-toastify/dist/ReactToastify.css';
import {Link} from "react-router-dom";
import {Box, List, ListItemButton, ListItemIcon, ListItemText} from "@mui/material";
import Toolbar from "@mui/material/Toolbar";
import Button from "@mui/material/Button";
import MenuIcon from "@mui/icons-material/Menu";
import IconButton from "@mui/material/IconButton";
import Divider from "@mui/material/Divider";
import {DisplaySettings, FormatQuote} from "@mui/icons-material";
import Drawer from "@mui/material/Drawer";
import {styled} from "@mui/material/styles";
import ChevronRightIcon from "@mui/icons-material/ChevronRight.js";
import EditIcon from "@mui/icons-material/Edit.js";
import FileUploadIcon from "@mui/icons-material/FileUpload.js";
import ScriptIcon from "@mui/icons-material/HistoryEdu.js";
import PersonAddIcon from "@mui/icons-material/PersonAdd.js";
import CelebrationIcon from "@mui/icons-material/Celebration.js";
import DeleteIcon from "@mui/icons-material/Delete.js";
import DeleteProject from "./DeleteProject.js";
import ScreenplayOnline from "./ScreenplayOnline.js";

const ProjectMenu = ({open, setOpen, projectData}) => {

    const id = projectData.id;
    const toggleDrawer = (isOpen) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setOpen(isOpen);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    const DrawerHeader = styled('div')(({theme}) => ({
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0, 1), ...theme.mixins.toolbar,
        justifyContent: 'flex-start',
    }));

    const [isDeleteOpen, setDeleteOpen] = useState(false);
    const [isOnlineUploadOpen, setOnlineUploadOpen] = useState(false);

    return (<>
        <Box sx={{minHeight: 60, backgroundColor: "aliceblue", width: '100%'}}>
            <Box
                position="fixed"
                color="transparent" elevation={0}
                sx={{width: '100%'}}
            >
                <Toolbar sx={{
                    display: 'flex', justifyContent: 'right', alignItems: 'center'
                }}>
                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        sx={{flex: 'right', color: '#4682B4'}}
                        onClick={() => setOpen(!open)}
                        startIcon={<MenuIcon/>}
                    >project menu
                    </Button>
                </Toolbar>
            </Box>
        </Box>

        <Drawer anchor="right" open={open} onClose={toggleDrawer(false)}>
            <DrawerHeader>
                <IconButton onClick={handleDrawerClose} size="large">
                    <ChevronRightIcon/>
                </IconButton>
            </DrawerHeader>
            <Divider/>
            <List onClick={() => handleDrawerClose()} dense={true}>
                <ListItemButton component={Link} to={'/projectEdit/' + id}>
                    <ListItemIcon>{<EditIcon/>}</ListItemIcon>
                    <ListItemText primary={'Edit Project'}/>
                </ListItemButton>
                <ListItemButton component={Link} to={'/projectScreenplayUpload/' + id}>
                    <ListItemIcon>{<FileUploadIcon/>}</ListItemIcon>
                    <ListItemText primary={'Upload Screenplay'}/>
                </ListItemButton>
                <ListItemButton component={Link} to={'/projectImageUpload/' + id}>
                    <ListItemIcon>{<FileUploadIcon/>}</ListItemIcon>
                    <ListItemText primary={'Upload Image'}/>
                </ListItemButton>
                {projectData.pdfUploaded &&
                    <ListItemButton component={Link} onClick={() => setOnlineUploadOpen(true)}>
                        <ListItemIcon>{<ScriptIcon/>}</ListItemIcon>
                        <ListItemText primary={'Screenplay Online'}/>
                    </ListItemButton>}
                <ListItemButton component={Link} to={'/projectSettings/' + id}>
                    <ListItemIcon>{<DisplaySettings/>}</ListItemIcon>
                    <ListItemText primary={'Settings'}/>
                </ListItemButton>
                <ListItemButton component={Link} to={'/projectAddUser/' + id}>
                    <ListItemIcon>{<PersonAddIcon/>}</ListItemIcon>
                    <ListItemText primary={'Add Co-writer'}/>
                </ListItemButton>
                <ListItemButton component={Link} to={'/competitionAdd/' + id}>
                    <ListItemIcon>{<CelebrationIcon/>}</ListItemIcon>
                    <ListItemText primary={'Edit Competitions'}/>
                </ListItemButton>
                <ListItemButton component={Link} to={'/quotesAdd/' + id}>
                    <ListItemIcon>{<FormatQuote/>}</ListItemIcon>
                    <ListItemText primary={'Edit Quotes'}/>
                </ListItemButton>
                <ListItemButton component={Link} onClick={() => setDeleteOpen(true)}>
                    <ListItemIcon>{<DeleteIcon/>}</ListItemIcon>
                    <ListItemText primary={'Delete Project'}/>
                </ListItemButton>
            </List>
        </Drawer>

        <DeleteProject open={isDeleteOpen} setOpen={setDeleteOpen} id={id}/>
        <ScreenplayOnline open={isOnlineUploadOpen} setOpen={setOnlineUploadOpen} id={id}/>

    </>);
}

export default ProjectMenu;

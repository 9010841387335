import * as React from 'react';
import {useEffect, useState} from 'react';
import Box from '@mui/material/Box';
import {useTheme} from '@mui/material/styles';
import MobileStepper from '@mui/material/MobileStepper';
import Button from '@mui/material/Button';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import axios from "axios";
import {Alert, Snackbar, Typography} from "@mui/material";
import properties from "../general/properties.js";
import Loading from "../general/Loading.js";
import {isIndustry} from "../general/api";

export default function ScreenplayStepper({id, executeScroll, extractData}) {

    const [pageTurn, setPageTurn] = useState({
        "page": 0
    });

    function handlePageTurn() {

        let page = pageTurn.page = activeStep;
        setPageTurn({...pageTurn, page})

        return axios({
            method: 'put', url: properties.backendServer + '/sg/project/screenplay/page/' + id, data: pageTurn,
        })

    }

    const theme = useTheme();

    const [activeStep, setActiveStep] = useState(0);

    const [isDisableNext, setDisableNext] = useState(false);

    const [pages, setPages] = useState({
        "pages": [
            {
                "pageNo": 0,
                "pageItems": [
                    {
                        "page": 0,
                        "yaxis": 0,
                        "xaxis": 0,
                        "text": "",
                        "height": 0,
                        "fontSize": 0.00,
                        "fontSizeInPts": 0.00
                    }
                ]
            }
        ],
        "startPage": 0,
        "lastVisit": 1659777603746
    });
    const [maxSteps, setMaxSteps] = useState(0);
    const [isLoading, setLoading] = useState(true);
    const [isError] = useState(false);
    const [isPageReadAlert, setPageReadAlert] = useState(false);

    const handleNext = () => {
        setDisableNext(activeStep + 1 === maxSteps - 1);
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        isIndustry() && handlePageTurn();
        executeScroll();
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
        setDisableNext(false);
    };


    useEffect(() => {

        setPages(extractData);
        setMaxSteps(extractData.pages.length);
        setLoading(false);
        const startPage = extractData.startPage;
        setActiveStep(startPage);
        if (startPage > 0) {
            setPageReadAlert(true);
        }

    }, [extractData]);


    function handleAlertClose(event, reason) {
        if (reason === 'clickaway') {
            return;
        }

        setPageReadAlert(false);
    }

    if (isError) {
        return <Typography>Error</Typography>
    } else if (isLoading) {
        return (<Loading/>)
    } else return (<Box sx={{
        maxWidth: 900,
        flexGrow: 1,
        border: '1px solid grey',
        fontSize: '16px',
        fontFamily: 'Courier New',
        backgroundColor: 'white'
    }}>
        <MobileStepper
            variant="text"
            steps={maxSteps}
            position="static"
            activeStep={activeStep}
            nextButton={<Button
                variant="contained"
                color="primary"
                size="small"
                onClick={handleNext}
                disabled={isDisableNext}
            >
                Next
                {theme.direction === 'rtl' ? (<KeyboardArrowLeft/>) : (<KeyboardArrowRight/>)}
            </Button>}
            backButton={<Button size="small" variant="contained" color="primary" onClick={handleBack}
                                disabled={activeStep === 0}>
                {theme.direction === 'rtl' ? (<KeyboardArrowRight/>) : (<KeyboardArrowLeft/>)}
                Back
            </Button>}
        />
        <Box sx={{height: 1500, width: 1200}}>
            {pages.pages[activeStep].pageItems.map((value, i) => (
                <Box key={i} sx={{
                    paddingLeft: value.xaxis * 1.5 + 'px',
                    height: value.height * 3 + 'px',
                    paddingTop: value.yaxis * 2 + 'px',
                    fontSize: value.fontSize + 1 + 'px'
                }}>{value.text}</Box>
            ))}
        </Box>
        <MobileStepper
            variant="text"
            steps={maxSteps}
            position="static"
            activeStep={activeStep}
            nextButton={<Button
                variant="contained"
                color="primary"
                size="small"
                onClick={handleNext}
                disabled={isDisableNext}
            >
                Next
                {theme.direction === 'rtl' ? (<KeyboardArrowLeft/>) : (<KeyboardArrowRight/>)}
            </Button>}
            backButton={<Button variant="contained" color="primary" size="small" onClick={handleBack}
                                disabled={activeStep === 0}>
                {theme.direction === 'rtl' ? (<KeyboardArrowRight/>) : (<KeyboardArrowLeft/>)}
                Back
            </Button>}
        />
        <Snackbar open={isPageReadAlert} onClose={handleAlertClose}
                  anchorOrigin={{vertical: "bottom", horizontal: "right"}}>
            <Alert onClose={handleAlertClose} severity="success" sx={{width: '100%'}}>
                You last visited on {new Date(pages.lastVisit).toLocaleDateString("en-US", {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
            })} and read to page {pages.startPage}
            </Alert>
        </Snackbar>
    </Box>);
}

import {Box, Grow, Stack} from "@mui/material";
import {LandingButton} from "../components/buttons.js";
import DownloadIcon from "@mui/icons-material/Download.js";
import MenuBookIcon from "@mui/icons-material/MenuBook.js";
import React, {Fragment} from "react";

const ProjectEnabledActions = ({data, getPdf, executeScroll}) => {


    if ((data.industryView || data.editRights) && (data.showPdfDownload || data.showExtract)) return (

        <Stack gap={2} direction={{xs: 'column', sm: 'row'}}>
            {data.showPdfDownload && <Grow in={true} timeout={4000} direction={"left"}>
                <LandingButton startIcon={<DownloadIcon/>} onClick={(e) => {
                    getPdf(e)
                }}>Download
                    Screenplay</LandingButton>
            </Grow>}
            {data.extract.showExtract &&
                <Box sx={{display: {xs: 'none', lg: 'flex'}}}><LandingButton startIcon={<MenuBookIcon/>}
                                                                             onClick={() => executeScroll()}>View
                    Extract</LandingButton></Box>}
        </Stack>)
    else {
        return <Fragment></Fragment>
    }
}

export default ProjectEnabledActions;
import {Box} from "@mui/material";
import React, {Fragment} from "react";
import {Link} from "react-router-dom";
import {LandingButton} from "../components/buttons.js";
import PersonIcon from "@mui/icons-material/Person";

const FirstEditProfileDetails = ({data}) => {


    if (!data.country || !data.name) {
        return (<Box minWidth={'500px'}
                     sx={{display: 'flex', justifyContent: 'center', minHeight: '25vh', alignItems: 'center'}}>

            <LandingButton startIcon={<PersonIcon/>} component={Link} to={"/userEdit"}>Complete profile
                details</LandingButton>

        </Box>)

    } else {
        return <Fragment></Fragment>
    }
}

export default FirstEditProfileDetails;
import React, {useState} from 'react';
import Grid from '@mui/material/Grid';
import MovieCreationIcon from '@mui/icons-material/MovieCreation';
import directing from "../directorschair.png";
import {DropzoneArea} from "material-ui-dropzone";
import Box from "@mui/material/Box";
import SaveCancel from "../general/SaveCancel.js";
import {HeroDisplayStretch} from "./layout.js";
import Container from "@mui/material/Container";
import FormHeader from "../general/FormHeader";


export default function ImageUpload({save, cancel, imageFilePath, handleUpload, files}) {

    const [saveEnabled, setSaveEnabled] = useState(true);

    const [loading, setLoading] = useState(false);

    function fileAdded() {

        setSaveEnabled(false);

    }

    return (
        <HeroDisplayStretch sx={{backgroundImage: `url(${directing})`, color: 'black'}}>
            <Container component="main" maxWidth="md">
                <FormHeader headerIcon={<MovieCreationIcon/>} title={"Upload Image"}/>
                <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    backgroundColor: '#F0F8FF',
                    padding: "20px",
                    width: '100%'
                }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                            <DropzoneArea onChange={handleUpload}
                                          acceptedFiles={['image/jpeg', 'image/png', 'image/bmp', 'image/webp']}
                                          showPreviews={false}
                                          showPreviewsInDropzone={false}
                                          showAlerts={false}
                                          maxFileSize={5000000}
                                          filesLimit={1}
                                          onDrop={fileAdded}
                                          dropzoneText={'Drag and drop image, or click'}/>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Box
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                            >
                                <Box component={"img"}
                                     sx={{
                                         paddingBottom: "20px;",
                                         borderRadius: "5px",
                                         maxHeight: '250px',
                                         maxWidth: '80%'
                                     }}
                                     src={files[0] != null ? URL.createObjectURL(files[0]) : imageFilePath}/>
                            </Box>

                        </Grid>
                        <Grid item xs={12}>
                            <SaveCancel save={save} cancel={cancel} saveEnabled={saveEnabled}
                                        setSaveEnabled={setSaveEnabled} loading={loading} setLoading={setLoading}/>
                        </Grid>
                    </Grid>
                </Box>
            </Container>
        </HeroDisplayStretch>
    );
}

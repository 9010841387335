import React, {useContext, useState} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import {Stack} from "@mui/material";
import directing from "../directorschair.png";
import Box from "@mui/material/Box";
import {HeroDisplayStretch} from "../components/layout.js";
import Container from "@mui/material/Container";
import axios from "axios";
import properties from "../general/properties.js";
import SmallCenteredFormHeader from "../components/SmallCenteredFormHeader.js";
import fadein from "../fadein4.jpg";
import SmallActionButtons from "../components/SmallActionButtons.js";
import TextField from "@mui/material/TextField";
import UserContext from "./UserContext.js";

export default function ChangeEmail() {


    const {id} = useParams();

    const [emailValidation, setEmailValidation] = useState({message: '', error: false});

    let [email, setEmail] = useState({id: 0, email: ''});

    const {logoutUser} = useContext(UserContext);

    let navigate = useNavigate();

    const handleChange = (event) => {

        setEmail({...email, [event.target.name]: event.target.value})

    }

    let save = (e) => {

        const url = properties.backendServer + '/sg/user/email/' + id;

        axios.put(url, email)
            .then(() => {
                logoutUser();
                navigate('/login');

            }).catch(err => {
            if (err.response.status === 400) {
                err.response.data.formErrors.forEach(v => {
                    if (v.field === 'email') {
                        setEmailValidation({message: v.message, error: true});
                    }

                });
            }
        });

        e.preventDefault();
    }

    let cancel = () => {

        navigate('/userView/' + localStorage.getItem("profileId"))

    };

    return (<HeroDisplayStretch sx={{backgroundImage: `url(${directing})`}}>
        <Container maxWidth="xs" sx={{borderRadius: "5px"}}>
            <Box sx={{
                flexDirection: 'column',
                alignItems: 'center',
                backgroundColor: '#F0F8FF',
                padding: "20px",
                width: '100%'
            }}>
                <SmallCenteredFormHeader img={fadein} title={"Change Email"}/>
                <Stack gap={2}>
                    <TextField
                        variant="outlined"
                        required
                        fullWidth
                        id="email"
                        label="Email Address"
                        name="email"
                        onChange={handleChange}
                        value={email.email}
                        error={emailValidation.error}
                        helperText={emailValidation.message}
                    />
                </Stack>
                <SmallActionButtons firstText={"Save"} firstFunction={save} secondText={"Cancel"}
                                    secondFunction={cancel} direction={"column"}/>

            </Box>
        </Container>
    </HeroDisplayStretch>);
}



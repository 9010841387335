import {Autocomplete} from "@mui/material";
import TextField from "@mui/material/TextField";
import React from "react";

const SelectGenres = ({allGenres, project, setProject}) => {

    function updateProjectGenres(value) {

        for (let i = 0; i < value.length; i++) {
            value[i].orderBy = i;
        }
        project.genres = value;
        setProject({...project, project});
    }

    return (

        <Autocomplete
            onChange={(event, value) =>
                updateProjectGenres(value)
            }
            multiple
            id="tags-outlined"
            options={allGenres}
            fullWidth
            value={project.genres}
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => option.id}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label="genres"
                    placeholder="select genres..."
                />
            )}
        />
    )
}

export default SelectGenres;
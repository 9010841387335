import React, {useEffect, useState} from 'react';
import {Box, Fade, Stack, Typography} from "@mui/material";
import directing from "../directorschair.png";
import {Link} from "react-router-dom";
import LoginIcon from '@mui/icons-material/Login';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import PersonIcon from '@mui/icons-material/Person';
import {LandingButton} from "../components/buttons.js";
import {HeroDisplay} from "../components/layout";


const LandingHeader = ({executeScroll, hidden}) => {

    const [isAuthenticated, setAuthenticated] = useState(false);

    const profileId = localStorage.getItem('profileId');

    useEffect(() => {
        setAuthenticated(localStorage.getItem('authenticated') === 'true');
    }, [])

    return (
        <HeroDisplay sx={{backgroundImage: `url(${directing})`}}>
            <Box pt={'5%'} pb={'5%'} display="flex"
                 alignItems="center"
                 justifyContent="center">
                <Box>
                    <Fade in={true} timeout={6000}>
                        <Box>
                            <Typography component="h1" variant="h3" color={'white'} fontWeight={700} align={'justify'}>You've
                                crafted
                                the perfect screenplay...
                            </Typography>
                        </Box>
                    </Fade>
                    <Fade in={true} timeout={18000}>
                        <Box>
                            <Typography align={"right"} component="h3" variant="h3" color={'white'}
                                        fontWeight={700} gutterBottom>
                                now sell it.
                            </Typography>
                        </Box>
                    </Fade>
                </Box>
            </Box>
            <Box display="flex"
                 alignItems="center"
                 justifyContent="center">
                <Stack direction="column" gap={'10px'}>
                    <LandingButton startIcon={<QuestionMarkIcon/>} onClick={executeScroll}> Tell me
                        more...</LandingButton>

                    {isAuthenticated ?
                        <LandingButton startIcon={<PersonIcon/>} component={Link} to={"/userView/" + profileId}>My
                            Profile</LandingButton> :
                        <LandingButton startIcon={<LoginIcon/>} component={Link} to={"/login"}>Sign In</LandingButton>}
                    {isAuthenticated ? <div></div> :
                        <LandingButton startIcon={<PersonAddAlt1Icon/>} component={Link} to={"/user"}>Create writer
                            account</LandingButton>}


                </Stack>

            </Box>

        </HeroDisplay>
    );
}

export default LandingHeader;
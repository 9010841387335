import React, {useContext, useState} from 'react';
import TextField from '@mui/material/TextField';
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Typography from "@mui/material/Typography";
import FormControlLabel from "@mui/material/FormControlLabel";
import Container from "@mui/material/Container";
import {useNavigate, useParams} from 'react-router-dom';
import {InputAdornment, Stack, Switch} from "@mui/material";
import pill from '../login2.png'
import axios from "axios";
import IconButton from "@mui/material/IconButton";
import {Visibility, VisibilityOff} from "@mui/icons-material";
import {HeroDisplayStretch} from "../components/layout";
import directing from "../directorschair.png";
import SmallCenteredFormHeader from "../components/SmallCenteredFormHeader.js";
import SmallActionButtons from "../components/SmallActionButtons";
import Box from "@mui/material/Box";
import properties from "../general/properties.js";
import UserContext from "../user/UserContext.js";
import TaskAltIcon from '@mui/icons-material/TaskAlt';

const Login = () => {
    const [userAuth, setUserAuth] = useState({email: '', password: '', refreshToken: true})
    const [isRequestFailed, setRequestFailed] = useState(false);
    const [requestFailedMessage, setRequestFailedMessage] = useState('');
    const [showPassword, setShowPassword] = useState(false);

    const {message} = useParams();

    let navigate = useNavigate();

    const handleChange = (event) => {
        setRequestFailed(false)
        setUserAuth({...userAuth, [event.target.name]: event.target.value})
    }

    let handleSwitchChange = (e) => {

        const name = e.target.name;

        const checked = e.target.checked;

        userAuth[name] = checked;
        setUserAuth({...userAuth, checked});

    }

    const {addUser} = useContext(UserContext);

    let industry = false;

    const login = (event) => {

        event.preventDefault();

        let auth = false;

        axios.create().post(properties.backendServer + '/login', userAuth, { withCredentials: true })
            .then(response => {

                localStorage.setItem('token', response.data.token);
                localStorage.setItem('profileImg', response.data.imageFilePath);
                localStorage.setItem('backgroundProfileImage', response.data.backgroundProfileImage);
                localStorage.setItem('profileId', response.data.id);
                localStorage.setItem('authenticated', 'true');

                industry = response.data.roles.find(o => o === 'ROLE_INDUSTRY');
                const admin = response.data.roles.find(o => o === 'ROLE_ADMIN');

                if (industry != null) {
                    localStorage.setItem('industry', 'true');
                }

                if (admin != null) {
                    localStorage.setItem('admin', 'true');
                }

                addUser();

                auth = true;
            }).then(() => {
            if (auth) {
                industry ? navigate('/industryLanding') : navigate('/userView/' + localStorage.getItem("profileId"));
            }
        })
            .catch((error) => {
                if (error.message != null && error.message === 'Network Error') {
                    setRequestFailedMessage('network error');
                    setRequestFailed(true);
                } else if (error.response != null && error.response.data.message === 'No User Email Activation') {
                    navigate('/resendActivationEmail');
                } else {
                    setRequestFailedMessage('invalid credentials');
                    setRequestFailed(true);
                }
            });

    }

    const createAccount = () => {

        navigate('/user');
    }

    const forgottenPassword = () => {

        navigate('/resetPassword');
    }

    return (<HeroDisplayStretch sx={{backgroundImage: `url(${directing})`}}>
        <Container component="main" maxWidth="xs" sx={{borderRadius: "5px"}}>
            <Box sx={{
                flexDirection: 'column',
                alignItems: 'center',
                backgroundColor: '#F0F8FF',
                padding: "20px",
                width: '100%'
            }}>
                <SmallCenteredFormHeader img={pill} title={"Log in"}/>

                {message === 'please check email for validation link' &&
                    <Stack direction={"row"} gap={1} sx={{color: 'green', paddingBottom: "20px"}}>
                        <TaskAltIcon/>
                        <Typography>please check email for validation link,<br/>if not found check your spam
                            folder</Typography>
                    </Stack>
                }

                {(message != null && message !== 'please check email for validation link') &&
                    <Stack direction={"row"} gap={1} sx={{color: 'green', paddingBottom: "20px"}}>
                        <TaskAltIcon/>
                        <Typography>{message}</Typography>
                    </Stack>

                }

                <Stack gap={2} mb={2} sx={{color: 'gray'}}>
                    <TextField
                        variant="outlined"
                        required
                        fullWidth
                        id="email"
                        color="primary"
                        label="Email Address"
                        name="email"
                        autoFocus={true}
                        onChange={handleChange}
                    />
                    <TextField
                        required
                        fullWidth
                        name="password"
                        id="password"
                        onChange={handleChange}
                        type={showPassword ? "text" : "password"}
                        InputProps={{
                            endAdornment: <InputAdornment position="end">
                                <IconButton
                                    onClick={() => setShowPassword(!showPassword)}
                                >
                                    {showPassword ? <Visibility/> : <VisibilityOff/>}
                                </IconButton>
                            </InputAdornment>,
                        }}
                        label="Password"
                    />
                    {isRequestFailed &&
                        <Typography sx={{
                            paddingTop: "10px",
                            alignItems: "left",
                            color: 'mediumvioletred'
                        }}>{requestFailedMessage}</Typography>}
                    <FormControlLabel labelPlacement='end'
                                      control={<Switch checked={userAuth.refreshToken}
                                                       onChange={handleSwitchChange}
                                                       name="refreshToken"/>}
                                      label="remember me"/>


                </Stack>
                <SmallActionButtons firstText={"Login"} firstFunction={login} secondText={"Create Account"}
                                    secondFunction={createAccount} thirdText={"Forgotten Password"}
                                    thirdFunction={forgottenPassword} direction={"column"}/>
            </Box>
            <ToastContainer autoClose={1500}/>
        </Container>
    </HeroDisplayStretch>)
        ;
}

export default Login;

import React, {useContext} from 'react';
import 'react-toastify/dist/ReactToastify.css';
import Container from "@mui/material/Container";
import goodbye from "../matrixgoodbyesmall.png";
import axios from "axios";
import {useNavigate} from "react-router-dom";
import SmallActionButtons from "../components/SmallActionButtons.js";
import SmallCenteredFormHeader from "../components/SmallCenteredFormHeader.js";
import Box from "@mui/material/Box";
import properties from "../general/properties.js";
import UserContext from "../user/UserContext.js";
import {Dialog, DialogContent} from "@mui/material";
import DialogTitle from "@mui/material/DialogTitle";
import {transitionUp} from "../general/api.js";

const Logout = ({open, setOpen}) => {

    const navigate = useNavigate();
    const {logoutUser} = useContext(UserContext);

    const logout = (event) => {

        axios({
            method: 'get',
            url: properties.backendServer + '/logout',
        }).then(() => {
            logoutUser();
            setOpen(false)
            navigate('/');
        }).catch(() => {
            logoutUser();
            setOpen(false)
            navigate('/userView/' + localStorage.getItem("profileId"));
        });

        event.preventDefault();
    }

    return (
        <Dialog
            open={open}
            TransitionComponent={transitionUp}
            keepMounted
            aria-describedby="logout"
        >
            <DialogTitle
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: '#F0F8FF'
                }}>Logout</DialogTitle>
            <DialogContent sx={{backgroundColor: '#F0F8FF'}}>
                <Container component="main" maxWidth="xs" sx={{borderRadius: "5px", backgroundColor: '#F0F8FF'}}>
                    <Box sx={{
                        flexDirection: 'column',
                        alignItems: 'center',
                        backgroundColor: '#F0F8FF',
                        padding: "20px",
                        width: '100%'
                    }}>

                        <SmallCenteredFormHeader img={goodbye} title={"sure you want to say bye?"}/>

                        <SmallActionButtons firstText={"Logout"} firstFunction={logout} secondText={"Cancel"}
                                            secondFunction={() => setOpen(false)} direction={"column"}/>

                    </Box>
                </Container>
            </DialogContent>
        </Dialog>
    );
}

export default Logout;

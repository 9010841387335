import ScreenplayStepper from "./ScreenplayStepper.js";
import {useEffect} from "react";
import Box from "@mui/material/Box";

const ScreenplayWrapper = ({executeScroll, extractData, id}) => {


    useEffect(() => {

    }, [])
    return (<Box sx={{mt: '50px', alignItems: "center", display: {xs: 'none', lg: 'flex'}, justifyContent: "center"}}>
            <ScreenplayStepper id={id} executeScroll={executeScroll} extractData={extractData}/>
        </Box>

    )


}

export default ScreenplayWrapper;
import React, {useEffect, useState} from "react";
import Project from "../project/Project.js";

const LandingProject = () => {

    function toggleFavourite() {

        let project = projectData.favourite = !projectData.favourite;
        setProjectData({...projectData, project})

    }

    function getBackgroundImage(image) {

        return 'linear-gradient( rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url(' + image + ')';

    }

    let [projectData, setProjectData] = useState({
        "id": 1,
        "title": "When Harry Met Sally(ish)",
        "titleEllipsis": "When Harry Met Sally",
        "logline": "Harry Burns and Sally Albright meet when they share a car ride from Chicago to New York City. As they both navigate through their own romantic relationships, they begin to wonder if they could be more than just friends.",
        "loglineEllipsis": "Harry Burns and Sally Albright meet when they share a car ride from Chicago to New York City. As they both navigate through their own romantic relationships, they begin to wonder if they could be more than just friends.",
        "genres": [{
            "id": 4, "name": "Comedy", "orderBy": 1
        }, {
            "id": 8, "name": "Romance", "orderBy": 2
        }],
        "imageFilePath": "https://scriptvender.s3.eu-west-2.amazonaws.com/example/project/1/images/whms2.png",
        "pitch": null,
        "pitchEllipsis": null,
        "pdfFilePath": "example/project/1/pdf/whenharrymesally.pdf",
        "screenplayPdfUploaded": false,
        "editRights": false,
        "industryView": true,
        "openView": true,
        "extract": {
            "showExtract": true,
            "extractUploaded": true,
            "extractFilePath": "extract/project/1/extract/When_Harry_Met_Sally",
            "totalPages": 7,
            "startPage": 5,
            "extractText": "Someone is staring at you in personal growth."
        },
        "color": "white",
        "showPdfDownload": true,
        "favourite": true,
        "favouriteCount": 2,
        "authors": [{
            "id": 2,
            "name": "Nora Ephron",
            "username": "NoraEphron",
            "imageFilePath": "https://scriptvender.s3.eu-west-2.amazonaws.com/example/project/1/images/youngNora.png",
            "country": "United States",
            "email": "nora@hotmail.co.uk",
            "communityView": false,
            "industryView": false,
            "enabled": false,
            "logline": "Best known for my romantic comedy films. I was nominated three times for the Writers Guild of America Award and the Academy Award for Best Original Screenplay for Silkwood, When Harry Met Sally, and Sleepless in Seattle. I won the BAFTA Award for Best Original Screenplay for When Harry Met Sally, which the Writers Guild of America ranked as the 40th greatest screenplay of all time."
        }],
        "competitionResponse": [{
            "id": 0, "competition": "Nicholl Fellowship", "round": "Finalist", "year": 2022, "notes": ""
        }, {
            "id": 0, "competition": "Page Awards", "round": "Semi Finalist", "year": 2021, "notes": ""
        }],
        "quotes": [
            {
                "quote": "When Harry Met Sally is a beautiful, brainy, touching and lilting romantic comedy that should touch the heartstrings of lovers and those yearning to be in love everywhere.",
                "name": "THR Staff",
                "organisation": "THR"
            },
            {
                "quote": "Ephron wants to maintain the sitcom sense of comfort and familiarity; she creates types that we somehow already know, slightly out-of-focus images in which we can see ourselves and our friends.",
                "name": "DAVE KEHR",
                "organisation": "Chicago Tribune"
            }
        ],

    });

    let [extractData] = useState({
            "pages": [
                {
                    "pageNo": 2,
                    "pageItems": [
                        {
                            "page": 1,
                            "text": "INT. SHAKESPEARE & CO - DAY",
                            "height": 7,
                            "fontSize": 16.0006,
                            "fontSizeInPts": 16.0,
                            "xaxis": 108,
                            "yaxis": 41
                        },
                        {
                            "page": 1,
                            "text": "Sally and Marie standing in the bookstore in a sectional ",
                            "height": 7,
                            "fontSize": 16.0006,
                            "fontSizeInPts": 16.0,
                            "xaxis": 108,
                            "yaxis": 24
                        },
                        {
                            "page": 1,
                            "text": "called Personal Relationships. A shelf full of books. Marie ",
                            "height": 7,
                            "fontSize": 16.0006,
                            "fontSizeInPts": 16.0,
                            "xaxis": 108,
                            "yaxis": 12
                        },
                        {
                            "page": 1,
                            "text": "is looking up at something like “Smart Women, Foolish ",
                            "height": 7,
                            "fontSize": 16.0006,
                            "fontSizeInPts": 16.0,
                            "xaxis": 108,
                            "yaxis": 12
                        },
                        {
                            "page": 1,
                            "text": "Choices.”",
                            "height": 7,
                            "fontSize": 16.0006,
                            "fontSizeInPts": 16.0,
                            "xaxis": 108,
                            "yaxis": 12
                        },
                        {
                            "page": 1,
                            "text": "MARIE",
                            "height": 7,
                            "fontSize": 16.0006,
                            "fontSizeInPts": 16.0,
                            "xaxis": 252,
                            "yaxis": 24
                        },
                        {
                            "page": 1,
                            "text": "So I just happened to see his ",
                            "height": 7,
                            "fontSize": 16.0006,
                            "fontSizeInPts": 16.0,
                            "xaxis": 180,
                            "yaxis": 12
                        },
                        {
                            "page": 1,
                            "text": "American Express Bill.",
                            "height": 7,
                            "fontSize": 16.0006,
                            "fontSizeInPts": 16.0,
                            "xaxis": 180,
                            "yaxis": 12
                        },
                        {
                            "page": 1,
                            "text": "SALLY",
                            "height": 7,
                            "fontSize": 16.0006,
                            "fontSizeInPts": 16.0,
                            "xaxis": 252,
                            "yaxis": 24
                        },
                        {
                            "page": 1,
                            "text": "What do you mean, you just happened ",
                            "height": 7,
                            "fontSize": 16.0006,
                            "fontSizeInPts": 16.0,
                            "xaxis": 180,
                            "yaxis": 12
                        },
                        {
                            "page": 1,
                            "text": "to see it?",
                            "height": 7,
                            "fontSize": 16.0006,
                            "fontSizeInPts": 16.0,
                            "xaxis": 180,
                            "yaxis": 12
                        },
                        {
                            "page": 1,
                            "text": "MARIE",
                            "height": 7,
                            "fontSize": 16.0006,
                            "fontSizeInPts": 16.0,
                            "xaxis": 252,
                            "yaxis": 24
                        },
                        {
                            "page": 1,
                            "text": "Well, he was shaving, and there it ",
                            "height": 7,
                            "fontSize": 16.0006,
                            "fontSizeInPts": 16.0,
                            "xaxis": 180,
                            "yaxis": 12
                        },
                        {
                            "page": 1,
                            "text": "was in his briefcase.",
                            "height": 7,
                            "fontSize": 16.0006,
                            "fontSizeInPts": 16.0,
                            "xaxis": 180,
                            "yaxis": 12
                        },
                        {
                            "page": 1,
                            "text": "SALLY",
                            "height": 7,
                            "fontSize": 16.0006,
                            "fontSizeInPts": 16.0,
                            "xaxis": 252,
                            "yaxis": 24
                        },
                        {
                            "page": 1,
                            "text": "What if he came out and saw you ",
                            "height": 7,
                            "fontSize": 16.0006,
                            "fontSizeInPts": 16.0,
                            "xaxis": 180,
                            "yaxis": 12
                        },
                        {
                            "page": 1,
                            "text": "looking through his briefcase?",
                            "height": 7,
                            "fontSize": 16.0006,
                            "fontSizeInPts": 16.0,
                            "xaxis": 180,
                            "yaxis": 12
                        },
                        {
                            "page": 1,
                            "text": "MARIE",
                            "height": 7,
                            "fontSize": 16.0006,
                            "fontSizeInPts": 16.0,
                            "xaxis": 252,
                            "yaxis": 24
                        },
                        {
                            "page": 1,
                            "text": "You’re missing the point. I’m ",
                            "height": 7,
                            "fontSize": 16.0006,
                            "fontSizeInPts": 16.0,
                            "xaxis": 180,
                            "yaxis": 12
                        },
                        {
                            "page": 1,
                            "text": "telling you what I found.",
                            "height": 7,
                            "fontSize": 16.0006,
                            "fontSizeInPts": 16.0,
                            "xaxis": 180,
                            "yaxis": 12
                        },
                        {
                            "page": 1,
                            "text": "(beat)",
                            "height": 7,
                            "fontSize": 16.0006,
                            "fontSizeInPts": 16.0,
                            "xaxis": 209,
                            "yaxis": 12
                        },
                        {
                            "page": 1,
                            "text": "He spent $120 on a nightgown for ",
                            "height": 7,
                            "fontSize": 16.0006,
                            "fontSizeInPts": 16.0,
                            "xaxis": 180,
                            "yaxis": 12
                        },
                        {
                            "page": 1,
                            "text": "his wife.",
                            "height": 7,
                            "fontSize": 16.0006,
                            "fontSizeInPts": 16.0,
                            "xaxis": 180,
                            "yaxis": 12
                        },
                        {
                            "page": 1,
                            "text": "(beat)",
                            "height": 7,
                            "fontSize": 16.0006,
                            "fontSizeInPts": 16.0,
                            "xaxis": 209,
                            "yaxis": 12
                        },
                        {
                            "page": 1,
                            "text": "I don’t think he’s ever going to ",
                            "height": 7,
                            "fontSize": 16.0006,
                            "fontSizeInPts": 16.0,
                            "xaxis": 180,
                            "yaxis": 12
                        },
                        {
                            "page": 1,
                            "text": "leave her.",
                            "height": 7,
                            "fontSize": 16.0006,
                            "fontSizeInPts": 16.0,
                            "xaxis": 180,
                            "yaxis": 12
                        },
                        {
                            "page": 1,
                            "text": "SALLY",
                            "height": 7,
                            "fontSize": 16.0006,
                            "fontSizeInPts": 16.0,
                            "xaxis": 252,
                            "yaxis": 24
                        },
                        {
                            "page": 1,
                            "text": "No one thinks he’s ever going to ",
                            "height": 7,
                            "fontSize": 16.0006,
                            "fontSizeInPts": 16.0,
                            "xaxis": 180,
                            "yaxis": 12
                        },
                        {
                            "page": 1,
                            "text": "leave her.",
                            "height": 7,
                            "fontSize": 16.0006,
                            "fontSizeInPts": 16.0,
                            "xaxis": 180,
                            "yaxis": 12
                        },
                        {
                            "page": 1,
                            "text": "MARIE",
                            "height": 7,
                            "fontSize": 16.0006,
                            "fontSizeInPts": 16.0,
                            "xaxis": 252,
                            "yaxis": 24
                        },
                        {
                            "page": 1,
                            "text": "You’re right, you’re right. I know ",
                            "height": 7,
                            "fontSize": 16.0006,
                            "fontSizeInPts": 16.0,
                            "xaxis": 180,
                            "yaxis": 12
                        },
                        {
                            "page": 1,
                            "text": "you’re right.",
                            "height": 7,
                            "fontSize": 16.0006,
                            "fontSizeInPts": 16.0,
                            "xaxis": 180,
                            "yaxis": 12
                        },
                        {
                            "page": 1,
                            "text": "Marie looks up for a moment for a new book, sees something.",
                            "height": 7,
                            "fontSize": 16.0006,
                            "fontSizeInPts": 16.0,
                            "xaxis": 108,
                            "yaxis": 24
                        },
                        {
                            "page": 1,
                            "text": "MARIE",
                            "height": 7,
                            "fontSize": 16.0006,
                            "fontSizeInPts": 16.0,
                            "xaxis": 252,
                            "yaxis": 24
                        },
                        {
                            "page": 1,
                            "text": "(CONT’D)",
                            "height": 7,
                            "fontSize": 15.9942,
                            "fontSizeInPts": 15.0,
                            "xaxis": 293,
                            "yaxis": 1
                        },
                        {
                            "page": 1,
                            "text": "Someone is staring at you in ",
                            "height": 7,
                            "fontSize": 15.9942,
                            "fontSizeInPts": 15.0,
                            "xaxis": 180,
                            "yaxis": 11
                        },
                        {
                            "page": 1,
                            "text": "personal growth.",
                            "height": 7,
                            "fontSize": 15.9942,
                            "fontSizeInPts": 15.0,
                            "xaxis": 180,
                            "yaxis": 12
                        },
                        {
                            "page": 1,
                            "text": "Sally glances over to the Personal Growth section.",
                            "height": 7,
                            "fontSize": 15.9942,
                            "fontSizeInPts": 15.0,
                            "xaxis": 108,
                            "yaxis": 24
                        },
                        {
                            "page": 1,
                            "text": "SALLY",
                            "height": 7,
                            "fontSize": 15.9942,
                            "fontSizeInPts": 15.0,
                            "xaxis": 252,
                            "yaxis": 24
                        },
                        {
                            "page": 1,
                            "text": "I know him. You’d like him. He’s ",
                            "height": 7,
                            "fontSize": 15.9942,
                            "fontSizeInPts": 15.0,
                            "xaxis": 180,
                            "yaxis": 12
                        },
                        {
                            "page": 1,
                            "text": "married.",
                            "height": 7,
                            "fontSize": 15.9942,
                            "fontSizeInPts": 15.0,
                            "xaxis": 180,
                            "yaxis": 12
                        }
                    ]
                },
                {
                    "pageNo": 3,
                    "pageItems": [
                        {
                            "page": 2,
                            "text": "2.",
                            "height": 7,
                            "fontSize": 15.9942,
                            "fontSizeInPts": 15.0,
                            "xaxis": 508,
                            "yaxis": -35
                        },
                        {
                            "page": 2,
                            "text": "MARIE",
                            "height": 7,
                            "fontSize": 16.0006,
                            "fontSizeInPts": 16.0,
                            "xaxis": 252,
                            "yaxis": 36
                        },
                        {
                            "page": 2,
                            "text": "Who is he?",
                            "height": 7,
                            "fontSize": 16.0006,
                            "fontSizeInPts": 16.0,
                            "xaxis": 180,
                            "yaxis": 12
                        },
                        {
                            "page": 2,
                            "text": "SALLY",
                            "height": 7,
                            "fontSize": 16.0006,
                            "fontSizeInPts": 16.0,
                            "xaxis": 252,
                            "yaxis": 24
                        },
                        {
                            "page": 2,
                            "text": "Harry Burns. He’s a political ",
                            "height": 7,
                            "fontSize": 16.0006,
                            "fontSizeInPts": 16.0,
                            "xaxis": 180,
                            "yaxis": 12
                        },
                        {
                            "page": 2,
                            "text": "consultant.",
                            "height": 7,
                            "fontSize": 16.0006,
                            "fontSizeInPts": 16.0,
                            "xaxis": 180,
                            "yaxis": 12
                        },
                        {
                            "page": 2,
                            "text": "MARIE",
                            "height": 7,
                            "fontSize": 16.0006,
                            "fontSizeInPts": 16.0,
                            "xaxis": 252,
                            "yaxis": 24
                        },
                        {
                            "page": 2,
                            "text": "He’s cute.",
                            "height": 7,
                            "fontSize": 16.0006,
                            "fontSizeInPts": 16.0,
                            "xaxis": 180,
                            "yaxis": 12
                        },
                        {
                            "page": 2,
                            "text": "SALLY",
                            "height": 7,
                            "fontSize": 16.0006,
                            "fontSizeInPts": 16.0,
                            "xaxis": 252,
                            "yaxis": 24
                        },
                        {
                            "page": 2,
                            "text": "You think he’s cute?",
                            "height": 7,
                            "fontSize": 16.0006,
                            "fontSizeInPts": 16.0,
                            "xaxis": 180,
                            "yaxis": 12
                        },
                        {
                            "page": 2,
                            "text": "MARIE",
                            "height": 7,
                            "fontSize": 16.0006,
                            "fontSizeInPts": 16.0,
                            "xaxis": 252,
                            "yaxis": 24
                        },
                        {
                            "page": 2,
                            "text": "How do you know he’s married.",
                            "height": 7,
                            "fontSize": 16.0006,
                            "fontSizeInPts": 16.0,
                            "xaxis": 180,
                            "yaxis": 12
                        },
                        {
                            "page": 2,
                            "text": "SALLY",
                            "height": 7,
                            "fontSize": 16.0006,
                            "fontSizeInPts": 16.0,
                            "xaxis": 252,
                            "yaxis": 24
                        },
                        {
                            "page": 2,
                            "text": "Because the last time I saw him he ",
                            "height": 7,
                            "fontSize": 16.0006,
                            "fontSizeInPts": 16.0,
                            "xaxis": 180,
                            "yaxis": 12
                        },
                        {
                            "page": 2,
                            "text": "was getting married.",
                            "height": 7,
                            "fontSize": 16.0006,
                            "fontSizeInPts": 16.0,
                            "xaxis": 180,
                            "yaxis": 12
                        },
                        {
                            "page": 2,
                            "text": "MARIE",
                            "height": 7,
                            "fontSize": 16.0006,
                            "fontSizeInPts": 16.0,
                            "xaxis": 252,
                            "yaxis": 24
                        },
                        {
                            "page": 2,
                            "text": "When was that?",
                            "height": 7,
                            "fontSize": 16.0006,
                            "fontSizeInPts": 16.0,
                            "xaxis": 180,
                            "yaxis": 12
                        },
                        {
                            "page": 2,
                            "text": "SALLY",
                            "height": 7,
                            "fontSize": 16.0006,
                            "fontSizeInPts": 16.0,
                            "xaxis": 252,
                            "yaxis": 24
                        },
                        {
                            "page": 2,
                            "text": "Six years ago.",
                            "height": 7,
                            "fontSize": 16.0006,
                            "fontSizeInPts": 16.0,
                            "xaxis": 180,
                            "yaxis": 12
                        },
                        {
                            "page": 2,
                            "text": "MARIE",
                            "height": 7,
                            "fontSize": 16.0006,
                            "fontSizeInPts": 16.0,
                            "xaxis": 252,
                            "yaxis": 24
                        },
                        {
                            "page": 2,
                            "text": "So, he might not be married any ",
                            "height": 7,
                            "fontSize": 16.0006,
                            "fontSizeInPts": 16.0,
                            "xaxis": 180,
                            "yaxis": 12
                        },
                        {
                            "page": 2,
                            "text": "more.",
                            "height": 7,
                            "fontSize": 16.0006,
                            "fontSizeInPts": 16.0,
                            "xaxis": 180,
                            "yaxis": 12
                        },
                        {
                            "page": 2,
                            "text": "SALLY",
                            "height": 7,
                            "fontSize": 16.0006,
                            "fontSizeInPts": 16.0,
                            "xaxis": 252,
                            "yaxis": 24
                        },
                        {
                            "page": 2,
                            "text": "Also, he’s obnoxious.",
                            "height": 7,
                            "fontSize": 16.0006,
                            "fontSizeInPts": 16.0,
                            "xaxis": 180,
                            "yaxis": 12
                        },
                        {
                            "page": 2,
                            "text": "MARIE",
                            "height": 7,
                            "fontSize": 16.0006,
                            "fontSizeInPts": 16.0,
                            "xaxis": 252,
                            "yaxis": 24
                        },
                        {
                            "page": 2,
                            "text": "This is just like in the movies, ",
                            "height": 7,
                            "fontSize": 16.0006,
                            "fontSizeInPts": 16.0,
                            "xaxis": 180,
                            "yaxis": 12
                        },
                        {
                            "page": 2,
                            "text": "remember, like in the Lady ",
                            "height": 7,
                            "fontSize": 16.0006,
                            "fontSizeInPts": 16.0,
                            "xaxis": 180,
                            "yaxis": 12
                        },
                        {
                            "page": 2,
                            "text": "Vanishes, where she says to him, ",
                            "height": 7,
                            "fontSize": 16.0006,
                            "fontSizeInPts": 16.0,
                            "xaxis": 180,
                            "yaxis": 12
                        },
                        {
                            "page": 2,
                            "text": "you are the most obnoxious man I ",
                            "height": 7,
                            "fontSize": 16.0006,
                            "fontSizeInPts": 16.0,
                            "xaxis": 180,
                            "yaxis": 12
                        },
                        {
                            "page": 2,
                            "text": "have ever met.",
                            "height": 7,
                            "fontSize": 16.0006,
                            "fontSizeInPts": 16.0,
                            "xaxis": 180,
                            "yaxis": 12
                        },
                        {
                            "page": 2,
                            "text": "SALLY",
                            "height": 7,
                            "fontSize": 16.0006,
                            "fontSizeInPts": 16.0,
                            "xaxis": 252,
                            "yaxis": 24
                        },
                        {
                            "page": 2,
                            "text": "(correcting her)",
                            "height": 7,
                            "fontSize": 16.0006,
                            "fontSizeInPts": 16.0,
                            "xaxis": 209,
                            "yaxis": 12
                        },
                        {
                            "page": 2,
                            "text": "-- the most hateful --",
                            "height": 7,
                            "fontSize": 16.0006,
                            "fontSizeInPts": 16.0,
                            "xaxis": 180,
                            "yaxis": 12
                        },
                        {
                            "page": 2,
                            "text": "MARIE",
                            "height": 7,
                            "fontSize": 16.0006,
                            "fontSizeInPts": 16.0,
                            "xaxis": 252,
                            "yaxis": 24
                        },
                        {
                            "page": 2,
                            "text": "And then they fell madly in love.",
                            "height": 7,
                            "fontSize": 16.0006,
                            "fontSizeInPts": 16.0,
                            "xaxis": 180,
                            "yaxis": 12
                        },
                        {
                            "page": 2,
                            "text": "SALLY",
                            "height": 7,
                            "fontSize": 16.0006,
                            "fontSizeInPts": 16.0,
                            "xaxis": 252,
                            "yaxis": 24
                        },
                        {
                            "page": 2,
                            "text": "Also, he never rememebers me.",
                            "height": 7,
                            "fontSize": 16.0006,
                            "fontSizeInPts": 16.0,
                            "xaxis": 180,
                            "yaxis": 12
                        },
                        {
                            "page": 2,
                            "text": "HARRY",
                            "height": 7,
                            "fontSize": 16.0006,
                            "fontSizeInPts": 16.0,
                            "xaxis": 252,
                            "yaxis": 24
                        },
                        {
                            "page": 2,
                            "text": "Sally Albright.",
                            "height": 7,
                            "fontSize": 16.0006,
                            "fontSizeInPts": 16.0,
                            "xaxis": 180,
                            "yaxis": 12
                        }
                    ]
                },
                {
                    "pageNo": 4,
                    "pageItems": [
                        {
                            "page": 3,
                            "text": "3.",
                            "height": 7,
                            "fontSize": 15.9942,
                            "fontSizeInPts": 15.0,
                            "xaxis": 508,
                            "yaxis": -35
                        },
                        {
                            "page": 3,
                            "text": "SALLY",
                            "height": 7,
                            "fontSize": 16.0006,
                            "fontSizeInPts": 16.0,
                            "xaxis": 252,
                            "yaxis": 36
                        },
                        {
                            "page": 3,
                            "text": "Hi Harry.",
                            "height": 7,
                            "fontSize": 16.0006,
                            "fontSizeInPts": 16.0,
                            "xaxis": 180,
                            "yaxis": 12
                        },
                        {
                            "page": 3,
                            "text": "HARRY",
                            "height": 7,
                            "fontSize": 16.0006,
                            "fontSizeInPts": 16.0,
                            "xaxis": 252,
                            "yaxis": 24
                        },
                        {
                            "page": 3,
                            "text": "I thought it was you.",
                            "height": 7,
                            "fontSize": 16.0006,
                            "fontSizeInPts": 16.0,
                            "xaxis": 180,
                            "yaxis": 12
                        },
                        {
                            "page": 3,
                            "text": "SALLY",
                            "height": 7,
                            "fontSize": 16.0006,
                            "fontSizeInPts": 16.0,
                            "xaxis": 252,
                            "yaxis": 24
                        },
                        {
                            "page": 3,
                            "text": "It is. This is Marie... was Marie.",
                            "height": 7,
                            "fontSize": 16.0006,
                            "fontSizeInPts": 16.0,
                            "xaxis": 180,
                            "yaxis": 12
                        },
                        {
                            "page": 3,
                            "text": "Marie is exiting down the stairs. She waves.",
                            "height": 7,
                            "fontSize": 16.0006,
                            "fontSizeInPts": 16.0,
                            "xaxis": 108,
                            "yaxis": 24
                        },
                        {
                            "page": 3,
                            "text": "Sally turns back to Harry. She shrugs.",
                            "height": 7,
                            "fontSize": 16.0006,
                            "fontSizeInPts": 16.0,
                            "xaxis": 108,
                            "yaxis": 24
                        },
                        {
                            "page": 3,
                            "text": "HARRY",
                            "height": 7,
                            "fontSize": 16.0006,
                            "fontSizeInPts": 16.0,
                            "xaxis": 252,
                            "yaxis": 24
                        },
                        {
                            "page": 3,
                            "text": "How ya doing?",
                            "height": 7,
                            "fontSize": 16.0006,
                            "fontSizeInPts": 16.0,
                            "xaxis": 180,
                            "yaxis": 12
                        },
                        {
                            "page": 3,
                            "text": "SALLY",
                            "height": 7,
                            "fontSize": 16.0006,
                            "fontSizeInPts": 16.0,
                            "xaxis": 252,
                            "yaxis": 24
                        },
                        {
                            "page": 3,
                            "text": "Fine.",
                            "height": 7,
                            "fontSize": 16.0006,
                            "fontSizeInPts": 16.0,
                            "xaxis": 180,
                            "yaxis": 12
                        },
                        {
                            "page": 3,
                            "text": "HARRY",
                            "height": 7,
                            "fontSize": 16.0006,
                            "fontSizeInPts": 16.0,
                            "xaxis": 252,
                            "yaxis": 24
                        },
                        {
                            "page": 3,
                            "text": "Oh, fine. How’s Joe?",
                            "height": 7,
                            "fontSize": 16.0006,
                            "fontSizeInPts": 16.0,
                            "xaxis": 180,
                            "yaxis": 12
                        },
                        {
                            "page": 3,
                            "text": "SALLY",
                            "height": 7,
                            "fontSize": 16.0006,
                            "fontSizeInPts": 16.0,
                            "xaxis": 252,
                            "yaxis": 24
                        },
                        {
                            "page": 3,
                            "text": "Fine. I hear he’s fine.",
                            "height": 7,
                            "fontSize": 16.0006,
                            "fontSizeInPts": 16.0,
                            "xaxis": 180,
                            "yaxis": 12
                        },
                        {
                            "page": 3,
                            "text": "HARRY",
                            "height": 7,
                            "fontSize": 16.0006,
                            "fontSizeInPts": 16.0,
                            "xaxis": 252,
                            "yaxis": 24
                        },
                        {
                            "page": 3,
                            "text": "You’re not with Joe anymore?",
                            "height": 7,
                            "fontSize": 16.0006,
                            "fontSizeInPts": 16.0,
                            "xaxis": 180,
                            "yaxis": 12
                        },
                        {
                            "page": 3,
                            "text": "SALLY",
                            "height": 7,
                            "fontSize": 16.0006,
                            "fontSizeInPts": 16.0,
                            "xaxis": 252,
                            "yaxis": 24
                        },
                        {
                            "page": 3,
                            "text": "We just broke up.",
                            "height": 7,
                            "fontSize": 16.0006,
                            "fontSizeInPts": 16.0,
                            "xaxis": 180,
                            "yaxis": 12
                        },
                        {
                            "page": 3,
                            "text": "HARRY",
                            "height": 7,
                            "fontSize": 16.0006,
                            "fontSizeInPts": 16.0,
                            "xaxis": 252,
                            "yaxis": 24
                        },
                        {
                            "page": 3,
                            "text": "Oh, gee, that’s too bad.",
                            "height": 7,
                            "fontSize": 16.0006,
                            "fontSizeInPts": 16.0,
                            "xaxis": 180,
                            "yaxis": 12
                        },
                        {
                            "page": 3,
                            "text": "SALLY",
                            "height": 7,
                            "fontSize": 16.0006,
                            "fontSizeInPts": 16.0,
                            "xaxis": 252,
                            "yaxis": 24
                        },
                        {
                            "page": 3,
                            "text": "Yeah, well, you know. Yeah.",
                            "height": 7,
                            "fontSize": 16.0006,
                            "fontSizeInPts": 16.0,
                            "xaxis": 180,
                            "yaxis": 12
                        },
                        {
                            "page": 3,
                            "text": "(beat)",
                            "height": 7,
                            "fontSize": 16.0006,
                            "fontSizeInPts": 16.0,
                            "xaxis": 209,
                            "yaxis": 12
                        },
                        {
                            "page": 3,
                            "text": "What about you?",
                            "height": 7,
                            "fontSize": 16.0006,
                            "fontSizeInPts": 16.0,
                            "xaxis": 180,
                            "yaxis": 12
                        },
                        {
                            "page": 3,
                            "text": "HARRY",
                            "height": 7,
                            "fontSize": 16.0006,
                            "fontSizeInPts": 16.0,
                            "xaxis": 252,
                            "yaxis": 24
                        },
                        {
                            "page": 3,
                            "text": "I’m fine.",
                            "height": 7,
                            "fontSize": 16.0006,
                            "fontSizeInPts": 16.0,
                            "xaxis": 180,
                            "yaxis": 12
                        },
                        {
                            "page": 3,
                            "text": "SALLY",
                            "height": 7,
                            "fontSize": 16.0006,
                            "fontSizeInPts": 16.0,
                            "xaxis": 252,
                            "yaxis": 24
                        },
                        {
                            "page": 3,
                            "text": "How’s married life?",
                            "height": 7,
                            "fontSize": 16.0006,
                            "fontSizeInPts": 16.0,
                            "xaxis": 180,
                            "yaxis": 12
                        },
                        {
                            "page": 3,
                            "text": "HARRY",
                            "height": 7,
                            "fontSize": 16.0006,
                            "fontSizeInPts": 16.0,
                            "xaxis": 252,
                            "yaxis": 24
                        },
                        {
                            "page": 3,
                            "text": "Not so good. I’m getting a divorce.",
                            "height": 7,
                            "fontSize": 16.0006,
                            "fontSizeInPts": 16.0,
                            "xaxis": 180,
                            "yaxis": 12
                        },
                        {
                            "page": 3,
                            "text": "SALLY",
                            "height": 7,
                            "fontSize": 16.0006,
                            "fontSizeInPts": 16.0,
                            "xaxis": 252,
                            "yaxis": 24
                        },
                        {
                            "page": 3,
                            "text": "I’m sorry. I’m really sorry.",
                            "height": 7,
                            "fontSize": 16.0006,
                            "fontSizeInPts": 16.0,
                            "xaxis": 180,
                            "yaxis": 12
                        },
                        {
                            "page": 3,
                            "text": "(beat)",
                            "height": 7,
                            "fontSize": 16.0006,
                            "fontSizeInPts": 16.0,
                            "xaxis": 209,
                            "yaxis": 12
                        },
                        {
                            "page": 3,
                            "text": "When did this happen?",
                            "height": 7,
                            "fontSize": 16.0006,
                            "fontSizeInPts": 16.0,
                            "xaxis": 180,
                            "yaxis": 12
                        }
                    ]
                }
            ],
            "startPage": 0,
            "lastVisit": null
        }
    )

    useEffect(() => {

        setProjectData(prevState => ({
            ...prevState, imageFilePath: getBackgroundImage(projectData.imageFilePath)
        }));

    }, []);

    return (<Project projectData={projectData} extractData={extractData} id={1} toggleFavourite={toggleFavourite}/>)


}

export default LandingProject
import React, {useEffect, useState} from 'react';
import directing from "../directorschair.png";
import Avatar from "@mui/material/Avatar";
import MovieCreationIcon from '@mui/icons-material/MovieCreation';
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import {DropzoneArea} from "material-ui-dropzone";
import Box from "@mui/material/Box";
import {getProject} from "../general/api.js";
import axios from "axios";
import {Navigate, useParams} from "react-router-dom";
import SaveCancel from "../general/SaveCancel.js";
import {HeroDisplayStretch} from "../components/layout.js";
import properties from "../general/properties.js";
import Container from "@mui/material/Container";
import {Stack} from "@mui/material";

export default function ProjectScreenplayUpload() {

    const {id} = useParams();

    const [files, setFiles] = useState([]);

    const [project, setProject] = useState([]);

    let [redirectProject, setRedirectProject] = useState({created: false, id: 0});

    const [saveEnabled, setSaveEnabled] = useState(true);

    const [loading, setLoading] = useState(false)

    useEffect(() => {

        getProject(id)
            .then(response => {
                setProject(response.data);
            });

    }, [id]);


    let save = (e) => {

        const url = properties.backendServer + '/sg/project/' + id + '/pdf';

        let formData = new FormData();
        formData.append("file", files[0], project.titleEllipsis + Date.now() + '.pdf');

        axios.post(url, formData, {
            transformRequest: () => formData,
        }).then(
            result => setRedirectProject({created: true, id: id})
        ).catch(error => {
            console.log(error);
        });

        e.preventDefault();
    }

    let cancel = () => {

        setRedirectProject({created: true, id: id})

    };

    function fileAdded() {

        setSaveEnabled(false);


    }

    let handleUpload = (files) => {
        setFiles(files);
    }

    if (redirectProject.created) {
        return <Navigate to={`/projectView/${id}`}/>
    } else {
        return (<HeroDisplayStretch sx={{backgroundImage: `url(${directing})`, color: 'black'}}>
            <Container component="main" maxWidth="md">
                <Box mt={8} p={3}
                     sx={{display: 'flex', flexDirection: 'column', alignItems: 'center', backgroundColor: '#F0F8FF'}}>
                    <Avatar m={1} sx={{backgroundColor: 'palette.action'}}>
                        <MovieCreationIcon/>
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        Upload Pdf for {project.title}
                    </Typography>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <DropzoneArea onChange={handleUpload}
                                          acceptedFiles={['application/pdf']}
                                          showPreviews={false}
                                          showPreviewsInDropzone={false}
                                          showAlerts={false}
                                          maxFileSize={5000000}
                                          filesLimit={1}
                                          onDrop={fileAdded}
                                          dropzoneText={'Drag and drop screenplay as a pdf, or click'}/>
                        </Grid>
                        <Grid item xs={12}>
                            <Stack gap={2}>
                                {!saveEnabled && <Typography
                                    sx={{color: 'mediumvioletred', justifyContent: 'center', alignItems: 'center'}}>Uploaded:
                                    Now hit save</Typography>}
                                <SaveCancel save={save} cancel={cancel} saveEnabled={saveEnabled}
                                            setSaveEnabled={setSaveEnabled} loading={loading} setLoading={setLoading}/>
                            </Stack>

                        </Grid>

                    </Grid>

                </Box>
            </Container>
        </HeroDisplayStretch>);
    }
}



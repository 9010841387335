import React, {useEffect, useState} from 'react';
import Avatar from '@mui/material/Avatar';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import {useNavigate, useParams} from "react-router-dom";
import {FormControlLabel, Stack, Switch, Tooltip} from "@mui/material";
import directing from "../directorschair.png";
import Box from "@mui/material/Box";
import {getUser, getUserStructure, putUser} from "../general/api.js";
import HelpIcon from '@mui/icons-material/Help';
import {HeroDisplay} from "../components/layout.js";
import Container from "@mui/material/Container";
import SaveCancel from "../general/SaveCancel.js";
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import axios from "axios";
import properties from "../general/properties.js";

export default function UserSettings() {


    const {id} = useParams();

    let [loading, setLoading] = useState(false);

    const [userProjectsData, setUserProjectsData] = useState([]);

    let [user, setUser] = useState(getUserStructure());

    const navigate = useNavigate();


    useEffect(() => {

        axios({
            method: 'get', url: properties.backendServer + '/sg/user/projects/' + id
        }).then(response => {
            setUserProjectsData(response.data);
        });

        getUser(id)
            .then(response => {
                let user = response.data;
                setUser(user);
            }).then(response => {
        });

    }, [id]);


    let handleSwitchChange = (e) => {

        const name = e.target.name;

        const checked = e.target.checked;

        user[name] = checked;
        setUser({...user, checked});

    }

    let save = (e) => {

        putUser(id, user)
            .then(() => {
                navigate('/userView/' + localStorage.getItem("profileId"));
            });

        e.preventDefault();
    }

    let cancel = (e) => {

        navigate('/userView/' + localStorage.getItem("profileId"));

    };

    return (<HeroDisplay sx={{backgroundImage: `url(${directing})`}}>
        <Container component="main" maxWidth="md">
            <Box sx={{
                flexDirection: 'column',
                alignItems: 'center',
                backgroundColor: '#F0F8FF',
                padding: "20px",
                width: '100%',
                color: 'black',
            }}>
                <Box sx={{flexDirection: 'column', display: "flex", alignItems: "center", pb: '20px'}}>
                    <Avatar m={1} sx={{backgroundColor: 'palette.action'}}>
                        <AccountCircleOutlinedIcon/>
                    </Avatar>
                    <Typography component="h1" variant="h5" color="black">
                        User Settings
                    </Typography>
                </Box>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6}
                          sx={{display: 'flex', alignItems: "center", justifyContent: "center"}}>
                        <Stack gap={2} sx={{width: {xs: '100%', md: '80%'}}}>
                            <Stack direction={"row"} alignItems={"center"}>
                                <FormControlLabel
                                    control={<Switch checked={user.industryView} onChange={handleSwitchChange}
                                                     name="industryView"/>}
                                    checked={user.industryView}
                                    label="Allow industry to view"
                                />
                                <Tooltip title={<Typography fontSize={20}>Toggle on to make your profile visible to
                                    industry registered accounts</Typography>}>
                                    <HelpIcon sx={{color: "red"}}/>
                                </Tooltip>
                            </Stack>
                        </Stack>
                    </Grid>
                    <Grid item xs={12} md={6}
                          sx={{display: 'flex', alignItems: "center", justifyContent: "center"}}>
                        <Stack gap={2} sx={{width: {xs: '100%', md: '80%'}}}>
                        </Stack>
                    </Grid>
                    <Grid item xs={12}>
                        <SaveCancel save={save} cancel={cancel} loading={loading} setLoading={setLoading}/>
                    </Grid>
                </Grid>
            </Box>
        </Container>
    </HeroDisplay>);
}



import {Box, Stack} from "@mui/material";
import Typography from "@mui/material/Typography";
import React from "react";

export default function Heading({title, subheader}) {
    return (<Box sx={{
        p: {xs: '5% ', md: '2%'},
    }}>
        <Stack>
            <Typography color="primary" variant='h4'
                        sx={{display: 'flex', justifyContent: "center"}}>{title}</Typography>
            <Typography variant='h5' color={"darkgrey"} align='justify'
                        sx={{display: 'flex', justifyContent: "center", pt: "1%"}}>{subheader}</Typography>
        </Stack>
    </Box>)
}
import {useState} from "react";
import MainDashBoard from "../traffic/MainDashBoard.js";

const LandingDashBoard = () => {


    const [data] = useState({
        "impressions": {
            "total": 36
        },
        "favourites": {
            "total": 8
        },
        "views": {
            "total": 30
        },
        "downloads": {
            "total": 14
        },
        "trafficByMonths": {
            "impressions": [
                0,
                2,
                6,
                7,
                9,
                12
            ],
            "views": [
                0,
                1,
                5,
                6,
                8,
                10
            ],
            "downloads": [
                0,
                1,
                2,
                3,
                3,
                5
            ],
            "trafficByMonth": [
                {
                    "impressions": 3,
                    "projectViews": 1,
                    "downloads": 0,
                    "month": "March"
                },
                {
                    "impressions": 8,
                    "projectViews": 3,
                    "downloads": 5,
                    "month": "April"
                },
                {
                    "impressions": 12,
                    "projectViews": 5,
                    "downloads": 1,
                    "month": "May"
                },
                {
                    "impressions": 14,
                    "projectViews": 7,
                    "downloads": 2,
                    "month": "June"
                },
                {
                    "impressions": 200,
                    "projectViews": 10,
                    "downloads": 3,
                    "month": "July"
                },
                {
                    "impressions": 21,
                    "projectViews": 13,
                    "downloads": 4,
                    "month": "August"
                }
            ]
        },
        "trafficByItems": {
            "trafficByItem": [
                {
                    "total": 1,
                    "daysAgo": 3,
                    "id": 1,
                    "title": "When Harry Met Sally",
                    "country": "United States",
                    "activity": "view"
                },
                {
                    "total": 1,
                    "daysAgo": 6,
                    "id": 1,
                    "title": "When Harry Met Sally",
                    "country": "United Kingdom",
                    "activity": "impression"
                },
                {
                    "total": 1,
                    "daysAgo": 6,
                    "id": 1,
                    "title": "When Harry Met Sally",
                    "country": "United Kingdom",
                    "activity": "download"
                },
                {
                    "total": 1,
                    "daysAgo": 7,
                    "id": 1,
                    "title": "Sleepless in Seatle",
                    "country": "United Kingdom",
                    "activity": "impression"
                }
            ]
        },
        "projects": {
            "project": [
                {
                    "id": 1,
                    "title": "When Harry Met Sally",
                    "titleEllipsis": null,
                    "imageFilePath": null
                },
                {
                    "id": 2,
                    "title": "Sleepless in Seattle",
                    "titleEllipsis": null,
                    "imageFilePath": null
                }
            ]
        },
        "projectsDonut": {
            "totals": [
                16,
                4
            ],
            "titles": [
                "When Harry Met Sally",
                "Sleepless in Seattle"
            ],
            "projectDonut": [
                {
                    "activity": "1",
                    "id": 1,
                    "title": "When Harry Met Sally",
                    "total": 16,
                    "percentage": 80
                },
                {
                    "activity": "2",
                    "id": 2,
                    "title": "Sleepless in Seattle",
                    "total": 4,
                    "percentage": 20
                }
            ]
        },
        "engagementData": {
            "projects": [
                {
                    "id": 1,
                    "title": "When Harry Met Sally",
                    "pageTotal": 30,
                    "startPage": 2,
                    "engagement": [],
                    "bounce": [
                        {
                            "x": 4,
                            "y": 5,
                            "r": 10,
                            "currentUserId": 3
                        },
                        {
                            "x": 4,
                            "y": 5,
                            "r": 5,
                            "currentUserId": 4
                        },
                        {
                            "x": 5,
                            "y": 5,
                            "r": 5,
                            "currentUserId": 5
                        }
                    ]
                },
                {
                    "id": 2,
                    "title": "Sleepless in Seattle",
                    "pageTotal": 20,
                    "startPage": 0,
                    "engagement": [],
                    "bounce": [{
                        "x": 4,
                        "y": 5,
                        "r": 5,
                        "currentUserId": 3
                    }]
                }
            ]
        }
    });


    return (<MainDashBoard data={data} months={data.trafficByMonths.trafficByMonth.map(o => o.month)}/>)
}

export default LandingDashBoard
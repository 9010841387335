import {Stack, Tab, Tabs} from "@mui/material";
import CelebrationIcon from "@mui/icons-material/Celebration";
import FormatQuoteIcon from "@mui/icons-material/FormatQuote";
import CompetitionPlacings from "./CompetitionPlacings.js";
import QuoteWrapper from "../quotes/QuoteWrapper.js";

export default function ProjectTabs({
                                        projectData,
                                        tabValue,
                                        handleChange,
                                        compsLabel,
                                        quotesLabel
                                    }) {

    return (<Stack sx={{width: '90%'}}>
        <Tabs value={tabValue} onChange={handleChange} aria-label="tab details" TabIndicatorProps={{
            style: {
                backgroundColor: "white"
            }
        }} indicatorColor="inherit" textColor="inherit" color="inherit">
            {projectData.competitionResponse.length > 0 &&
                <Tab icon={<CelebrationIcon/>} label={compsLabel} value={1}/>}
            {projectData.quotes.length > 0 && <Tab icon={<FormatQuoteIcon/>} label={quotesLabel} value={2}/>}
        </Tabs>

        {tabValue === 1 && <CompetitionPlacings data={projectData}/>}
        {tabValue === 2 && <QuoteWrapper quotes={projectData.quotes}/>}
    </Stack>)
}
import React, {useEffect, useState} from 'react';
import 'react-toastify/dist/ReactToastify.css';
import Container from "@mui/material/Container";
import {useParams} from "react-router-dom";
import SmallActionButtons from "../components/SmallActionButtons.js";
import SmallCenteredFormHeader from "../components/SmallCenteredFormHeader.js";
import Box from "@mui/material/Box";
import {Dialog, DialogContent, FormControlLabel, Select, Stack, Switch, Typography} from "@mui/material";
import DialogTitle from "@mui/material/DialogTitle";
import {transitionUp} from "../general/api.js";
import TextField from "@mui/material/TextField";
import anchorman from "../anchorman.jpg";
import MenuItem from "@mui/material/MenuItem";
import axios from "axios";
import properties from "../general/properties.js";

const CompetitionModal = ({
                              open,
                              setOpen,
                              competitionEntryList,
                              setCompetitionEntryList,
                              competition,
                              setCompetitionEntry,
                              competitionEntry,
                              onDisplayConfetti,
                              round,
                              setRound
                          }) => {

    const [competitionValidation, setCompetitionValidation] = useState({message: '', error: false});
    const {id} = useParams();

    const handleCompChange = (event) => {

        setCompetitionEntry({...competitionEntry, [event.target.name]: event.target.value})
        const rounds = competition.filter(o => {
            return o.id === event.target.value
        });
        setRound(rounds[0].rounds);

        setCompetitionValidation({message: '', error: false})

    }

    const loadRounds = () => {

        const competitionId = competitionEntry.competitionId;

        if (competitionId === 0) return;

        if (competitionId == null || competitionId === '') {
            return;
        }
        const rounds = competition.filter(o => {
            return o.id === competitionId
        });
        setRound(rounds[0].rounds);

    }

    const handleChange = (event) => {

        setCompetitionEntry({...competitionEntry, [event.target.name]: event.target.value})

        setCompetitionValidation({message: '', error: false})


    }

    useEffect(() => {

        loadRounds();

    }, [loadRounds, round]);


    let save = (e) => {

        const url = properties.backendServer + '/sg/competition';
        competitionEntry["projectId"] = id;
        setCompetitionEntry(competitionEntry)

        if (competitionEntry.id === null || competitionEntry.id === 0) {
            axios.post(url, competitionEntry)
                .then((result) => {
                    setCompetitionEntryList(() => [...competitionEntryList, result.data]);
                    onDisplayConfetti();
                }).catch(err => {
                if (err.response.status === 400) {
                    err.response.data.formErrors.forEach(v => {
                        if (v.field === 'competition') {
                            setCompetitionValidation({message: v.message, error: true});
                        }

                    });
                }
            });
        } else {
            axios.put(url, competitionEntry)
                .then((result) => {
                    const competitionEntryList2 = competitionEntryList.filter(obj => obj.id !== competitionEntry.id);
                    setCompetitionEntryList(() => [...competitionEntryList2, result.data]);
                    onDisplayConfetti();
                }).catch(err => {
                if (err.response.status === 400) {
                    err.response.data.formErrors.forEach(v => {
                        if (v.field === 'competition') {
                            setCompetitionValidation({message: v.message, error: true});
                        }
                    });
                }
            });
        }

        e.preventDefault();
    }


    let handleSwitchChange = (e) => {

        const name = e.target.name;

        const checked = e.target.checked;

        competitionEntry[name] = checked;
        setCompetitionEntry({...competitionEntry, checked});

    }

    return (
        <Dialog
            open={open}
            TransitionComponent={transitionUp}
            keepMounted
            aria-describedby="competition"
        >
            <DialogTitle
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: '#F0F8FF'
                }}>Competition Placing</DialogTitle>
            <DialogContent sx={{backgroundColor: '#F0F8FF'}}>
                <Container maxWidth="xs" sx={{borderRadius: "5px"}}>
                    <Box sx={{
                        flexDirection: 'column',
                        alignItems: 'center',
                        backgroundColor: '#F0F8FF',
                        padding: "20px",
                        width: '100%',
                        color: 'black'
                    }}>
                        <SmallCenteredFormHeader img={anchorman} title={"Add competition"}/>
                        <Typography sx={{mb: '5px'}} color={"red"}>{competitionValidation.message}</Typography>
                        <Stack gap={2}>
                            <Select
                                name="competitionId"
                                id="competitionId"
                                value={competitionEntry.competitionId}
                                label="Competition"
                                onChange={handleCompChange}
                                // error={competitionValidation.error}
                                helperText={"This is a test"}
                            >
                                {competition.map((value, id) => (
                                    <MenuItem key={id} value={value.id}>{value.name}</MenuItem>))}
                            </Select>
                            <Select
                                name="roundId"
                                labelId="roundId"
                                id="roundId"
                                value={competitionEntry.roundId}
                                label="Round"
                                onChange={handleChange}
                            >
                                {round && round.map((value, id) => (
                                    <MenuItem key={id} value={value.id}>{value.name}</MenuItem>))}
                            </Select>
                            <TextField
                                name="year"
                                variant="outlined"
                                required
                                fullWidth
                                id="year"
                                label="Year"
                                onChange={handleChange}
                                value={competitionEntry.year}
                                // error={titleError}
                                // helperText={titleMessage}
                            />
                            <FormControlLabel color={'black'}
                                              control={
                                                  <Switch checked={competitionEntry.visible}
                                                          onChange={handleSwitchChange}
                                                          name="visible"/>
                                              }
                                              label="Visible to industry"
                            />
                        </Stack>
                        <SmallActionButtons firstText={"Save"} firstFunction={save} secondText={"Return"}
                                            secondFunction={() => setOpen(false)} direction={"column"}/>

                    </Box>
                </Container>
            </DialogContent>
        </Dialog>
    );
}

export default CompetitionModal;

import {Avatar, Box, Card, CardContent, Grid, LinearProgress, Typography} from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';

export const Downloads = (props) => {

    const projectDownloads = props.data;

    return (<Card
            sx={{height: '100%'}}
            {...props}
        >
            <CardContent>
                <Grid
                    container
                    spacing={3}
                    sx={{justifyContent: 'space-between'}}
                >
                    <Grid item>
                        <Typography
                            color="textSecondary"
                            gutterBottom
                            variant="overline"
                        >
                            SCREENPLAY DOWNLOADS
                        </Typography>
                        <Typography
                            color="textPrimary"
                            variant="h4"
                        >
                            {projectDownloads.total}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Avatar
                            sx={{
                                backgroundColor: 'warning.main',
                                height: 56,
                                width: 56
                            }}
                        >
                            <DownloadIcon/>
                        </Avatar>
                    </Grid>
                </Grid>
                <Box sx={{pt: 3}}>
                    <LinearProgress
                        value={75.5}
                        variant="determinate"
                    />
                </Box>
            </CardContent>
        </Card>
    );
}


import React, {useEffect, useState} from 'react';
import Avatar from '@mui/material/Avatar';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import MovieCreationIcon from '@mui/icons-material/MovieCreation';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import {FormControl, InputLabel, Stack} from "@mui/material";
import directing from "../directorschair.png";
import {getGenres} from "../general/api.js";
import SaveCancel from "../general/SaveCancel.js";
import {HeroDisplayStretch} from "../components/layout.js";
import Box from "@mui/material/Box";
import SelectGenres from "../components/SelectGenres.js";


export default function ProjectUpdate({project, setProject, saveData, cancel}) {

    const [genres, setGenres] = useState([]);

    const [titleError, setTitleError] = useState(false);

    const [titleMessage, setTitleMessage] = useState('');

    const [loading, setLoading] = useState(false)

    useEffect(() => {

        getGenres()
            .then(response => {
                setGenres(response.data);
            });

        return () => {
            setProject({});
            setGenres([]);
        };

    }, [setProject]);


    let handleChange = (e) => {

        setTitleError(false);
        setTitleMessage('');

        const name = e.target.name;

        const value = e.target.value;

        project[name] = value;
        setProject({...project, value});

    }

    let handleExtractChange = (e) => {

        const value = e.target.value;

        project.extract.extractText = value;
        setProject({...project, value});

    }

    let save = (event) => {

        if (project.title == null || project.title === "") {
            setTitleError(true);
            setTitleMessage("must not be blank");
            setLoading(false);
            return;
        }

        saveData(event);

        event.preventDefault();
    }


    return (
        <HeroDisplayStretch sx={{backgroundImage: `url(${directing})`}}>
            <Container component="main" maxWidth="md">
                <Box sx={{
                    flexDirection: 'column',
                    alignItems: 'center',
                    backgroundColor: '#F0F8FF',
                    padding: "20px",
                    width: '100%'
                }}>
                    <Box sx={{flexDirection: 'column', display: "flex", alignItems: "center"}}>
                        <Avatar m={1} sx={{backgroundColor: 'palette.action'}}>
                            <MovieCreationIcon/>
                        </Avatar>
                        <Typography component="h1" variant="h5" color="black">
                            {project.title}
                        </Typography>
                    </Box>
                    <FormControl sx={{width: '100%', marginTop: 3}} noValidate onSubmit={save}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={6}>
                                <Stack gap={2}>
                                    <InputLabel id="title"></InputLabel>
                                    <TextField
                                        name="title"
                                        variant="outlined"
                                        required
                                        fullWidth
                                        id="title"
                                        label="Screenplay title"
                                        onChange={handleChange}
                                        value={project.title}
                                        error={titleError}
                                        helperText={titleMessage}
                                    />
                                    <InputLabel id="logline"></InputLabel>
                                    <TextField
                                        name="logline"
                                        variant="outlined"
                                        required
                                        fullWidth
                                        multiline
                                        rows="3"
                                        id="logline"
                                        label="logline"
                                        onChange={handleChange}
                                        value={project.logline}
                                    />
                                    <SelectGenres project={project} setProject={setProject} allGenres={genres}/>
                                </Stack>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Grid item xs={12} sm={12}>
                                    <TextField
                                        variant="outlined"
                                        multiline
                                        rows="10"
                                        fullWidth
                                        id="pitch"
                                        label="Pitch"
                                        name="pitch"
                                        onChange={handleChange}
                                        value={project.pitch}
                                        helperText={"A more extensive description for your project can go here"}
                                    />
                                </Grid>
                            </Grid>
                            {project.id !== 0 && <Grid item xs={12}>
                                <Grid item xs={12} sm={12}>
                                    <TextField
                                        variant="outlined"
                                        multiline
                                        rows="4"
                                        fullWidth
                                        id="extract"
                                        label="Extract text"
                                        name="extract.extractText"
                                        onChange={handleExtractChange}
                                        value={project.extract.extractText}
                                        helperText={"Describe the context of your extract here. ie summarize what preceded the extract for the reader."}
                                    />
                                </Grid>
                            </Grid>
                            }
                            <Grid item xs={12}>
                                <SaveCancel save={save} cancel={cancel} loading={loading} setLoading={setLoading}/>
                            </Grid>
                        </Grid>
                    </FormControl>
                </Box>
            </Container>
        </HeroDisplayStretch>
    )

}

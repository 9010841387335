import React, {useState} from 'react';
import 'react-toastify/dist/ReactToastify.css';
import axios from "axios";
import {useNavigate} from "react-router-dom";
import properties from "../general/properties.js";
import DialogYesNo from "../admin/DialogYesNo.js";
import MessageDialog from "../admin/MessageDialog.js";

const ScreenplayOnline = ({open, setOpen, id}) => {

    const navigate = useNavigate();

    const [isMessageOpen, setMessageOpen] = useState(false);

    const run = () => {

        axios({
            method: 'post',
            url: properties.backendServer + '/sg/project/screenplay/online/' + id,
        }).then(() => {
            setOpen(false)
            navigate('/projectView/' + id);
        }).catch((err) => {
            setMessageOpen(true);
            setOpen(false)
            navigate('/projectView/' + id);
        });

    }

    return (
        <>
            <DialogYesNo open={open} action={run} handleClose={() => setOpen(false)}
                         title={"Display Screenplay Online"}
                         description={"As with PDF download, online screenplay will only be visible to industry accounts. Display Online?"}/>
            <MessageDialog title={"PDF Validity issue"}
                           message={"Invalid PDF format. Not able to convert to in screen slider format."}
                           open={isMessageOpen} setOpen={setMessageOpen}/>
        </>
    );
}

export default ScreenplayOnline;

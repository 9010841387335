import List from "@mui/material/List";
import {Link} from "react-router-dom";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import React from "react";
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import PeopleIcon from "@mui/icons-material/People.js";
import {ListItemButton} from "@mui/material";
import ClearIcon from '@mui/icons-material/Clear';

const AdminMenu = (props) => {

    return (
        <>
            <List onClick={() => props.handleDrawerClose}>
                <Divider/>
                <ListItemButton onClick={props.handleDrawerClose} key={'createIndustry'} component={Link}
                                to="/admin/createIndustry">
                    <ListItemIcon>{<PersonAddIcon/>}</ListItemIcon>
                    <ListItemText primary={'Admin: Create Industry Account'}/>
                </ListItemButton>
                <ListItemButton onClick={props.handleDrawerClose} key={'adminWriters'} component={Link}
                                to="/sg/users/0/admin">
                    <ListItemIcon>{<PeopleIcon/>}</ListItemIcon>
                    <ListItemText primary={'Admin View Writers'}/>
                </ListItemButton>
                <ListItemButton onClick={props.handleDrawerClose} key={'adminClearCache'} component={Link}
                                to="/admin/clearCache">
                    <ListItemIcon>{<ClearIcon/>}</ListItemIcon>
                    <ListItemText primary={'Clear Caches'}/>
                </ListItemButton>

            </List>
        </>
    )


}

export default AdminMenu
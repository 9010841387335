import React from 'react';
import {Chart as ChartJS, Legend, LinearScale, PointElement, Tooltip} from 'chart.js';
import {Bubble} from 'react-chartjs-2';
import {Box, Card, CardContent, CardHeader, Divider, useTheme} from "@mui/material";

ChartJS.register(LinearScale, PointElement, Tooltip, Legend);


export const ScreenplayBubbleChart = ({data}) => {

    const theme = useTheme();

    const bubbleData = {
        datasets: [
            {
                label: 'Engagement',
                data: data.engagement,
                backgroundColor: 'rgba(255, 99, 132, 0.5)',
            },
            {
                label: 'Bounce',
                data: data.bounce,
                backgroundColor: 'rgba(53, 162, 235, 0.5)',
            },
        ]
    };

    const footer = (context) => {

        return 'Page: ' + context.parsed.x;
    };

    const options = {
        animation: true,
        cornerRadius: 20,
        scaleShowLabels: false,
        layout: {padding: 0},
        legend: {display: false},
        maintainAspectRatio: false,
        responsive: true,
        scales: {
            y: {
                grid: {
                    display: false
                }, ticks: {stepSize: 1}, min: 0, max: 10, display: false
            },
            x: {
                grid: {
                    display: false
                }, ticks: {stepSize: 1}, min: data.startPage, max: data.pageTotal
            }
        },
        xAxes: [{
            ticks: {
                fontColor: theme.palette.text.secondary
            }
        }],
        yAxes: [{
            ticks: {
                fontColor: theme.palette.text.secondary, beginAtZero: true, min: 10
            }, gridLines: {
                display: false, drawBorder: false
            }
        }],
        plugins: {
            tooltip: {
                callbacks: {
                    label: function (context) {
                        return footer(context);
                    }
                }
            }
        },
        tooltips: {
            backgroundColor: theme.palette.background.paper,
            bodyFontColor: theme.palette.text.secondary,
            borderColor: theme.palette.divider,
            borderWidth: 1,
            enabled: true,
            mode: 'index',
            titleFontColor: theme.palette.text.primary
        }
    };

    return (
        <Card>
            <CardHeader
                title={data.title}
            />
            <Divider/>
            <CardContent>
                <Box
                    sx={{
                        height: 400, position: 'relative'
                    }}
                >
                    <Bubble options={options} data={bubbleData}/>
                </Box>
            </CardContent>
            <Divider/>
        </Card>


    )
}

export default ScreenplayBubbleChart;

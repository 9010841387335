import React from "react";
import {Box, Grid, Stack, Typography} from "@mui/material";
import shooting from "../emocafegirl.png";
import Container from "@mui/material/Container";
import DoneIcon from '@mui/icons-material/Done';
import {AnimationOnScroll} from 'react-animation-on-scroll';
import "animate.css/animate.min.css";


const Visibility = () => {


    return (<Box sx={{backgroundColor: "white", width: '100%', paddingBottom: '40px'}}>

            <Grid container spacing={2} sx={{backgroundColor: 'white'}}>
                <Grid item xs={12} md={6}>
                    <Container maxWidth="sm">
                        <Box component={"img"} src={shooting}
                             sx={{width: "100%", paddingBottom: "20px;", borderRadius: "5px"}}
                             alt="Shooting a script"/>
                    </Container>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Box>

                        <Typography variant={'h4'} p={'20px'} color="primary">Get your screenwriting vision
                            realised on screen.</Typography>
                        <AnimationOnScroll animateIn="animate__fadeInRightBig">

                            <Stack direction="column" pl={'10px'} gap={2}>
                                <Stack direction="row" alignItems="left" gap={1}>
                                    <DoneIcon color="primary"/>
                                    <Typography variant="body1">Place your screenplay project on the ScriptVender
                                        marketplace</Typography>
                                </Stack>
                                <Stack direction="row" alignItems="left" gap={1}>
                                    <DoneIcon color="primary"/>
                                    <Typography variant="body1">Include any successes in the amateur screenplay
                                        competitions</Typography>
                                </Stack>
                                <Stack direction="row" alignItems="left" gap={1}>
                                    <DoneIcon color="primary"/>
                                    <Typography variant="body1">Got some cool industry feedback? Include it in your
                                        quotes section</Typography>
                                </Stack>
                            </Stack>
                        </AnimationOnScroll>
                    </Box>

                </Grid>
            </Grid>
        </Box>


    )
}

export default Visibility
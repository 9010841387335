import React, {useState} from 'react';
import {getProjectRequestStructure, postProject} from "../general/api.js";
import {useNavigate} from "react-router-dom";
import ProjectUpdate from "./ProjectUpdate";


export default function ProjectCreate() {

    const navigate = useNavigate();

    let [project, setProject] = useState(getProjectRequestStructure);

    const save = () => {

        postProject(project)
            .then(() => navigate('/userView/' + localStorage.getItem("profileId")));

    }

    const cancel = () => {

        navigate('/userView/' + localStorage.getItem("profileId"));

    }

    return (
        <ProjectUpdate project={project} setProject={setProject} saveData={save} cancel={cancel}/>
    )

}

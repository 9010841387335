import IndustryLanding from "./industryLanding.js";

export default function IndustryFinalistsProjects() {

    return (<>
            <IndustryLanding url='/sg/industry/finalists/projects/'/>
        </>

    )


}
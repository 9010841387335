import {Box} from "@mui/material";
import React, {Fragment} from "react";
import {Link} from "react-router-dom";
import {CreateNewFolder} from "@mui/icons-material";
import {LandingButton} from "../components/buttons.js";

const CreateFirstProject = ({data}) => {


    if ((!data || data.length === 0)) {
        return (<Box minWidth={'500px'}
                     sx={{display: 'flex', justifyContent: 'center', minHeight: '25vh', alignItems: 'center'}}>

            <LandingButton startIcon={<CreateNewFolder/>} component={Link} to={"/projectCreate"}>Create first
                project</LandingButton>

        </Box>)

    } else {
        return <Fragment></Fragment>
    }
}

export default CreateFirstProject;
import {styled} from "@mui/material/styles";
import Button from "@mui/material/Button";
import {blue} from "@mui/material/colors";

export const LandingButton = styled(Button)(({theme}) => ({
    color: theme.palette.getContrastText(blue[500]),
    textTransform: 'none',
    fontSize: '20px',
    borderRadius: 28,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    contrastText: "black",
    backgroundColor: '#F0F8FF',
    '&:hover': {
        backgroundColor: blue[700],
        "&.MuiButton-text": {
            color: "black"
        }
    },
    "&.MuiButton-text": {
        color: "grey"
    },
}));


import PerfectScrollbar from 'react-perfect-scrollbar';
import {
    Box,
    Card,
    CardHeader,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TableSortLabel,
    Tooltip
} from '@mui/material';
import {SeverityPill} from './severityPill';

// const traffic = [
//   {
//     country: 'United Kingdom',
//     createdAt: 1555016400000,
//     status: 'impression',
//     project: 'When Harry met Sally'
//   },
//   {
//     country: 'United States',
//     createdAt: 1555016400000,
//     status: 'download',
//     project: 'When Harry met Sally'
//   },
//   {
//     country: 'United Kingdom',
//     createdAt: 1555016400000,
//     status: 'project view',
//     project: 'When Harry met Sally'
//   },
//
// ];

export const TrafficTable = (props) => {

    const traffic = props.data;


    return (<Card {...props}>
            <CardHeader title="Latest Traffic"/>
            <PerfectScrollbar>
                <Box sx={{minWidth: 800}}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    Country
                                </TableCell>
                                <TableCell>
                                    Title
                                </TableCell>
                                <TableCell sortDirection="desc">
                                    <Tooltip
                                        enterDelay={300}
                                        title="Sort"
                                    >
                                        <TableSortLabel
                                            active
                                            direction="desc"
                                        >
                                            Days ago
                                        </TableSortLabel>
                                    </Tooltip>
                                </TableCell>
                                <TableCell>
                                    Activity
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {traffic.map((item, i) => (
                                <TableRow
                                    hover
                                    key={i}
                                >
                                    <TableCell>
                                        {item.country}
                                    </TableCell>
                                    <TableCell>
                                        {item.title}
                                    </TableCell>
                                    <TableCell>
                                        {item.daysAgo === 0 ? 'today' : item.daysAgo}
                                    </TableCell>
                                    <TableCell>
                                        <SeverityPill
                                            color={(item.activity === 'view' && 'success')
                                                || (item.activity === 'impression' && 'error')
                                                || (item.activity === 'download' && 'warning')
                                                || 'warning'}
                                        >
                                            {item.activity}
                                        </SeverityPill>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </Box>
            </PerfectScrollbar>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    p: 2
                }}
            >
                {/*<Button*/}
                {/*    color="primary"*/}
                {/*    endIcon={<ArrowRightIcon fontSize="small"/>}*/}
                {/*    size="small"*/}
                {/*    variant="text"*/}
                {/*>*/}
                {/*    View all*/}
                {/*</Button>*/}
            </Box>
        </Card>
    )
};

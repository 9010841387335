import {Checkbox, Tooltip} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import React from "react";
import {Visibility, VisibilityOff} from "@mui/icons-material";

const Industry = ({industryView, editRights}) => {


    return (
        (editRights) ?
            <Tooltip title={industryView ? "This project visible to industry" : "This project not visible to industry"}>
                <IconButton sx={{p: 0, m: 0}} aria-label="add to favorites">
                    <Checkbox sx={{p: 0, m: 0}} checked={industryView}
                              icon={<VisibilityOff sx={{color: "white"}} fontSize="large"/>}
                        // onClick={toggle}
                              checkedIcon={<Visibility fontSize="large" sx={{color: "green"}}/>}/>
                </IconButton>
            </Tooltip> : <> </>

    )

}

export default Industry;
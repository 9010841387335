import TextField from "@mui/material/TextField";
import React, {useState} from "react";
import directing from "../directorschair.png";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import SmallCenteredFormHeader from "../components/SmallCenteredFormHeader.js";
import SmallActionButtons from "../components/SmallActionButtons.js";
import {ToastContainer} from "react-toastify";
import {HeroDisplayStretch} from "../components/layout.js";
import axios from "axios";
import properties from "../general/properties.js";
import {useNavigate} from "react-router-dom";

const ResetPassword = () => {

    const [email, setEmail] = useState('');
    const [emailError, setEmailError] = useState(false);
    const [emailErrorMessage, setEmailErrorMessage] = useState('');
    const [requestFailed, setRequestFailed] = useState('');

    let navigate = useNavigate();

    const resetPassword = () => {

        axios.create().post(properties.backendServer + '/op/user/password/reset/' + email)
            .then(response => {

                navigate('/login/password%20reset');

            })
            .catch((error) => {
                setRequestFailed(true);
                setEmailError(true)
            });


    }

    const loginPage = () => {

        navigate('/login');

    }

    return (
        <>
            <HeroDisplayStretch sx={{backgroundImage: `url(${directing})`}}>
                <Container component="main" maxWidth="xs" sx={{borderRadius: "5px"}}>
                    <Box sx={{
                        flexDirection: 'column',
                        alignItems: 'center',
                        backgroundColor: '#F0F8FF',
                        padding: "20px",
                        width: '100%'
                    }}>
                        <SmallCenteredFormHeader title={"Reset Password"}/>

                        <TextField
                            variant="outlined"
                            required
                            fullWidth
                            name="password"
                            id="password"
                            onChange={event => {
                                setEmailError(false);
                                setEmail(event.target.value)
                            }}
                            value={email}
                            type={"email"}
                            label="Email"
                            error={emailError}
                            helperText={emailError === true ? 'Unable to reset password' : ' '}
                        />

                        <SmallActionButtons firstText={"Reset Password"} firstFunction={resetPassword}
                                            secondText={"Login"} secondFunction={loginPage}
                                            direction={"column"}/>
                    </Box>
                    <ToastContainer autoClose={1500}/>
                </Container>
            </HeroDisplayStretch>

        </>
    )
}

export default ResetPassword;
import {Grow} from "@mui/material";
import FormTitle from "../layout/FormTitle.js";
import React from "react";
import Box from "@mui/material/Box";

const SmallCenteredFormHeader = ({img, title}) => {

    return (
        <Box mb={2}>
            <Grow in={true} timeout={4000} direction={"left"}>
                <Box component={"img"} sx={{width: "100%", paddingBottom: "20px;", borderRadius: "5px"}} src={img}/>
            </Grow>
            <FormTitle title={title}/>
        </Box>

    );

}

export default SmallCenteredFormHeader;
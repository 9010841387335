import {useContext, useEffect} from "react";
import UserContext from "../user/UserContext.js";

const LoadData = () => {

    const {addUser} = useContext(UserContext);

    useEffect(() => {

        addUser();

    }, []);

    return null;

}

export default LoadData;
import {ListItemIcon, ListItemText, Menu, MenuItem} from "@mui/material";
import React, {useState} from "react";
import Avatar from "@mui/material/Avatar";
import {AccountCircle, Logout, Person, Settings} from "@mui/icons-material";
import {useNavigate} from "react-router-dom";
import ImageIcon from '@mui/icons-material/Image';
import DeleteImage from '@mui/icons-material/HideImage';
import Box from "@mui/material/Box";
import PasswordOutlinedIcon from '@mui/icons-material/PasswordOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';

export default function ProfileAvatar({avatarData, logoutOpen, setLogoutOpen}) {

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const navigate = useNavigate();

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClick = (forward) => {
        setAnchorEl(null);
        navigate(forward);
    }

    const handleClose = () => {
        setAnchorEl(null);
    };


    return (<Box>
        <Avatar id="basic-button"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
                sx={{
                    border: '0.1px solid #F0F8FF',
                    "&:hover": {opacity: .8, textDecoration: 'underline', cursor: 'pointer'}
                }}
                src={avatarData.imageFilePath}/>
        <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
                'aria-labelledby': 'basic-button',
            }}
        >
            <MenuItem onClick={() => handleMenuClick('/userView/' + avatarData.profileId)}>
                <ListItemIcon>
                    <AccountCircle fontSize="medium"/>
                </ListItemIcon>
                <ListItemText>View my Profile</ListItemText>
            </MenuItem>
            <MenuItem onClick={() => handleMenuClick('/userEdit')}>
                <ListItemIcon>
                    <Person fontSize="medium"/>
                </ListItemIcon>
                <ListItemText>Edit my Profile</ListItemText>
            </MenuItem>
            <MenuItem onClick={() => handleMenuClick('/userImageUpload/' + avatarData.profileId)}>
                <ListItemIcon>
                    <Person fontSize="medium"/>
                </ListItemIcon>
                <ListItemText>Upload avatar Image</ListItemText>
            </MenuItem>
            <MenuItem onClick={() => handleMenuClick('/userBackgroundImageUpload/' + avatarData.profileId)}>
                <ListItemIcon>
                    <ImageIcon fontSize="medium"/>
                </ListItemIcon>
                <ListItemText>Upload background Image</ListItemText>
            </MenuItem>
            {avatarData.backgroundProfileImage != null &&
                <MenuItem onClick={() => handleMenuClick('/userBackgroundImageDelete/' + avatarData.profileId)}>
                    <ListItemIcon>
                        <DeleteImage fontSize="medium"/>
                    </ListItemIcon>
                    <ListItemText>Remove background Image</ListItemText>
                </MenuItem>
            }
            <MenuItem onClick={() => handleMenuClick('/userSettings/' + avatarData.profileId)}><ListItemIcon>
                <Settings fontSize="medium"/>
            </ListItemIcon>
                <ListItemText>My Settings</ListItemText>
            </MenuItem>
            <MenuItem onClick={() => handleMenuClick('/changePassword/' + avatarData.profileId)}><ListItemIcon>
                <PasswordOutlinedIcon fontSize="medium"/>
            </ListItemIcon>
                <ListItemText>Change Password</ListItemText>
            </MenuItem>
            <MenuItem onClick={() => handleMenuClick('/changeEmail/' + avatarData.profileId)}><ListItemIcon>
                <EmailOutlinedIcon fontSize="medium"/>
            </ListItemIcon>
                <ListItemText>Change Email</ListItemText>
            </MenuItem>
            <MenuItem onClick={() => {
                setAnchorEl(null);
                setLogoutOpen(!logoutOpen)
            }}><ListItemIcon>
                <Logout fontSize="medium"/>
            </ListItemIcon>
                <ListItemText>Logout</ListItemText></MenuItem>
        </Menu>
    </Box>);
}
import React, {useEffect, useState} from 'react';
import Avatar from '@mui/material/Avatar';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import {Navigate, useParams} from "react-router-dom";
import {FormControlLabel, InputLabel, Select, Stack, Switch, Tooltip} from "@mui/material";
import directing from "../directorschair.png";
import Box from "@mui/material/Box";
import {getProjectRequestStructure, loadProjectAndSet, putProject} from "../general/api.js";
import HelpIcon from '@mui/icons-material/Help';
import SaveCancel from './../general/SaveCancel.js'
import Container from "@mui/material/Container";
import MovieCreationIcon from '@mui/icons-material/MovieCreation';
import {HeroDisplayStretch} from "../components/layout.js";
import MenuItem from "@mui/material/MenuItem";
import Loading from "../general/Loading.js";


export default function ProjectSettings() {


    const {id} = useParams();

    let [loadedProject, setLoadedProject] = useState({created: false, id: 0});
    let [redirectProject, setRedirectProject] = useState({created: false, id: 0});

    let [project, setProject] = useState(getProjectRequestStructure());

    const [loading, setLoading] = useState(false)

    useEffect(() => {

        loadProjectAndSet(id, setProject, setLoadedProject);


    }, [id]);


    let handleSwitchChange = (e) => {

        const name = e.target.name;

        const checked = e.target.checked;

        project[name] = checked;
        setProject({...project, checked});

    }

    let handleSwitchExtractChange = (e) => {

        const name = e.target.name;

        const checked = e.target.checked;

        project.extract[name] = checked;
        setProject({...project, checked});

    }

    let save = (e) => {

        putProject(id, project)
            .then(() => {
                setRedirectProject({created: true, id: id})
            });


        e.preventDefault();
    }

    const handleChange = (event) => {

        setProject({...project, [event.target.name]: event.target.value})
    }

    let cancel = () => {

        setRedirectProject({created: true, id: id})

    };

    if (!loadedProject.created) {
        return <Loading/>
    } else if (redirectProject.created) {
        return <Navigate to={`/projectView/${loadedProject.id}`}/>
    } else {
        return (<HeroDisplayStretch sx={{backgroundImage: `url(${directing})`}}>
            <Container component="main" maxWidth="md">
                <Box sx={{
                    flexDirection: 'column',
                    alignItems: 'center',
                    backgroundColor: '#F0F8FF',
                    padding: "20px",
                    width: '100%',
                    color: 'black',
                }}>
                    <Box sx={{flexDirection: 'column', display: "flex", alignItems: "center", pb: '20px'}}>
                        <Avatar m={1} sx={{backgroundColor: 'palette.action'}}>
                            <MovieCreationIcon/>
                        </Avatar>
                        <Typography component="h1" variant="h5" color="black">
                            Project Settings
                        </Typography>
                    </Box>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6}
                              sx={{display: 'flex', alignItems: "center", justifyContent: "center"}}>
                            <Stack gap={2} sx={{width: {xs: '100%', md: '80%'}}}>
                                <Stack direction={"row"} alignItems={"center"}>
                                    <FormControlLabel
                                        control={<Switch checked={project.industryView} onChange={handleSwitchChange}
                                                         name="industryView"/>}
                                        checked={project.industryView}
                                        label="Allow industry to view"
                                    />
                                    <Tooltip title={<Typography fontSize={30}>Toggle on to make your project visible to
                                        industry
                                        registered accounts"</Typography>}>
                                        <HelpIcon sx={{color: "red"}}/>
                                    </Tooltip>
                                </Stack>
                                <InputLabel id="color-selectl">Font Color</InputLabel>
                                <Select
                                    labelId="color-select"
                                    id="color-select"
                                    value={project.color}
                                    name="color"
                                    label="Color"
                                    onChange={handleChange}
                                >
                                    <MenuItem value="white">white</MenuItem>
                                    <MenuItem value="aliceblue">alice blue</MenuItem>
                                    <MenuItem value="black">black</MenuItem>
                                </Select>

                            </Stack>
                        </Grid>
                        <Grid item xs={12} md={6}
                              sx={{display: 'flex', alignItems: "center", justifyContent: "center"}}>
                            <Stack gap={2} sx={{width: {xs: '100%', md: '80%'}}}>
                                <FormControlLabel
                                    control={
                                        <Switch disabled={!project.pdfUploaded} checked={project.showPdfDownload}
                                                onChange={handleSwitchChange}
                                                name="showPdfDownload"/>
                                    }
                                    label="Pdf Download"
                                />
                                <FormControlLabel
                                    control={
                                        <Switch disabled={!project.extract.extractUploaded}
                                                checked={project.extract.showExtract}
                                                onChange={handleSwitchExtractChange}
                                                name="showExtract"/>
                                    }
                                    label="View Extract Online"
                                />
                            </Stack>
                        </Grid>

                        <Grid item xs={12}>
                            <SaveCancel save={save} cancel={cancel} loading={loading} setLoading={setLoading}/>
                        </Grid>
                    </Grid>
                </Box>
            </Container>
        </HeroDisplayStretch>);
    }
}


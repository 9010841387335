import {Bar} from 'react-chartjs-2';
import {Box, Card, CardContent, CardHeader, Divider, useTheme} from '@mui/material';

export const OverallTraffic = (props) => {
    const theme = useTheme();

    const trafficByDate = props.data;

    let months = props.months

    let impressions = [].concat(trafficByDate.impressions);
    let views = [].concat(trafficByDate.views);
    let downloads = [].concat(trafficByDate.downloads);

    const data = {
        datasets: [{
            backgroundColor: 'red',
            barPercentage: 1,
            barThickness: 12,
            borderRadius: 4,
            categoryPercentage: 1,
            data: impressions,
            label: 'Impressions',
            maxBarThickness: 10
        }, {
            backgroundColor: 'green',
            barPercentage: 1,
            barThickness: 12,
            borderRadius: 4,
            categoryPercentage: 1,
            data: views,
            label: 'Project Visits',
            maxBarThickness: 10
        }, {
            backgroundColor: 'orange',
            barPercentage: 1,
            barThickness: 12,
            borderRadius: 4,
            categoryPercentage: 1,
            data: downloads,
            label: 'Screenplay Downloads',
            maxBarThickness: 10
        }], labels: months
    };

    const options = {
        animation: true,
        cornerRadius: 20,
        layout: {padding: 0},
        legend: {display: false},
        maintainAspectRatio: false,
        responsive: true,
        scales: {
            y: {
                grid: {
                    display: false
                }, ticks: {stepSize: 1}, min: 0
            }
        },
        xAxes: [{
            ticks: {
                fontColor: theme.palette.text.secondary
            }
        }],
        yAxes: [{
            ticks: {
                fontColor: theme.palette.text.secondary, beginAtZero: true, min: 5
            }, gridLines: {
                display: false, drawBorder: false
            }
        }],
        tooltips: {
            backgroundColor: theme.palette.background.paper,
            bodyFontColor: theme.palette.text.secondary,
            borderColor: theme.palette.divider,
            borderWidth: 1,
            enabled: true,
            footerFontColor: theme.palette.text.secondary,
            intersect: false,
            mode: 'index',
            titleFontColor: theme.palette.text.primary
        }
    };

    return (<Card {...props}>
        <CardHeader
            title="Overall traffic for the past 6 months"
        />
        <Divider/>
        <CardContent>
            <Box
                sx={{
                    height: 400, position: 'relative'
                }}
            >
                <Bar
                    data={data}
                    options={options}
                />
            </Box>
        </CardContent>
        <Divider/>
        <Box
            sx={{
                display: 'flex', justifyContent: 'flex-end', p: 2
            }}
        >
        </Box>
    </Card>);
};

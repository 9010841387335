import React, {useEffect, useState} from "react";
import axios from "axios";
import MainDashBoard from "./MainDashBoard.js";
import properties from "../general/properties.js";
import Loading from "../general/Loading.js";

const DashBoardWrapper = () => {

    const [data, setData] = useState({
        "impressions": {
            "total": 0
        },
        "favourites": {
            "total": 0
        },
        "views": {
            "total": 0
        },
        "downloads": {
            "total": 0
        },
        "trafficByMonths": {
            "trafficByMonth": [
                {
                    "impressions": 0,
                    "projectViews": 0,
                    "downloads": 0,
                    "month": ""
                }
            ],
            "impressions": [
                0
            ],
            "views": [
                0
            ],
            "downloads": [
                0
            ]
        },
        "trafficByItems": {
            "trafficByItem": [
                {
                    "total": 0,
                    "daysAgo": 0,
                    "id": 0,
                    "title": "",
                    "country": "",
                    "activity": ""
                }
            ]
        },
        "projects": {
            "project": [
                {
                    "id": 0,
                    "title": "",
                    "titleEllipsis": "",
                    "imageFilePath": ""
                }
            ]
        },
        "projectsDonut": {
            "projectDonut": [
                {
                    "activity": "",
                    "id": 0,
                    "title": "",
                    "total": 0,
                    "percentage": 0,
                    "color": ""
                }
            ],
            "totals": [
                0
            ],
            "titles": [
                ""
            ]
        },
        "engagementData": {
            "projects": [
                {
                    "id": 0,
                    "title": "",
                    "pageTotal": 0,
                    "startPage": 0,
                    "engagement": [
                        {
                            "x": 0,
                            "y": 0,
                            "r": 0,
                            "currentUserId": 0
                        }
                    ],
                    "bounce": [
                        {
                            "x": 0,
                            "y": 0,
                            "r": 0,
                            "currentUserId": 0
                        }
                    ]
                }
            ]
        }
    });
    const [loading, setLoading] = useState(true);


    useEffect(() => {

        axios({
            method: 'get',
            url: properties.backendServer + '/sg/user/dashboard',
        }).then(response => {
            setData(response.data);
            setLoading(false);
        })

        return () => {
            setData({});
        };

    }, []);

    if (loading) {
        return (<Loading/>)
    } else return (
        <>
            <MainDashBoard data={data} months={data.trafficByMonths.trafficByMonth.map(o => o.month)}/>
        </>
    );
}


export default DashBoardWrapper;

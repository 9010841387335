import {Divider, List, ListItemButton, ListItemIcon, ListItemText} from "@mui/material";
import {Link} from "react-router-dom";
import {Logout} from "@mui/icons-material";
import InfoIcon from '@mui/icons-material/Info';
import React from "react";

const AuthenticatedMenu = (props) => {

    return (
        <List onClick={() => props.handleDrawerClose()} dense={true}>
            <Divider/>
            <List onClick={() => props.handleDrawerClose} dense={true}>
                <ListItemButton key={'about'} component={Link} to="/about">
                    <ListItemIcon>{<InfoIcon/>}</ListItemIcon>
                    <ListItemText primary={'About'}/>
                </ListItemButton>
                <ListItemButton key={'logout'} onClick={() => {
                    props.setLogoutOpen(!props.logoutOpen)
                }}>
                    <ListItemIcon>{<Logout/>}</ListItemIcon>
                    <ListItemText primary={'Logout'}/>
                </ListItemButton>
            </List>
        </List>

    )

}

export default AuthenticatedMenu;
import React from 'react';
import {Grid, Grow, Stack, Typography} from "@mui/material";
import Chip from "@mui/material/Chip";


export default function ProjectHeader(props) {

    const headerData = props.data;

    const color = headerData.color;

    return (
        <Grid container direction={"column"}>
            <Grid item md={6}>
                <Grow in={true} timeout={4000} direction={"left"}>
                    <Typography
                        component="h1"
                        variant="h2"
                        align="left"
                        color={color}
                        gutterBottom
                    >
                        {headerData.title}
                    </Typography>
                </Grow>
            </Grid>
            <Grid item md={9}>
                <Typography component="h5" variant="h5" align="left" color="text.secondary" paragraph
                            sx={{width: '80%', zIndex: 3, color: {color}}}>
                    {headerData.logline}
                </Typography>
            </Grid>
            <Grid item md={8}>
                <Typography gutterBottom color={"textSecondary"} fontStyle={"italic"} variant={"h6"}>
                    <Stack direction="row" gap={1} flexWrap={"wrap"}>
                        {headerData.genres.map(v => {
                            return <Chip sx={{color: color}} key={v.id} label={v.name}/>
                        })}
                    </Stack>
                </Typography>
            </Grid>
        </Grid>
    );
}

import IndustryLanding from "./industryLanding.js";

export default function IndustryFavouriteWriters() {

    return (<>
            <IndustryLanding url='/sg/industry/favourite/writers/'/>
        </>

    )


}
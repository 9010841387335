import React, {useState} from 'react';
import {Avatar, Box, Stack, Typography} from "@mui/material";
import {ProfilePreview} from "./ProfilePreview.js";

function UserInfo({authors}) {

    const [profileViewOpen, setProfileViewOpen] = useState(false);

    const [author, setAuthor] = useState({});

    if (authors === null) {
        return
    }
    return (
        <>
            <Box>
                <Stack direction={{xs: "column", md: "row"}} gap={{xs: 2, md: 0}}>
                    {
                        authors.map((value, i) => (
                            <Stack key={i} sx={{
                                cursor: 'pointer',
                                opacity: 1,
                                borderRadius: 6,
                                "&:hover": {opacity: .8, textDecoration: 'underline'}
                            }} direction={"row"} gap={1} pr={2}
                                   onClick={() => {
                                       setAuthor(value);
                                       setProfileViewOpen(true)
                                   }}>
                                <Avatar
                                    src={value.imageFilePath}
                                />
                                <Box>
                                    <Stack>
                                        <Typography>{value.name}</Typography>
                                        <Typography
                                            sx={{fontStyle: 'italic', fontSize: '80%'}}>{value.country}</Typography>
                                    </Stack>
                                </Box>
                            </Stack>
                        ))

                    }
                </Stack>


            </Box>

            <ProfilePreview author={author} open={profileViewOpen} setOpen={setProfileViewOpen}/>
        </>

    );
}

export default UserInfo;
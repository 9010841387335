import {Box, Container, Grid} from "@mui/material";
import {Impressions} from "./impressions.js";
import {ProjectView} from "./projectView.js";
import {Downloads} from "./downloads.js";
import {Favourites} from "./favourites.js";
import {OverallTraffic} from "./overallTraffic.js";
import {DonutByProject} from "./donutByProject.js";
import {YourProjects} from "./yourprojects.js";
import {TrafficTable} from "./trafficTable.js";
import React from "react";
import {BubbleChartProjectsWrap} from "./BubbleChartProjectsWrap";
import Heading from "../landing/Heading.js";
import {AnimationOnScroll} from "react-animation-on-scroll";


const MainDashBoard = ({data, months}) => {

    return (
        <Box
            component="main"
            sx={{
                flexGrow: 1,
                py: 8
            }}
        >
            <Container maxWidth={false}>
                <Grid
                    container
                    spacing={3}
                >
                    <Grid
                        item
                        lg={3}
                        sm={6}
                        xl={3}
                        xs={12}
                    >
                        <Impressions data={data.impressions}/>
                    </Grid>
                    <Grid
                        item
                        xl={3}
                        lg={3}
                        sm={6}
                        xs={12}
                    >
                        <ProjectView data={data.views}/>
                    </Grid>
                    <Grid
                        item
                        xl={3}
                        lg={3}
                        sm={6}
                        xs={12}
                    >
                        <Downloads data={data.downloads}/>
                    </Grid>
                    <Grid
                        item
                        xl={3}
                        lg={3}
                        sm={6}
                        xs={12}
                    >
                        <Favourites data={data.favourites} sx={{height: '100%'}}/>
                    </Grid>
                    <Grid
                        item
                        lg={8}
                        md={6}
                        xl={9}
                        xs={12}
                    >
                        <OverallTraffic data={data.trafficByMonths} months={months}/>
                    </Grid>
                    <Grid
                        item
                        lg={4}
                        md={6}
                        xl={3}
                        xs={12}
                    >
                        <DonutByProject data={data.projectsDonut} sx={{height: '100%'}}/>
                    </Grid>
                    {!data.engagementData.projects.length === 0 &&
                        <Grid item
                              xs={12}
                        >

                            <AnimationOnScroll animateIn="animate__fadeInRightBig">
                                <Heading title={"Reader Engagement"}
                                         subheader={"Gain valuable insight into your work's reader engagement. Discover which pages your readers are likely to disengage, or when they commit and hit download."}/>
                            </AnimationOnScroll>
                            <BubbleChartProjectsWrap data={data.engagementData}/>
                        </Grid>
                    }
                    {data.editRights &&
                        <Grid
                            item
                            lg={4}
                            md={6}
                            xl={3}
                            xs={12}
                        >
                            <YourProjects data={data.projects.project}
                                          sx={{height: '100%'}}/>
                        </Grid>
                    }
                    <Grid
                        item
                        lg={8}
                        md={12}
                        xl={9}
                        xs={12}
                    >
                        <TrafficTable data={data.trafficByItems.trafficByItem}/>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    )
}

export default MainDashBoard;
import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import directing from "../directorschair.png";
import Box from "@mui/material/Box";
import {HeroDisplayStretch} from "../components/layout.js";
import axios from "axios";
import properties from "../general/properties.js";
import Loading from "../general/Loading.js";
import {LandingButton} from "../components/buttons.js";
import QuotesModal from "./QuotesModal.js";
import QuotesDisplay from "./QuotesDisplay.js";
import {Stack} from "@mui/material";
import {FormatQuote} from "@mui/icons-material";


export default function AddQuote() {

    const {id} = useParams();

    const [addQuoteOpen, setAddQuoteOpen] = useState(false);

    let [quotesList, setQuotesList] = useState({
        "id": 0,
        "quote": "",
        "name": "",
        "organisation": "",
        "projectId": 0
    });

    const [quote, setQuote] = useState({
        "id": 0,
        "quote": "",
        "name": "",
        "organisation": "",
        "projectId": 0
    });

    const [loading, setLoading] = useState(true);

    let navigate = useNavigate();

    useEffect(() => {

        axios({
            method: 'get', url: properties.backendServer + '/sg/project/quotes/' + id,
        })
            .then((result) => {
                setQuotesList(result.data);
                setLoading(false);
            }).catch(err => {
            console.log(err)
        });


    }, [id, loading]);

    useEffect(() => {

    }, [quotesList, id]);


    function deleteQuote(quoteId) {

        const url = properties.backendServer + '/sg/project/quote/' + id + '/' + quoteId;

        axios.delete(url)
            .then(() => {
                const quotesList2 = quotesList.filter(obj => obj.id !== quoteId);
                setQuotesList(quotesList2);
            });
    }

    if (loading) {
        return <Loading/>
    } else {
        return (<>
            <HeroDisplayStretch sx={{backgroundImage: `url(${directing})`}}>
                <Box
                    sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <Stack spacing={1}>
                        <LandingButton startIcon={<FormatQuote/>}
                                       onClick={() => {
                                           setQuote({
                                               "id": 0,
                                               "quote": '',
                                               "name": '',
                                               "organisation": '',
                                               "projectId": Number(id)
                                           });
                                           setAddQuoteOpen(true)
                                       }}>Add Quote </LandingButton>
                        <LandingButton
                            onClick={() => navigate('/projectView/' + id)}>Return </LandingButton>
                    </Stack>

                </Box>
                <QuotesDisplay quotesList={quotesList}
                               deleteQuoteEntry={deleteQuote} quoteEntry={quote}
                               setQuoteEntry={setQuote} open={addQuoteOpen}
                               setOpen={setAddQuoteOpen}/>

            </HeroDisplayStretch>
            <QuotesModal
                open={addQuoteOpen} setOpen={setAddQuoteOpen}
                quotesList={quotesList}
                setQuotesList={setQuotesList}
                quoteEntry={quote}
                setQuoteEntry={setQuote}/>
        </>);
    }
}




import React from 'react';
import 'react-toastify/dist/ReactToastify.css';
import axios from "axios";
import {useNavigate} from "react-router-dom";
import properties from "../general/properties.js";
import DialogYesNo from "../admin/DialogYesNo.js";

const DeleteProject = ({open, setOpen, id}) => {

    const navigate = useNavigate();

    const del = () => {

        axios({
            method: 'delete',
            url: properties.backendServer + '/sg/project/' + id,
        }).then(() => {
            setOpen(false)
            navigate('/userView/' + localStorage.getItem("profileId"));
        }).catch(() => {
            setOpen(false)
            navigate('/userView/' + localStorage.getItem("profileId"));
        });

    }

    return (
        <DialogYesNo open={open} action={del} handleClose={() => setOpen(false)}
                     title={"Delete Project"}
                     description={"Are you sure you want to delete entire project?"}/>
    );
}

export default DeleteProject;

import IndustryLanding from "./industryLanding.js";

export default function IndustryFavouriteProjects() {

    return (<>
            <IndustryLanding url='/sg/industry/favourite/projects/'/>
        </>

    )


}
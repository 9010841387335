import {Card, CardContent, Grid, Stack, Typography} from "@mui/material";
import Box from "@mui/material/Box";
import CardActions from "@mui/material/CardActions";
import IconButton from "@mui/material/IconButton";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever.js";
import EditIcon from "@mui/icons-material/Edit.js";
import React from "react";

const QuotesDisplay = ({
                           quotesList,
                           deleteQuoteEntry,
                           setQuoteEntry,
                           setOpen
                       }) => {


    return (

        <Box sx={{pl: '5%', pr: '5%'}}>
            <Grid container mt={'2%'} pb={"5%"} justifyContent="space-around" alignItems="space-around"
                  spacing={5}>
                {quotesList.map((value, id) => (<Grid key={id} item xs={12} sm={9} md={6} lg={6} xl={4}>
                    <Card>
                        <CardContent>
                            <Typography sx={{fontSize: 14}} color="text.secondary" gutterBottom>
                                Quote
                            </Typography>
                            <Box sx={{display: 'flex', justifyContent: 'center'}}>
                                <Stack>
                                    <Typography variant="h5" component="div">
                                        {value.quote}
                                    </Typography>
                                    <Typography color="text.secondary">
                                        {value.name}
                                    </Typography>
                                    <Typography variant="body2">
                                        {value.organisation}
                                    </Typography>
                                </Stack>
                            </Box>
                        </CardContent>
                        <CardActions sx={{display: 'flex', justifyContent: 'right'}}>
                            <IconButton size="small"
                                        onClick={() => deleteQuoteEntry(value.id)}><DeleteForeverIcon/></IconButton>
                            <IconButton onClick={() => {
                                setQuoteEntry({
                                    id: value.id,
                                    quote: value.quote,
                                    name: value.name,
                                    organisation: value.organisation
                                });
                                setOpen(true)
                            }} size="small"><EditIcon/></IconButton>
                        </CardActions>
                    </Card>
                </Grid>))}
            </Grid>
        </Box>)

}

export default QuotesDisplay;
import {BrowserRouter, Route, Routes} from "react-router-dom";
import Login from "./account/Login.js";
import {createTheme, StyledEngineProvider, ThemeProvider} from '@mui/material/styles';
import CssBaseline from "@mui/material/CssBaseline";
import {Box, responsiveFontSizes, Stack, Typography} from "@mui/material";
import ProjectList from "./project/ProjectList.js";
import ProjectScreenplayUpload from "./project/ProjectScreenplayUpload.js";
import ProjectCreate from "./project/ProjectCreate.js";
import ProjectEdit from "./project/ProjectEdit.js";
import ProjectSettings from "./project/ProjectSettings.js";
import UserSettings from "./user/UserSettings.js";
import ProjectWrapper from "./project/ProjectWrapper.js";
import ProjectImageUpload from "./project/ProjectImageUpload.js";
import UserView from "./user/UserView.js";
import SignUp from "./account/SignUp.js";
import Logout from "./account/Logout.js";
import CookieConsent from "react-cookie-consent";

import {initAxiosInterceptors} from './general/api.js'
import ErrorPage from './general/ErrorPage.js'
import DashBoard from "./traffic/DashBoardWrapper.js";
import Header from "./layout/Header.js";
import CreateIndustry from "./admin/CreateIndustry.js";
import LandingPage from "./landing/LandingPage.js";
import UserEdit from "./user/UserEdit.js";
import UserImageUpload from "./user/UserImageUpload.js";
import UserBackgroundImageUpload from "./user/UserBackgroundImageUpload.js";
import ScreenplayWrapper from "./screenplay/ScreenplayWrapper.js";
import ScreenplayExtract from "./project/ScreenplayExtract.js";
import {UserProvider} from "./user/UserContext";
import LoadData from "./general/LoadData.js";
import ChangePassword from "./user/ChangePassword";
import ChangeEmail from "./user/ChangeEmail.js";
import ProjectAddUser from "./project/ProjectAddUser.js";
import AddCompetition from "./competition/AddCompetition";
import IndustryRecommendedWriters from "./industry/IndustryRecommendedWriters.js";
import IndustryLandingWrapper from "./industry/IndustryLandingWrapper";
import IndustryRecommendedProjects from "./industry/IndustryRecommendedProjects";
import IndustryFavouriteProjects from "./industry/IndustryFavouriteProjects.js";
import IndustryFavouriteWriters from "./industry/IndustryFavouriteWriters.js";
import IndustryFinalistsProjects from "./industry/IndustryFinalistsProjects";
import ClearIndustryCaches from "./admin/ClearIndustryCaches";
import UserList from "./user/UserList.js";
import Loading from "./general/Loading.js";
import {UserProjects} from "./project/UserProjects";
import Help from './general/Help.js'
import AddQuote from "./quotes/AddQuote.js";
import React from "react";
import logo from "./svlogo2Primary.png";
import ValidateEmail from "./account/ValidateEmail.js";
import ResetPassword from "./account/ResetPassword.js";
import ResendActivationEmail from "./account/ResendActivationEmail.js";

let theme = createTheme({
    palette: {
        mode: 'light', primary: {
            main: '#4682B4', // main: '#F0F8FF',
        }, secondary: {
            main: '#F0F8FF',
        }, background: {
            default: "#F0F8FF"
        }
    }, components: {
        MuiButton: {
            styleOverrides: {
                contained: {
                    backgroundColor: "#F0F8FF", color: 'black', "&:hover": {
                        color: "white"
                    }
                }
            }
        }, MuiMenu: {
            styleOverrides: {
                list: {
                    backgroundColor: "#F0F8FF", color: 'black'
                }
            }
        }, MuiDrawer: {
            styleOverrides: {
                paper: {
                    backgroundColor: "#F0F8FF",
                }
            }
        }
    }, display: 'flex', text: {}
});

theme = responsiveFontSizes(theme);

initAxiosInterceptors();

function App() {


    return (

        <UserProvider>
            <div className="App">
                <StyledEngineProvider injectFirst>
                    <ThemeProvider theme={theme}>
                        <CssBaseline/>

                        <BrowserRouter>
                            <LoadData/>
                            <Header/>
                            <Routes>
                                <Route exact path="/" element={<LandingPage/>}>
                                    <Route path="about" element={<LandingPage/>}/>
                                </Route>

                                <Route path="/sg/projects/:page" element={<ProjectList/>}/>
                                <Route path="/projectScreenplayUpload/:id" element={<ProjectScreenplayUpload/>}/>
                                <Route path="/screenplayExtract/:id" element={<ScreenplayExtract/>}/>
                                <Route path="/logout" element={<Logout/>}/>
                                <Route path="/login/:message" element={<Login/>}/>
                                <Route path="/login" element={<Login/>}/>
                                <Route path="/about" element={<LandingPage/>}/>
                                <Route path="/projectCreate" element={<ProjectCreate/>}/>
                                <Route path="/project/screenplay/:id" element={<ScreenplayWrapper/>}/>
                                <Route path="/projectEdit/:id" element={<ProjectEdit/>}/>
                                <Route path="/projectAddUser/:id" element={<ProjectAddUser/>}/>
                                <Route path="/userEdit" element={<UserEdit/>}/>
                                <Route path="/projectSettings/:id" element={<ProjectSettings/>}/>
                                <Route path="/userSettings/:id" element={<UserSettings/>}/>
                                <Route path="/changePassword/:id" element={<ChangePassword/>}/>
                                <Route path="/changeEmail/:id" element={<ChangeEmail/>}/>
                                <Route path="/competitionAdd/:id" element={<AddCompetition/>}/>
                                <Route path="/quotesAdd/:id" element={<AddQuote/>}/>
                                <Route path="/projectView/:id" element={<ProjectWrapper/>}/>
                                <Route path="/projectImageUpload/:id" element={<ProjectImageUpload/>}/>
                                <Route path="/userImageUpload/:id" element={<UserImageUpload/>}/>
                                <Route path="/industryLanding" element={<IndustryLandingWrapper/>}/>
                                <Route path="/industryRecommendedWriters" element={<IndustryRecommendedWriters/>}/>
                                <Route path="/industryRecommendedProjects" element={<IndustryRecommendedProjects/>}/>
                                <Route path="/industryFavouriteWriters" element={<IndustryFavouriteWriters/>}/>
                                <Route path="/industryFavouriteProjects" element={<IndustryFavouriteProjects/>}/>
                                <Route path="/industryFinalistsProjects" element={<IndustryFinalistsProjects/>}/>
                                <Route path="/userBackgroundImageUpload/:id" element={<UserBackgroundImageUpload/>}/>
                                <Route path="/userView/:id" element={<UserView/>}/>
                                <Route path="/sg/users/:page" element={<UserList/>}/>
                                <Route path="/errorPage" element={<ErrorPage/>}/>
                                <Route path="/loading" element={<Loading/>}/>
                                <Route path="/user" element={<SignUp/>}/>
                                <Route path="/sg/dashboard" element={<DashBoard/>}/>
                                <Route path="/admin/createIndustry" element={<CreateIndustry/>}/>
                                <Route path="/admin/clearCache" element={<ClearIndustryCaches/>}/>
                                <Route path="/user/projects" element={<UserProjects/>}/>
                                <Route path="/help" element={<Help open={true}/>}/>
                                <Route path="/validateEmail/:uuid" element={<ValidateEmail/>}/>
                                <Route path="/resendActivationEmail" element={<ResendActivationEmail/>}/>
                                <Route path="/resetPassword" element={<ResetPassword/>}/>


                            </Routes>
                            <Stack
                                justifyContent="center"
                                alignItems="center"
                            >
                                <Box sx={{width: '150px', paddingTop: '50px'}} color="primary" component={"img"}
                                     src={logo}/>
                                <Box sx={{bgcolor: '#F0F8FF', p: 6}} component="footer">
                                    <Typography variant="h5" align="center" color="primary" gutterBottom>
                                        ScriptVender
                                    </Typography>
                                    <Typography
                                        variant="h6"
                                        align="center"
                                        color="primary"
                                        component="p"
                                    >
                                        take your screenplay to market
                                    </Typography>
                                </Box>
                            </Stack>
                            <CookieConsent
                                location="bottom"
                                buttonText="Okay"
                                cookieName="cookiesAccept"
                                style={{background: "#2B373B"}}
                                buttonStyle={{backgroundColor: "#F0F8FF", fontSize: "13px"}}
                                expires={150}
                                acceptOnScroll={true}
                            >
                                This website uses cookies to enhance your experience.
                            </CookieConsent>
                        </BrowserRouter>
                    </ThemeProvider>
                </StyledEngineProvider>
            </div>
        </UserProvider>

    );
}

export default App;

import {Doughnut} from 'react-chartjs-2';
import {Box, Card, CardContent, CardHeader, Divider, Typography, useTheme} from '@mui/material';

export const DonutByProject = (props) => {
    const theme = useTheme();

    const donutData = props.data;

    const data = {
        datasets: [
            {
                data: donutData.totals,
                backgroundColor: ["#00FF00", "#0000FF", "#FFFF00", "#FF00FF", "#00FFFF", "#FF6600", "#3399FF", "#9933CC", "#66CC66", "#FFCC99", "#336699", "#000000", "#CC3399", "#99CC33", "#CCCC33", "#669999", "#CC6666", "#9999CC", "#FF0000"],
                borderWidth: 8,
                borderColor: '#FFFFFF',
                hoverBorderColor: '#FFFFFF'
            }
        ],
        labels: donutData.titles
    };

    const options = {
        animation: true,
        cutoutPercentage: 80,
        layout: {padding: 0},
        legend: {
            display: false
        },
        maintainAspectRatio: false,
        responsive: true,
        tooltips: {
            backgroundColor: theme.palette.background.paper,
            bodyFontColor: theme.palette.text.secondary,
            borderColor: theme.palette.divider,
            borderWidth: 1,
            enabled: true,
            footerFontColor: theme.palette.text.secondary,
            intersect: false,
            mode: 'index',
            titleFontColor: theme.palette.text.primary
        }
    };

    return (
        <Card {...props}>
            <CardHeader title="Traffic by project"/>
            <Divider/>
            <CardContent>
                <Box
                    sx={{
                        height: 300,
                        position: 'relative'
                    }}
                >
                    <Doughnut
                        data={data}
                        options={options}
                    />
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        pt: 2
                    }}
                >
                    {donutData.projectDonut.map(({
                                                     color,
                                                     title,
                                                     total,
                                                     percentage
                                                 }) => (
                        <Box
                            key={title}
                            sx={{
                                p: 1,
                                textAlign: 'center'
                            }}
                        >
                            <Typography
                                color="textPrimary"
                                variant="body1"
                            >
                                {title}
                            </Typography>
                            <Typography
                                style={{color}}
                                variant="h4"
                            >
                                {percentage}
                                %
                            </Typography>
                        </Box>
                    ))}
                </Box>
            </CardContent>
        </Card>
    );
};

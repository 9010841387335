import {Grid} from '@mui/material';
import ScreenplayBubbleChart from "./bubbleCharts.js";
import React from "react";


export const BubbleChartProjectsWrap = (props) => {

    const projects = props.data.projects;


    return (<Grid container>
            {projects.map((project, i) => (
                <Grid item key={i}
                      xs={12}
                      lg={6}
                >
                    <ScreenplayBubbleChart data={project}/>
                </Grid>
            ))}
        </Grid>
    )
};

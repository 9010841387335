import React, {useContext, useEffect, useState} from 'react';
import {useNavigate} from "react-router-dom";
import {getUser, getUserStructure, putUser} from "../general/api.js";
import UserUpdate from "./UserUpdate.js";
import UserContext from "./UserContext.js";
import Loading from "../general/Loading.js";


export default function UserEdit() {

    const id = localStorage.getItem("profileId");

    const navigate = useNavigate();

    let [loadedUser, setLoadedUser] = useState({created: false, id: 0});

    let [user, setUser] = useState(getUserStructure);

    const {addUser} = useContext(UserContext);

    useEffect(() => {

        getUser(id)
            .then(response => {
                setUser(response.data);
            }).then(response => {
            setLoadedUser({created: true, id: id});
        });

        return () => {
            setUser({});
            setLoadedUser({created: false, id: id});
        };

    }, [id]);


    let save = (e) => {
        putUser(id, user)
            .then(() => {
                addUser();
                navigate('/userView/' + localStorage.getItem("profileId"))
            });

        e.preventDefault();
    }

    if (!loadedUser.created) {
        return <Loading/>
    } else {
        return (
            <UserUpdate user={user} setUser={setUser} saveData={save}/>
        );
    }
}

import Button from "@mui/material/Button";
import {Stack} from "@mui/material";
import React from "react";

const SmallActionsButtons = ({
                                 firstText,
                                 firstFunction,
                                 secondText,
                                 secondFunction,
                                 thirdText,
                                 thirdFunction,
                                 direction
                             }) => {


    return (

        <Stack direction={direction} gap={4} mt={4} mb={4}>
            <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                onClick={firstFunction}
            >
                {firstText}
            </Button>
            {secondText != null && <Button
                fullWidth
                variant="contained"
                color="primary"
                onClick={secondFunction}
            >
                {secondText}
            </Button>}
            {thirdText != null && <Button
                fullWidth
                variant="contained"
                color="primary"
                onClick={thirdFunction}
            >
                {thirdText}
            </Button>}
        </Stack>

    )
}

export default SmallActionsButtons;
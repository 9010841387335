import {List, ListItemButton, ListItemIcon, ListItemText} from "@mui/material";
import {CreateNewFolder, FolderOpen, Person} from "@mui/icons-material";
import {Link} from "react-router-dom";
import ShowChartIcon from "@mui/icons-material/ShowChart";
import {useContext} from "react";
import UserContext from "../user/UserContext.js";

const UserMenu = (props) => {

    const {userLight} = useContext(UserContext);

    const profileView = '/userView/' + userLight.profileId;

    return (
        <List onClick={() => props.handleDrawerClose()} dense={true}>
            <ListItemButton onClick={() => props.handleDrawerClose()} key={'projectAdd'} component={Link}
                            to="/projectCreate">
                <ListItemIcon>{<CreateNewFolder/>}</ListItemIcon>
                <ListItemText primary={'Create Project'}/>
            </ListItemButton>
            <ListItemButton onClick={() => props.handleDrawerClose()} key={'profile'} component={Link}
                            to={profileView}>
                <ListItemIcon>{<Person/>}</ListItemIcon>
                <ListItemText primary={'My Profile'}/>
            </ListItemButton>
            <ListItemButton key={'userprojects'} component={Link} to="/user/projects">
                <ListItemIcon>{<FolderOpen/>}</ListItemIcon>
                <ListItemText primary={'My Projects'}/>
            </ListItemButton>
            <ListItemButton key={'dashboard'} component={Link} to="/sg/dashboard">
                <ListItemIcon>{<ShowChartIcon/>}</ListItemIcon>
                <ListItemText primary={'My Dashboard'}/>
            </ListItemButton>
        </List>
    )

}

export default UserMenu;
import {Card, CardContent, Grid, Stack, Typography} from "@mui/material";
import Box from "@mui/material/Box";
import CardActions from "@mui/material/CardActions";
import IconButton from "@mui/material/IconButton";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever.js";
import EditIcon from "@mui/icons-material/Edit.js";
import React from "react";

const CompetitionDisplay = ({
                                competitionEntryList,
                                deleteCompEntry,
                                setCompetitionEntry,
                                setOpen,
                                competitions,
                            }) => {

    const getCompetitionId = (competitionName) => {

        return competitions.filter((value) => {
            return value.name === competitionName
        })
            .map((val) => val.id)[0]

    }

    const getRoundId = (competitionName, roundName) => {

        return competitions.filter(o => o.name === competitionName)
            .map(o => o.rounds).flatMap(o => o).filter(o => o.name === roundName).map(o => o.id)[0];


    }


    return (

        <Box sx={{pl: '5%', pr: '5%'}}>
            <Grid container mt={'2%'} pb={"5%"} justifyContent="space-around" alignItems="space-around"
                  spacing={5}>
                {competitionEntryList.map((value, id) => (<Grid key={id} item xs={12} sm={9} md={6} lg={6} xl={4}>
                    <Card>
                        <CardContent>
                            <Typography sx={{fontSize: 14}} color="text.secondary" gutterBottom>
                                Competition placing
                            </Typography>
                            <Box sx={{display: 'flex', justifyContent: 'center'}}>
                                <Stack>
                                    <Typography variant="h5" component="div">
                                        {value.competition}
                                    </Typography>
                                    <Typography color="text.secondary">
                                        {value.round}
                                    </Typography>
                                    <Typography variant="body2">
                                        {value.year}
                                    </Typography>
                                </Stack>
                            </Box>
                        </CardContent>
                        <CardActions sx={{display: 'flex', justifyContent: 'right'}}>
                            <IconButton size="small"
                                        onClick={() => deleteCompEntry(value.id)}><DeleteForeverIcon/></IconButton>
                            <IconButton onClick={() => {
                                setCompetitionEntry({
                                    id: value.id,
                                    competitionId: getCompetitionId(value.competition),
                                    roundId: getRoundId(value.competition, value.round),
                                    year: value.year,
                                    visible: true
                                });
                                setOpen(true)
                            }} size="small"><EditIcon/></IconButton>
                        </CardActions>
                    </Card>
                </Grid>))}
            </Grid>
        </Box>)

}

export default CompetitionDisplay;
import DialogYesNo from "./DialogYesNo.js";
import React from "react";
import {HeroDisplayWithImage} from "../components/layout";
import {useNavigate} from "react-router-dom";
import axios from "axios";
import properties from "../general/properties.js";

const ClearIndustryCaches = () => {

    const navigate = useNavigate();

    function save() {

        return axios({
            method: 'put', url: properties.backendServer + '/admin/clearcache'
        })
            .then(() => {
                navigate('/industryLanding');
            }).catch(err => {
                console.log(err)
            });


    }

    function onClose() {

        navigate('/industryLanding');

    }


    return (<HeroDisplayWithImage backgroundimg={null} backgroundRep={'repeat-y'}>
        <DialogYesNo open={true} action={save} handleClose={onClose}
                     title={"Clear Cache"}
                     description={"Are you sure you want to clear the server side cache for industry projects and writers? This cache refresh will happen every hour from server start-up otherwise."}/>
    </HeroDisplayWithImage>)


}

export default ClearIndustryCaches
import React, {useContext, useEffect, useState} from 'react';
import axios from "axios";
import {Navigate, useParams} from "react-router-dom";
import ImageUpload from "../components/ImageUpload";
import {getUser, getUserStructure, handleImageUpload} from "../general/api.js";
import properties from "../general/properties.js";
import UserContext from "./UserContext.js";


export default function UserImageUpload() {

    const {id} = useParams();

    const [files, setFiles] = useState([]);

    const [user, setUser] = useState(getUserStructure());

    let [redirectUser, setRedirectUser] = useState({created: false, id: 0});

    const {addUser} = useContext(UserContext);


    useEffect(() => {

        getUser(id)
            .then(response => {
                setUser(response.data);
            });

    }, [id]);

    let handleUpload = (files) => {
        setFiles(files);
    }

    let save = (e) => {

        handleImageUpload(files[0], true).then((file) => {

                let formData = new FormData();
                formData.append("file", file, user.name + Date.now() + '.png');

                const url = properties.backendServer + '/sg/user/' + id + '/image';

                axios.post(url, formData, {
                    transformRequest: () => formData,
                }).then(
                    result => {
                        localStorage.setItem("profileImg", result.data.imageFilePath);
                        addUser();
                        setRedirectUser({created: true, id: id})
                    }
                ).catch(error => {
                    console.log(error);
                });
            }
        );

        e.preventDefault();
    }

    let cancel = (e) => {

        setRedirectUser({created: true, id: id})

    };


    if (redirectUser.created) {
        return <Navigate to={`/userView/${id}`}/>
    } else {
        return (
            <ImageUpload save={save} cancel={cancel} imageFilePath={user.imageFilePath} handleUpload={handleUpload}
                         files={files}/>
        );
    }
}

import React, {useEffect, useMemo, useState} from 'react';
import Avatar from '@mui/material/Avatar';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import MovieCreationIcon from '@mui/icons-material/MovieCreation';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import {FormControl, InputLabel, Select, Stack} from "@mui/material";
import directing from "../directorschair.png";
import {useNavigate} from "react-router-dom";
import SaveCancel from "../general/SaveCancel.js";
import {HeroDisplayStretch} from "../components/layout.js";
import Box from "@mui/material/Box";
import countryList from "react-select-country-list";
import MenuItem from "@mui/material/MenuItem";


export default function UserUpdate({user, setUser, saveData}) {

    const navigate = useNavigate();

    const [nameError, setNameError] = useState(false);
    const [countryError, setCountryError] = useState(false);

    const id = localStorage.getItem("profileId");

    const options = useMemo(() => countryList().getData(), [])

    const [loading, setLoading] = useState(false)

    useEffect(() => {

        return () => {
            setUser({});
        };

    }, [setUser]);


    let handleChange = (event) => {

        setUser({...user, [event.target.name]: event.target.value})
        setNameError(false);

    }

    let save = (e) => {

        if (!user.name) {
            setNameError(true);
            setLoading(false);
            return;
        }

        if (!user.country) {
            setCountryError(true);
            setLoading(false);
            return;
        }


        saveData(e);

        e.preventDefault();
    }

    let cancel = (e) => {

        navigate('/userView/' + id);

        e.preventDefault();
    }


    return (
        <HeroDisplayStretch sx={{backgroundImage: `url(${directing})`}}>
            <Container component="main" maxWidth="md">
                <Box sx={{
                    flexDirection: 'column',
                    alignItems: 'center',
                    backgroundColor: '#F0F8FF',
                    padding: "20px",
                    width: '100%'
                }}>
                    <Box sx={{flexDirection: 'column', display: "flex", alignItems: "center"}}>
                        <Avatar m={1} sx={{backgroundColor: 'palette.action'}}>
                            <MovieCreationIcon/>
                        </Avatar>
                        <Typography component="h1" variant="h5" color="black">
                            {user.name}
                        </Typography>
                    </Box>
                    <FormControl sx={{width: '100%', marginTop: 3}} noValidate onSubmit={save}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={6}>
                                <Stack gap={2}>
                                    <InputLabel id="useredit.name"></InputLabel>
                                    <TextField
                                        name="name"
                                        variant="outlined"
                                        required
                                        fullWidth
                                        id="useredit.name"
                                        label="name"
                                        onChange={handleChange}
                                        value={user.name}
                                        error={nameError}
                                        helperText={"not empty"}
                                    />
                                    <InputLabel id="useredit.logline"></InputLabel>
                                    <TextField
                                        name="logline"
                                        variant="outlined"
                                        required
                                        fullWidth
                                        multiline
                                        rows="3"
                                        id="useredit.logline"
                                        label="your logline"
                                        onChange={handleChange}
                                        value={user.logline}
                                    />
                                    <InputLabel id="useredit.country"></InputLabel>
                                    <Select
                                        name="country"
                                        id="useredit.country"
                                        value={user.country}
                                        variant="outlined"
                                        fullWidth
                                        label="country"
                                        onChange={handleChange}
                                        error={countryError}
                                        helperText={"not empty"}
                                        isClearable
                                    >
                                        {
                                            options.map((value) => (
                                                <MenuItem value={value.label}>{value.label}</MenuItem>
                                            ))

                                        }

                                    </Select>
                                </Stack>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Grid item xs={12} sm={12}>
                                    <TextField
                                        variant="outlined"
                                        multiline
                                        rows="10"
                                        fullWidth
                                        id="useredit.notes"
                                        label="Notes"
                                        name="notes"
                                        onChange={handleChange}
                                        value={user.notes}
                                    />
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <SaveCancel save={save} cancel={cancel} loading={loading} setLoading={setLoading}/>
                            </Grid>
                        </Grid>
                    </FormControl>
                </Box>
            </Container>
        </HeroDisplayStretch>
    )

}

import Avatar from "@mui/material/Avatar";
import React from "react";
import {AvatarGroup} from "@mui/material";

export default function ProjectAvatarGroup({authors}) {

    if (!authors) {
        return <></>
    }
    return (
        <AvatarGroup max={2}>
            {authors.map((value, id) => (

                <Avatar key={id} alt={value.name} src={value.imageFilePath}/>

            ))}
        </AvatarGroup>
    )

}
import {Stack} from "@mui/material";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";

export default function FormHeader({title, headerIcon}) {

    return (

        <>
            <Container sx={{
                backgroundColor: '#F0F8FF',
                padding: "20px",
            }}>
                <Stack gap={1}>
                    <Box sx={{display: 'flex', justifyContent: 'center'}}>
                        <Avatar sx={{display: 'flex', justifyContent: 'center', backgroundColor: 'palette.action'}}>
                            {headerIcon}
                        </Avatar>
                    </Box>
                    <Typography align="center" component="h1" variant="h5">
                        {title}
                    </Typography>
                </Stack>
            </Container>
        </>

    )

}
import IndustryLanding from "./industryLanding.js";

export default function IndustryRecommendedProjects() {

    return (<>
            <IndustryLanding url='/sg/industry/recommended/projects/'/>
        </>

    )


}
import noImage from '../no-image-available.png'
import {
    Box,
    Button,
    Card,
    CardHeader,
    Divider,
    IconButton,
    List,
    ListItemAvatar,
    ListItemButton,
    ListItemText
} from '@mui/material';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {useNavigate} from "react-router-dom";


export const YourProjects = (props) => {

    let navigate = useNavigate();

    const projects = props.data;

    const handleClick = (id) => {

        navigate('/projectView/' + id)
    }

    return (<Card {...props}>
            <CardHeader
                title="Your Projects"
            />
            <Divider/>
            <List>
                {projects.map((project, i) => (
                    <ListItemButton
                        divider={i < projects.length - 1}
                        key={project.id}
                        onClick={() => handleClick(project.id)}
                    >
                        <ListItemAvatar>
                            <img
                                alt={project.title}
                                src={project.imageFilePath === null ? noImage : project.imageFilePath}
                                style={{
                                    height: 48,
                                    width: 64,
                                    marginRight: 20,
                                }}
                            />
                        </ListItemAvatar>
                        <ListItemText
                            primary={project.title}
                        />
                        <IconButton
                            edge="end"
                            size="small"
                        >
                            <MoreVertIcon/>
                        </IconButton>
                    </ListItemButton>
                ))}
            </List>
            <Divider/>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    p: 2
                }}
            >
                <Button
                    color="primary"
                    endIcon={<ArrowRightIcon/>}
                    size="small"
                    variant="text"
                    onClick={() => navigate('/user/projects')}
                >
                    View all
                </Button>
            </Box>
        </Card>
    )
};

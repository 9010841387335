import {Avatar, Box, Card, CardContent, Grid, LinearProgress, Typography} from '@mui/material';
import FavoriteIcon from "@mui/icons-material/Favorite.js";
import React from "react";

export const Favourites = (props) => {

    const projectFavourites = props.data;

    return (<Card
            sx={{height: '100%'}}
            {...props}
        >
            <CardContent>
                <Grid
                    container
                    spacing={3}
                    sx={{justifyContent: 'space-between'}}
                >
                    <Grid item>
                        <Typography
                            color="textSecondary"
                            gutterBottom
                            variant="overline"
                        >
                            Favourites
                        </Typography>
                        <Typography
                            color="textPrimary"
                            variant="h4"
                        >
                            {projectFavourites.total}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Avatar
                            sx={{
                                backgroundColor: 'white',
                                height: 56,
                                width: 56
                            }}
                        >
                            <FavoriteIcon fontSize="large" sx={{color: "red"}}/>
                        </Avatar>
                    </Grid>
                </Grid>
                <Box sx={{pt: 3}}>
                    <LinearProgress
                        value={75.5}
                        variant="determinate"
                    />
                </Box>
            </CardContent>
        </Card>
    );
}
import {Box, Stack, Typography} from "@mui/material";
import React from "react";

const CompetitionPlacings = ({data}) => {


    return (<Box>
        <Stack mt={2} spacing={2} sx={{width: {xs: '80%', sm: '50%', md: '30%', lg: '100%'}}}>

            {data.competitionResponse.map((o, i) => {
                return <div key={i}>
                    <Stack key={i} direction={'column'}>
                        <Typography component="h3" variant="h6"
                                    fontWeight={700}>{o.competition}</Typography>
                        <Typography>{o.round}</Typography>
                        <Typography>{o.year}</Typography>
                    </Stack>
                </div>
            })}
        </Stack></Box>)

}

export default CompetitionPlacings;
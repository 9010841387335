import React from 'react';
import {Grow} from "@mui/material";
import Typography from "@mui/material/Typography";


function FormTitle(props) {

    return (
        <Grow in={true} timeout={4000} direction={"left"}>
            <Typography sx={{
                color: "black",
                fontSize: "large",
                textAlign: "center"
            }}>
                {props.title}
            </Typography>
        </Grow>
    );
}

export default FormTitle;
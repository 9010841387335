import axios from "axios";
import properties from "./properties.js";
import * as React from "react";
import Slide from "@mui/material/Slide";
import imageCompression from "browser-image-compression";

export const getGenres = () => {

    return axios({
        method: 'get', url: properties.backendServer + '/op/genres',
    })

}

export const getProject = id => {

    return axios({
        method: 'get', url: properties.backendServer + '/sg/project/' + id,
    })

}

export const isAuthenticated = () => {

    let authenticated = localStorage.getItem('authenticated');

    if (authenticated === null) return false;

    return authenticated;


}

export const isIndustry = () => {

    let industry = localStorage.getItem('industry');

    if (industry === null) return false;

    return industry;

}

export const initAxiosInterceptors = () => {

    axios.interceptors.request.use(
        config => {
            const token = localStorage.getItem('token');
            if (token) {
                config.headers['Authorization'] = 'Bearer ' + token;
                config.withCredentials = true;
            }

            config.headers['Content-Type'] = 'application/json';
            return config;
        },
        error => {
            Promise.reject(error)
        });

// Response interceptor for API calls
    axios.interceptors.response.use((response) => {
        return response
    }, async function (error) {
        const originalRequest = error.config;
        if (error.response) {
            if (error.response.status === 401 && !originalRequest._retry) {
                originalRequest._retry = true;
                const access_token = error.response.data.token;
                localStorage.setItem('token', access_token);
                return axios(originalRequest);
            } else if (error.response.status === 401 && originalRequest._retry) {
                localStorage.clear();
                window.location = properties.domain + '/login';
                return Promise.reject(error);
            } else if (error.response.status === 400) {
                return Promise.reject(error);
            } else {
                window.location = properties.domain + '/ErrorPage';
                return Promise.reject(error);
            }
        } else {
            window.location = properties.domain + '/ErrorPage';
        }

    });

}

export const getUser = id => {

    return axios({
        method: 'get', url: properties.backendServer + '/sg/user/' + id,
    })

}

export const putProject = (id, project) => {

    return axios({
        method: 'put', url: properties.backendServer + '/sg/project/' + id, data: project,
    })

}

export const putUser = (id, user) => {

    return axios({
        method: 'put', url: properties.backendServer + '/sg/user/' + id, data: user,
    })

}

export const postProject = (project) => {

    return axios({
        method: 'post', url: properties.backendServer + '/sg/project', data: project,
    })

}

export const loadProjectAndSet = (id, setProject, setLoadedProject) => {

    getProject(id)
        .then(response => {
            let project = response.data;

            setProject(project);

        }).then(() => {
        setLoadedProject({created: true, id: id});
    });

}

export const loadGenresAndSet = (setGenres) => {

    getGenres()
        .then(response => {
            setGenres(response.data);

        });

}

export async function handleImageUpload(file, small) {

    const imageFile = file;

    const options = {
        maxSizeMB: small ? 1 : 2,
        maxWidthOrHeight: small ? 960 : 1920
    }
    try {
        const compressedFile = await imageCompression(imageFile, options);
        return compressedFile;
    } catch (error) {
        console.log(error);
    }

}

export const getProjectStructure = () => {
    return {
        "totalPages": 0,
        "startPage": 0,
        "id": 0,
        "title": "",
        "titleEllipsis": "",
        "logline": "",
        "loglineEllipsis": "",
        "genres": [
            {
                "id": 0,
                "name": "",
                "orderBy": 0
            }
        ],
        "genreList": "",
        "imageFilePath": "",
        "pitch": "",
        "pitchEllipsis": "",
        "pdfFilePath": "",
        "screenplayPdfUploaded": false,
        "editRights": false,
        "industryView": false,
        "openView": false,
        "extract": [{
            "showExtract": false, "totalPages": 86, "startPage": 89, extractUploaded: false, extractText: ""
        }],
        "showPdfDownload": false,
        "favourite": false,
        "favouriteCount": 0,
        "authors": [
            {
                "id": 0,
                "name": "",
                "username": "",
                "imageFilePath": "",
                "country": "",
                "email": "",
                "communityView": false,
                "industryView": false,
                "enabled": false,
                "logline": "",
                "notes": "",
                "backgroundProfileImage": "",
                "pinnedProjectId": 0
            }
        ],
        "competitionResponse": [
            {
                "id": 0,
                "competition": "",
                "round": "",
                "year": 0,
                "notes": ""
            }
        ]
    }
}

export const getProjectRequestStructure = () => {

    return {
        "id": 0,
        "title": "",
        "logline": "",
        "pitch": "",
        "genres": [],
        "industryView": false,
        "extract": {
            "showExtract": false,
            "extractUploaded": false,
            "extractFilePath": "",
            "extractText": "",
            "totalPages": 0,
            "startPage": 0
        },
        "showPdfDownload": false,
        "pdfUploaded": false,
        "openView": false,
        "color": "white"
    }

}
export const getUserStructure = () => {
    return {
        "id": 0,
        "name": "",
        "username": "",
        "imageFilePath": "",
        "country": "",
        "email": "",
        "communityView": false,
        "industryView": false,
        "enabled": false,
        "logline": "",
        "notes": "",
        "backgroundProfileImage": "",
        "pinnedProjectId": 0,
        "createdProject": false
    }

}

export const getUserLightStructure = () => {

    return {
        id: 0,
        name: '',
        imageFilePath: '',
        backgroundProfileImage: ''
    }

}


export const transitionUp = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
import React, {useState} from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import directing from "../directorschair.png";
import {Stack} from "@mui/material";
import fadein from "../fadein4.jpg";
import axios from "axios";
import {Navigate, useNavigate} from "react-router-dom";
import {HeroDisplay} from "../components/layout.js";
import SmallCenteredFormHeader from "../components/SmallCenteredFormHeader.js";
import SmallActionButtons from "../components/SmallActionButtons.js";
import EmailAndName from "../account/EmailAndName.js";
import PasswordChange from "../account/PasswordChange.js";
import properties from "../general/properties.js";

let CreateIndustry = () => {
    let [user, setUser] = useState({firstName: '', lastName: '', email: '', password: ''});

    let [isCreated, setCreated] = useState(false);

    const handleChange = (event) => {

        setUser({...user, [event.target.name]: event.target.value})
    }

    let navigate = useNavigate();

    const goToLogin = () => {

        navigate('/login');
    }

    let save = (event) => {

        event.preventDefault();

        axios.create().post(properties.backendServer + '/admin/user/industry', user)
            .then(result => {
                setCreated(true);
                return result.data
            })
            .then(({message}) => {
                if (!isCreated) {
                    console.log("message" + message);
                }
            })
            .catch(err => {
                console.error(err);
            })

        event.preventDefault();
    }

    if (isCreated) {
        return <Navigate to="/login"/>
    }

    return (
        <HeroDisplay sx={{backgroundImage: `url(${directing})`}}>
            <Container maxWidth="xs" sx={{borderRadius: "5px"}}>
                <Box sx={{
                    flexDirection: 'column',
                    alignItems: 'center',
                    backgroundColor: '#F0F8FF',
                    padding: "20px",
                    width: '100%'
                }}>
                    <SmallCenteredFormHeader img={fadein} title={"Create an account"}/>
                    <Stack gap={2}>
                        <EmailAndName user={user} handleChange={handleChange}/>
                        <PasswordChange user={user} onChange={handleChange}/>
                    </Stack>
                    <SmallActionButtons firstText={"Create Account"} firstFunction={save} secondText={"Cancel"}
                                        secondFunction={goToLogin} direction={"column"}/>

                </Box>
            </Container>
        </HeroDisplay>);

}

export default CreateIndustry;

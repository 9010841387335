import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft.js";
import ChevronRightIcon from "@mui/icons-material/ChevronRight.js";
import Divider from "@mui/material/Divider";
import IndustryMenu from "../industry/IndustryMenu.js";
import Drawer from "@mui/material/Drawer";
import React from "react";
import {styled, useTheme} from "@mui/material/styles";
import AdminMenu from "../admin/AdminMenu.js";
import UserMenu from "./UserMenu.js";
import UnauthenticatedMenu from "./UnauthenticatedMenu.js";
import AuthenticatedMenu from "./AuthenticatedMenu.js";

const SideDrawer = ({
                        authenticated,
                        industry,
                        admin,
                        open,
                        setOpen,
                        handleDrawerClose,
                        classes,
                        setLogoutOpen,
                        logoutOpen
                    }) => {

    const theme = useTheme();

    const DrawerHeader = styled('div')(({theme}) => ({
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0, 1),
        ...theme.mixins.toolbar,
        justifyContent: 'flex-end',
    }));

    return (<>
        <Drawer
            sx={{backgroundColor: 'blue'}}
            className={classes.drawer}
            variant="persistent"
            anchor="left"
            open={open}
            onClick={(ev, reason) => setOpen(false)}
            classes={{
                paper: classes.drawerPaper,
            }}
        >
            <DrawerHeader>
                <IconButton onClick={handleDrawerClose} size="large">
                    {theme.direction === 'ltr' ? <ChevronLeftIcon/> : <ChevronRightIcon/>}
                </IconButton>
            </DrawerHeader>
            <Divider/>
            {industry && < IndustryMenu handleDrawerClose={handleDrawerClose}/>}
            {admin === 'true' && < AdminMenu handleDrawerClose={handleDrawerClose}/>}
            {authenticated && !industry && <UserMenu handleDrawerClose={handleDrawerClose}/>}
            {!authenticated ? <UnauthenticatedMenu handleDrawerClose={handleDrawerClose}/> :
                <AuthenticatedMenu handleDrawerClose={handleDrawerClose} setLogoutOpen={setLogoutOpen}
                                   logoutOpen={logoutOpen}/>}
        </Drawer>
    </>)

}

export default SideDrawer
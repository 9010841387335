import React, {useEffect, useState} from 'react';
import Grid from '@mui/material/Grid';
import {Card, Grow} from "@mui/material";
import ProjectCard from "../project/ProjectCard.js";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import axios from "axios";
import {HeroDisplayWithImage} from "../components/layout";
import properties from "../general/properties.js";
import UserCard from "../user/UserCard.js";
import Loading from "../general/Loading.js";


export default function IndustryLanding({url}) {


    const [data, setData] = useState({
        "industryLandingItemDTO": [
            {
                "title": "",
                "itemType": "",
                "itemList": [
                    {}
                ]
            }
        ]
    });

    const [loading, setLoading] = useState(true);


    useEffect(() => {

        axios({
            method: 'get', url: properties.backendServer + url + localStorage.getItem("profileId")
        }).then(response => {
            setData(response.data);
            setLoading(false);

        });

    }, [data.total, url]);


    if (loading) {
        return (<Loading/>)
    } else if (data.industryLandingItemDTO.map == null) {
        return (<div>None found</div>)
    } else return (<>
            <HeroDisplayWithImage backgroundimg={null} backgroundRep={'repeat-y'}>

                {data.industryLandingItemDTO.map((value) => (
                    <Box>
                        <Typography variant='h4' sx={{
                            pt: '2%',
                            display: 'flex',
                            justifyContent: "center"
                        }}>{value.title}</Typography>

                        <Grow in={true} timeout={4000} direction={"left"}>
                            <Grid container padding={{xs: "5px", md: "50px"}}
                                  justifyContent="space-around"
                                  alignItems="space-around"
                                  spacing={2}>
                                {value.itemList.map((value2) => (

                                    <Grid item xs={12} sm={9} md={6} lg={6} xl={4}>
                                        <Card item={value2.id} variant="outlined">
                                            {value.itemType === 'PROJECT' ? <ProjectCard projectData={value2}/> :
                                                <UserCard userData={value2}/>}
                                        </Card>

                                    </Grid>
                                ))}
                            </Grid>
                        </Grow>
                    </Box>

                ))}
            </HeroDisplayWithImage>
        </>

    )

}

import React, {useEffect, useState} from "react";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import {getGenres} from "../general/api.js";
import {
    Autocomplete,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    InputLabel,
    Stack,
    Typography
} from "@mui/material";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";


const ProjectFilterModal = ({isFilterOpen, setFilterOpen, handlePageReload, isFilterOn, setFilterOn}) => {

    const [genres, setGenres] = useState([]);

    let [filter, setFilter] = useState({
        "page": 0, "titleSearchText": "", "sortBy": "", "genres": []
    });

    let [genreFilter, setGenreFilter] = useState({
        "page": 0, "titleSearchText": "", "sortBy": "", "genres": []
    });

    const [isMasterFilterOn, setMasterFilterOn] = useState(false);

    function onClose() {

        setFilterOpen(false);
    }

    const handleFilterClose = () => {
        setFilterOpen(!isFilterOpen);
    }

    function updateProjectGenres(value) {

        for (let i = 0; i < value.length; i++) {
            value[i].orderBy = i;
        }
        genreFilter.genres = value;
        setGenreFilter({...genreFilter, genreFilter});

        if (genreFilter.genres.length > 0) {
            setMasterFilterOn(true);
        }
    }


    useEffect(() => {

        getGenres()
            .then(response => {
                setGenres(response.data);

            });

        const filterStore = JSON.parse(localStorage.getItem('projectFilter'));

        if (filterStore != null) {
            genreFilter.genres = filterStore.genres;
            setGenreFilter({...genreFilter, genreFilter});
        }

    }, [isFilterOpen]);

    const handleFilterSelect = () => {

        filter.genres = genreFilter.genres;

        localStorage.setItem('projectFilter', JSON.stringify(filter));
        setFilterOpen(false);
        setFilterOn(true);
        handlePageReload();

    }

    const handleMasterFilterToggle = () => {

        filter.genres = [];
        filter.page = 0;
        filter.sortBy = ""; // TODO prob wants to be default sort
        filter.titleSearchText = "";

        localStorage.setItem('projectFilter', JSON.stringify(filter));
        setFilterOpen(false);
        setFilterOn(false);
        handlePageReload();
        setMasterFilterOn(false)

    }


    return (
        <Dialog
            fullWidth
            maxWidth="md"
            open={isFilterOpen}
            onClose={() => onClose()}
        >
            <DialogTitle><Stack justifyContent="space-between" direction="row"><Typography>Set
                Filters {isMasterFilterOn} </Typography> {isMasterFilterOn ? <Button variant="contained"
                                                                                     color="primary"
                                                                                     onClick={handleMasterFilterToggle}>clear
                filter</Button> : <></>}
            </Stack></DialogTitle>
            <DialogContent>
                <Stack spacing={2}>
                    <InputLabel id="genres">genres</InputLabel>
                    <Autocomplete
                        onChange={(event, value) => updateProjectGenres(value)}
                        multiple
                        id="genres"
                        options={genres}
                        value={genreFilter.genres}
                        getOptionLabel={(option) => option.name}
                        getOptionValue={(option) => option.id}
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                        renderInput={(params) => (<TextField
                            {...params}
                            label="genres"
                            placeholder="select genres..."
                        />)}
                    />
                    <InputLabel id="sortOrder">sort order</InputLabel>
                    <Select
                        id="sortOrder"
                        value={filter.sort}
                    >
                        <MenuItem value={10}>Title</MenuItem>
                        <MenuItem value={20}>ScriptVender Order</MenuItem>
                        <MenuItem value={30}>Genre</MenuItem>
                        <MenuItem value={30}>Writer Location</MenuItem>
                    </Select>
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button variant="contained"
                        color="primary" onClick={handleFilterSelect}>Set Filter</Button>
                <Button variant="contained"
                        color="primary" onClick={handleFilterClose}>Cancel</Button>
            </DialogActions>
        </Dialog>
    );
}

export default ProjectFilterModal;
import React, {useContext, useState} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import {Stack} from "@mui/material";
import directing from "../directorschair.png";
import Box from "@mui/material/Box";
import {HeroDisplayStretch} from "../components/layout.js";
import Container from "@mui/material/Container";
import axios from "axios";
import properties from "../general/properties.js";
import SmallCenteredFormHeader from "../components/SmallCenteredFormHeader.js";
import fadein from "../fadein4.jpg";
import PasswordChange from "../account/PasswordChange.js";
import SmallActionButtons from "../components/SmallActionButtons.js";
import UserContext from "./UserContext.js";

export default function ChangePassword() {


    const {id} = useParams();

    const [passwordValidation, setPasswordValidation] = useState({message: '', error: false});

    const [confirmPasswordValidation, setConfirmPasswordValidation] = useState({message: '', error: false});

    let [password, setPassword] = useState({id: 0, password: ''});

    const {logoutUser} = useContext(UserContext);

    let navigate = useNavigate();

    const handleChange = (event) => {

        setPassword({...password, [event.target.name]: event.target.value})

        if (event.target.value !== '') {
            if (event.target.id === 'password') {
                setPasswordValidation({message: '', error: false})
            } else if (event.target.id === 'confirmPassword') {
                setConfirmPasswordValidation({message: '', error: false})
            }
        }
    }

    let save = (e) => {

        const url = properties.backendServer + '/sg/user/password/' + id;

        axios.put(url, password)
            .then(() => {
                logoutUser();
                navigate('/login');
            }).catch(err => {
            if (err.response.status === 400) {
                err.response.data.formErrors.forEach(v => {
                    if (v.field === 'password') {
                        setPasswordValidation({message: v.message, error: true});
                    } else if (v.field === 'confirmPassword') {
                        setConfirmPasswordValidation({message: v.message, error: true});
                    }

                });
            }
        });


        e.preventDefault();
    }

    let cancel = () => {
        console.log("id " + localStorage.getItem("profileId"))

        navigate('/userView/' + localStorage.getItem("profileId"));

    };

    return (<HeroDisplayStretch sx={{backgroundImage: `url(${directing})`}}>
        <Container maxWidth="xs" sx={{borderRadius: "5px"}}>
            <Box sx={{
                flexDirection: 'column',
                alignItems: 'center',
                backgroundColor: '#F0F8FF',
                padding: "20px",
                width: '100%'
            }}>
                <SmallCenteredFormHeader img={fadein} title={"Change Password"}/>
                <Stack gap={2}>
                    <PasswordChange user={password} onChange={handleChange}
                                    passwordValidation={passwordValidation}
                                    confirmPasswordValidation={confirmPasswordValidation}/>
                </Stack>
                <SmallActionButtons firstText={"Save"} firstFunction={save} secondText={"Cancel"}
                                    secondFunction={cancel} direction={"column"}/>

            </Box>
        </Container>
    </HeroDisplayStretch>);
}



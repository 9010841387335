import DialogTitle from "@mui/material/DialogTitle";
import {Dialog, DialogContent, Stack, Typography} from "@mui/material";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import React from "react";
import {transitionUp} from "../general/api.js";
import Button from "@mui/material/Button";
import Avatar from "@mui/material/Avatar";
import {useNavigate} from "react-router-dom";

export const ProfilePreview = ({author, open, setOpen}) => {

    const navigate = useNavigate();

    return (<Dialog
        open={open}
        TransitionComponent={transitionUp}
        keepMounted
        aria-describedby="logout"
    >
        <DialogTitle
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: '#F0F8FF',
                fontSize: 'medium'
            }}>{author.name}</DialogTitle>
        <DialogContent sx={{backgroundColor: '#F0F8FF'}}>
            <Container component="main" maxWidth="md" sx={{borderRadius: "5px", backgroundColor: '#F0F8FF'}}>
                <Box sx={{
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    backgroundColor: '#F0F8FF',
                    width: '100%'
                }}>

                    <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'center', pb: '5%'}}>
                        <Avatar alt={author.name} aria-label={author.name} sx={{width: 80, height: 80}}
                                src={author.imageFilePath}/>
                    </Box>

                    <Stack gap={2}>
                        <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                            <Typography>{author.country}</Typography>
                        </Box>

                        <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                            <Typography variant={"h6"}>{author.logline}</Typography>
                        </Box>
                    </Stack>

                    <Stack>
                        <Stack direction={"column"} gap={4} mt={4} mb={4}>
                            <Button fullWidth
                                    variant="contained"
                                    color="primary" onClick={() => navigate('/userView/' + author.id)}>View
                                Full Profile</Button>
                            <Button fullWidth
                                    variant="contained"
                                    color="primary" onClick={() => setOpen(false)}>Return</Button>
                        </Stack>
                    </Stack>

                </Box>
            </Container>
        </DialogContent>
    </Dialog>)
}
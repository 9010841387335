import React, {useState} from 'react';
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import {useNavigate} from 'react-router-dom';
import {Stack} from "@mui/material";
import axios from "axios";
import {HeroDisplayStretch} from "../components/layout";
import directing from "../directorschair.png";
import SmallCenteredFormHeader from "../components/SmallCenteredFormHeader.js";
import SmallActionButtons from "../components/SmallActionButtons";
import Box from "@mui/material/Box";
import properties from "../general/properties.js";
import TextField from "@mui/material/TextField";

const ResendActivationEmail = () => {

    const [isRequestFailed, setRequestFailed] = useState(false);
    const [email, setEmail] = useState('');
    const [requestFailedMessage, setRequestFailedMessage] = useState('');

    let navigate = useNavigate();

    const sendValidateEmail = () => {

        axios.create().post(properties.backendServer + '/op/user/activation/resend/' + email)
            .then(response => {
                if (response.data.message != null && response.data.message === 'Email doesn\'t exist. Please create account') {
                    setRequestFailedMessage(response.data.message);
                    setRequestFailed(true);
                } else if (response.data.message != null && response.data.message === 'please check email for validation link') {
                    setRequestFailedMessage(response.data.message + ', also check your spam folder');
                    setRequestFailed(true);
                } else {
                    navigate('/login/account%20validated');
                }

            })
            .catch((error) => {
                setRequestFailedMessage(error.response.data.message);
                setRequestFailed(true);
            });


    }

    const loginNavigate = () => {

        navigate('/login');

    }

    const handleChange = (event) => {
        setEmail(event.target.value);
    }


    return (<HeroDisplayStretch sx={{backgroundImage: `url(${directing})`}}>
        <Container component="main" maxWidth="xs" sx={{borderRadius: "5px"}}>
            <Box sx={{
                flexDirection: 'column',
                alignItems: 'center',
                backgroundColor: '#F0F8FF',
                padding: "20px",
                width: '100%'
            }}>
                <SmallCenteredFormHeader title={"Resend Activation Email"}/>

                <Stack gap={2} mb={2} sx={{color: 'gray'}}>
                    <TextField
                        variant="outlined"
                        required
                        fullWidth
                        id="email"
                        color="primary"
                        label="Email Address"
                        name="email"
                        autoFocus={true}
                        value={email}
                        onChange={handleChange}
                    />
                    {isRequestFailed &&
                        <Typography sx={{
                            paddingTop: "10px",
                            alignItems: "left",
                            color: 'mediumvioletred'
                        }}>{requestFailedMessage}</Typography>}


                </Stack>
                <SmallActionButtons firstText={"Resend Validation Email"} firstFunction={sendValidateEmail}
                                    direction={"column"} secondText={"Login"} secondFunction={loginNavigate}/>


            </Box>
            <ToastContainer autoClose={1500}/>
        </Container>
    </HeroDisplayStretch>);
}

export default ResendActivationEmail;

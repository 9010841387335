import React, {useState} from 'react';
import makeStyles from '@mui/styles/makeStyles';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {Box} from "@mui/material";
import {Navigate} from "react-router-dom";
import {ExpandMore} from "@mui/icons-material";
import PersonOffIcon from '@mui/icons-material/PersonOff';
import {CardContentWithBackgroundWithImage} from "../components/layout";

const useStyles = makeStyles((theme) => ({
    root: {
        minHeight: 400,
    },
    media: {
        paddingTop: '5%',
        cursor: 'pointer',
        height: 300,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        borderRadius: 6,
        backgroundColor: '#BDBDBD',

        "&:hover": {
            opacity: .8,
        },
    }, header: {
        backgroundColor: '#F0F8FF',
    },
    title: {
        cursor: 'pointer',
    },
    mainContent: {
        minHeight: 80,
        backgroundColor: '#F0F8FF'
    }
}));


const MyOptions = [
    "Share via Whatsapp",
    "Send Email",
    "Download",
    "Save as PDF",
];

export default function UserCard({userData}) {
    const classes = useStyles();
    const [expanded, setExpanded] = React.useState(false);

    const [anchorEl, setAnchorEl] = useState(null);

    const [isProjectView, setProjectView] = useState(false);

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    const open = Boolean(anchorEl);

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleSetProjectView = () => {
        setProjectView(true);
    }

    if (isProjectView) {
        return <Navigate to={`/userView/${userData.id}`}/>
    } else {
        return (
            <Box className={classes.root}>
                <CardHeader
                    className={classes.header}
                    avatar={
                        <Avatar alt={userData.name} src={userData.imageFilePath}/>
                    }
                    title={userData.name}
                    classes={{
                        title: classes.title
                    }}
                    subheader={userData.country}
                />
                <CardContentWithBackgroundWithImage backgroundimg={userData.backgroundProfileImage}
                                                    className={classes.media}
                                                    onClick={handleSetProjectView}>
                    <Typography sx={{p: '5%', color: 'white'}} variant="h5">{userData.logline}</Typography>
                </CardContentWithBackgroundWithImage>
                <CardContent className={classes.mainContent}>
                    <Typography variant="body2" color="textSecondary" component="p">
                        {userData.notes}
                    </Typography>
                </CardContent>
                <CardActions disableSpacing>
                    <IconButton aria-label="add to favorites" size="large">
                        <FavoriteIcon/>
                    </IconButton>
                    <IconButton aria-label="share" size="large">
                        <ShareIcon/>
                    </IconButton>
                    {userData.enabled ? <></> : <PersonOffIcon/>}
                    <ExpandMore
                        expand={expanded}
                        onClick={handleExpandClick}
                        aria-expanded={expanded}
                        aria-label="show more"
                    >
                        <ExpandMoreIcon/>
                    </ExpandMore>
                </CardActions>
                <Collapse in={expanded} timeout="auto" unmountOnExit>
                    <CardContent>
                        <Typography paragraph>{userData.notes}</Typography>
                    </CardContent>
                </Collapse>
            </Box>
        );
    }
}

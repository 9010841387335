import IndustryLanding from "./industryLanding";

export default function IndustryLandingWrapper() {

    return (<>
            <IndustryLanding url='/sg/industry/landing/'/>
        </>

    )


}
import {Link} from "react-router-dom";
import {List, ListItemButton, ListItemIcon, ListItemText} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info.js";
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import LoginIcon from '@mui/icons-material/Login';

const UnauthenticatedMenu = (props) => {

    return (<List onClick={() => props.handleDrawerClose} dense={true}>
        <ListItemButton key={'about'} component={Link} to="/about">
            <ListItemIcon>{<InfoIcon/>}</ListItemIcon>
            <ListItemText primary={'About'}/>
        </ListItemButton>
        <ListItemButton key={'login'} component={Link} to="/login">
            <ListItemIcon>{<LoginIcon/>}</ListItemIcon>
            <ListItemText primary={'Login'}/>
        </ListItemButton>
        <ListItemButton key={'account'} component={Link} to="/user">
            <ListItemIcon>{<PersonAddIcon/>}</ListItemIcon>
            <ListItemText primary={'Create Account'}/>
        </ListItemButton>
    </List>)

}

export default UnauthenticatedMenu
import {styled} from "@mui/material/styles";
import Box from "@mui/material/Box";
import {CardContent} from "@mui/material";

export const HeroDisplay = styled(Box)(({theme}) => ({
    paddingLeft: '5%',
    paddingRight: '5%',
    // paddingTop: theme.spacing(10),
    height: '100vh',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundAttachment: 'fixed',
    backgroundColor: 'black',
    color: '#F0F8FF',

}));

export const HeroDisplayStretch = styled(Box)(({theme}) => ({
    paddingLeft: '5%',
    paddingRight: '5%',
    paddingTop: theme.spacing(10),
    paddingBottom: theme.spacing(10),
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundAttachment: 'fixed',
    color: '#F0F8FF',

}));

export const HeroDisplayWithImage = styled(Box, {
    shouldForwardProp: (propName) => {
        return propName !== "backgroundimg" || "backgroundRep"
    }
})(({backgroundimg}) => ({
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'repeat-y',
    backgroundImage: (backgroundimg == null || backgroundimg == "") ? 'none' : backgroundimg,
    backgroundColor: 'black',
    color: '#F0F8FF',
    minHeight: '100vh',
}));

export const CardContentWithBackgroundWithImage = styled(CardContent, {
    shouldForwardProp: (propName) => {
        return propName !== "backgroundimg" || "backgroundRep"
    }
})(({backgroundimg}) => ({
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundImage: backgroundimg,
    color: '#F0F8FF',
}));





import List from "@mui/material/List";
import {Link} from "react-router-dom";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import React from "react";
import {ListItemButton} from "@mui/material";
import HomeIcon from '@mui/icons-material/Home';
import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined';
import EmojiEventsOutlinedIcon from '@mui/icons-material/EmojiEventsOutlined';
import RecommendOutlinedIcon from '@mui/icons-material/RecommendOutlined';
import {FolderOpen, PersonSearch} from "@mui/icons-material";

const IndustryMenu = (props) => {

    return (
        <>
            <List onClick={() => props.handleDrawerClose} dense={true}>
                <ListItemButton onClick={props.handleDrawerClose} key={'industryLanding'} component={Link}
                                to="/industryLanding">
                    <ListItemIcon>{<HomeIcon/>}</ListItemIcon>
                    <ListItemText primary={'Your landing page'}/>
                </ListItemButton>
                <ListItemButton onClick={props.handleDrawerClose} key={'industryRecommendedProjects'} component={Link}
                                to="/industryRecommendedProjects">
                    <ListItemIcon>{<RecommendOutlinedIcon/>}</ListItemIcon>
                    <ListItemText primary={'Recommended Projects'}/>
                </ListItemButton>
                <ListItemButton onClick={props.handleDrawerClose} key={'industryRecommendedWriters'} component={Link}
                                to="/industryRecommendedWriters">
                    <ListItemIcon>{<RecommendOutlinedIcon/>}</ListItemIcon>
                    <ListItemText primary={'Recommended Writers'}/>
                </ListItemButton>
                <ListItemButton onClick={props.handleDrawerClose} key={'industryFavouriteProjects'} component={Link}
                                to="/industryFavouriteProjects">
                    <ListItemIcon>{<FavoriteBorderOutlinedIcon/>}</ListItemIcon>
                    <ListItemText primary={'Favourite Projects'}/>
                </ListItemButton>
                <ListItemButton onClick={props.handleDrawerClose} key={'industryFavouriteWriters'} component={Link}
                                to="/industryFavouriteWriters">
                    <ListItemIcon>{<FavoriteBorderOutlinedIcon/>}</ListItemIcon>
                    <ListItemText primary={'Favourite Writers'}/>
                </ListItemButton>
                <ListItemButton onClick={props.handleDrawerClose} key={'industryFinalistsProjects'} component={Link}
                                to="/industryFinalistsProjects">
                    <ListItemIcon>{<EmojiEventsOutlinedIcon/>}</ListItemIcon>
                    <ListItemText primary={'Finalist Projects'}/>
                </ListItemButton>
                <ListItemButton key={'listProjects'} component={Link} to="/sg/projects/0">
                    <ListItemIcon>{<FolderOpen/>}</ListItemIcon>
                    <ListItemText primary={'All visible projects'}/>
                </ListItemButton>
                <ListItemButton key={'listUsers'} component={Link} to="/sg/users/0">
                    <ListItemIcon>{<PersonSearch/>}</ListItemIcon>
                    <ListItemText primary={'All visible writers'}/>
                </ListItemButton>

            </List>
        </>
    )


}

export default IndustryMenu
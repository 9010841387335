import React, {Fragment, useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import axios from "axios";
import Project from "./Project.js";
import {getProjectStructure} from "../general/api.js";
import properties from "../general/properties.js";
import Loading from "../general/Loading.js";

const ProjectWrapper = ({projectId}) => {

    const {id} = useParams();

    let [projectData, setProjectData] = useState(getProjectStructure());

    let [extractData, setExtractData] = useState({});

    const [isProjectLoading, setProjectLoading] = useState(true);
    const [isExtractLoading, setExtractLoading] = useState(true);


    function getBackgroundImage(image) {

        return 'linear-gradient( rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url(' + image + ')';

    }

    function toggleFavourite() {


        axios({
            method: 'post', url: properties.backendServer + '/sg/project/' + projectData.id + '/favourites',
        }).then(() => {
            let project = projectData.favourite = !projectData.favourite;
            setProjectData({...projectData, project})
        }).catch(error => {
            console.log(error);
        });


    }

    useEffect(() => {

        projectId = (projectId == null) && id;

        axios({
            method: 'get',
            url: properties.backendServer + '/sg/project/' + projectId,
        }).then(response => {
            setProjectData(response.data);
            setProjectData(prevState => ({
                ...prevState,
                imageFilePath: getBackgroundImage(response.data.imageFilePath)
            }));

            if (response.data.extract.showExtract) {
                axios({
                    method: 'get',
                    url: properties.backendServer + '/sg/project/screenplay/extract/' + projectId,
                }).then(response => {
                    setExtractData(response.data);
                    setExtractLoading(false);
                });
            } else {
                setExtractLoading(false);
            }

            setProjectLoading(false);
        });

    }, []);

    if (isProjectLoading || isExtractLoading) {
        return <Loading/>
    } else return (<Fragment>
        <Project projectData={projectData} id={id} toggleFavourite={toggleFavourite} scrollBy={0}
                 extractData={extractData}/>
    </Fragment>);
};

export default ProjectWrapper;
import React, {useState} from 'react';
import 'react-toastify/dist/ReactToastify.css';
import Container from "@mui/material/Container";
import {useParams} from "react-router-dom";
import SmallActionButtons from "../components/SmallActionButtons.js";
import Box from "@mui/material/Box";
import {Dialog, DialogContent, Stack, Typography} from "@mui/material";
import DialogTitle from "@mui/material/DialogTitle";
import {transitionUp} from "../general/api.js";
import TextField from "@mui/material/TextField";
import axios from "axios";
import properties from "../general/properties.js";

const QuotesModal = ({
                         open,
                         setOpen,
                         quotesList,
                         setQuotesList,
                         setQuoteEntry,
                         quoteEntry
                     }) => {

    const [quoteValidation, setQuoteValidation] = useState({message: '', error: false});
    const {id} = useParams();


    const handleChange = (event) => {

        setQuoteEntry({...quoteEntry, [event.target.name]: event.target.value})

        setQuoteValidation({message: '', error: false})


    }


    let save = (e) => {

        const url = properties.backendServer + '/sg/project/quote/' + id;
        quoteEntry["projectId"] = id;
        setQuoteEntry(quoteEntry)

        if (quoteEntry.id === null || quoteEntry.id === 0) {
            axios.post(url, quoteEntry)
                .then((result) => {
                    setQuotesList(() => [...quotesList, result.data]);
                    setOpen(false);
                }).catch(err => {
                if (err.response.status === 400) {
                    err.response.data.formErrors.forEach(v => {
                        if (v.field === 'quote') {
                            setQuoteValidation({message: v.message, error: true});
                        }

                    });
                }
            });
        } else {
            axios.put(url, quoteEntry)
                .then((result) => {
                    const quotesEntryList2 = quotesList.filter(obj => obj.id !== quoteEntry.id);
                    setQuotesList(() => [...quotesEntryList2, result.data]);
                    setOpen(false);
                }).catch(err => {
                if (err.response.status === 400) {
                    err.response.data.formErrors.forEach(v => {
                        if (v.field === 'competition') {
                            setQuoteValidation({message: v.message, error: true});
                        }
                    });
                }
            });
        }


        e.preventDefault();
    }


    return (
        <Dialog
            open={open}
            TransitionComponent={transitionUp}
            keepMounted
            aria-describedby="quote"
            fullWidth
        >
            <DialogTitle
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: '#F0F8FF'
                }}>Quote</DialogTitle>
            <DialogContent sx={{backgroundColor: '#F0F8FF'}}>
                <Container maxWidth="sm" sx={{borderRadius: "5px"}}>
                    <Box sx={{
                        flexDirection: 'column',
                        alignItems: 'center',
                        backgroundColor: '#F0F8FF',
                        padding: "20px",
                        width: '100%',
                        color: 'black'
                    }}>
                        <Typography sx={{mb: '5px'}} color={"red"}>{quoteValidation.message}</Typography>
                        <Stack gap={2}>
                            <TextField
                                name="quote"
                                variant="outlined"
                                multiline
                                rows="10"
                                required
                                fullWidth
                                id="quote"
                                label="Quote"
                                onChange={handleChange}
                                value={quoteEntry.quote}
                                // error={titleError}
                                // helperText={titleMessage}
                            />
                            <TextField
                                name="name"
                                variant="outlined"
                                required
                                fullWidth
                                id="name"
                                label="Name"
                                onChange={handleChange}
                                value={quoteEntry.name}
                                // error={titleError}
                                // helperText={titleMessage}
                            />
                            <TextField
                                name="organisation"
                                variant="outlined"
                                required
                                fullWidth
                                id="organisation"
                                label="Organisation"
                                onChange={handleChange}
                                value={quoteEntry.organisation}
                                // error={titleError}
                                // helperText={titleMessage}
                            />

                        </Stack>
                        <SmallActionButtons firstText={"Save"} firstFunction={save} secondText={"Return"}
                                            secondFunction={() => setOpen(false)} direction={"column"}/>

                    </Box>
                </Container>
            </DialogContent>
        </Dialog>
    );
}

export default QuotesModal;

import React from "react";
import {Box, Grid, Stack, Typography} from "@mui/material";
import shooting from "../emocafeguy.png";
import Container from "@mui/material/Container";
import DoneIcon from '@mui/icons-material/Done';
import {AnimationOnScroll} from 'react-animation-on-scroll';
import "animate.css/animate.min.css";


const Visibility2 = () => {


    return (<Box sx={{
            backgroundColor: "white",
            width: '100%',
            paddingBottom: '40px',
            paddingLeft: {sm: '0%', lg: '10%'},
            paddingRight: {sm: '0%', lg: '10%'}
        }}>

            <Grid container spacing={2} sx={{backgroundColor: 'white'}}>
                <Grid item xs={12} md={6}>
                    <Box>

                        <Typography variant={'h4'} p={'20px'} color="primary">Gain insight into industry activity
                            related to your projects</Typography>
                        <AnimationOnScroll animateIn="animate__fadeInRightBig">

                            <Stack direction="column" pl={'10px'} gap={3} pt={'10px'}>
                                <Stack direction="column" pl={'20px'} gap={3}>
                                    <Stack direction="row" alignItems="left" gap={3}>
                                        <DoneIcon color="primary"/>
                                        <Typography variant="body1">View the number of overall visits to your
                                            project</Typography>
                                    </Stack>
                                    <Stack direction="row" alignItems="left" gap={3}>
                                        <DoneIcon color="primary"/>
                                        <Typography variant="body1">Check out the number of times your project comes up
                                            in
                                            a search, but isn't clicked on.</Typography>
                                    </Stack>
                                    <Stack direction="row" alignItems="left" gap={3}>
                                        <DoneIcon color="primary"/>
                                        <Typography variant="body1">If you enable online reads, gain valuable insights
                                            into engagement or page disengagement stats.</Typography>
                                    </Stack>
                                </Stack>
                            </Stack>
                        </AnimationOnScroll>
                    </Box>

                </Grid>
                <Grid item xs={12} md={6}>
                    <Container maxWidth="sm">
                        <Box component={"img"} src={shooting}
                             sx={{width: "100%", paddingBottom: "20px;", borderRadius: "5px"}}
                             alt="Shooting a script"/>
                    </Container>
                </Grid>
            </Grid>
        </Box>


    )
}

export default Visibility2
import {HeroDisplayWithImage} from "../components/layout.js";
import Box from "@mui/material/Box";
import React from "react";
import {CircularProgress, Stack} from "@mui/material";

let Loading = () => {


    return (<HeroDisplayWithImage backgroundimg={null}>
        <Box sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
            height: '100vh',
        }}>
            <Stack direction='column' spacing={10}>
                <CircularProgress/>
                <CircularProgress/>
                <CircularProgress/>
            </Stack>
        </Box>
    </HeroDisplayWithImage>);

}

export default Loading;
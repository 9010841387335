import TextField from "@mui/material/TextField";
import {InputAdornment} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import {Visibility, VisibilityOff} from "@mui/icons-material";
import React, {useState} from "react";

const PasswordChange = ({user, onChange, passwordValidation, confirmPasswordValidation}) => {

    let [isShowPassword, setShowPassword] = useState(false);

    let [isConfirmShowPassword, setConfirmShowPassword] = useState(false);

    return (
        <>
            <TextField
                variant="outlined"
                required
                fullWidth
                name="password"
                id="password"
                onChange={onChange}
                value={user.password}
                type={isShowPassword ? "text" : "password"}
                InputProps={{
                    endAdornment: <InputAdornment position="end">
                        <IconButton
                            onClick={() => setShowPassword(!isShowPassword)}
                        >
                            {isShowPassword ? <Visibility/> : <VisibilityOff/>}
                        </IconButton>
                    </InputAdornment>,
                }}
                label="Password"
                error={passwordValidation.error}
                helperText={passwordValidation.message}
            />
            <TextField
                variant="outlined"
                required
                fullWidth
                name="confirmPassword"
                id="confirmPassword"
                onChange={onChange}
                value={user.confirmPassword}
                type={isConfirmShowPassword ? "text" : "password"}
                error={confirmPasswordValidation.error}
                helperText={confirmPasswordValidation.message}
                InputProps={{
                    endAdornment: <InputAdornment position="end">
                        <IconButton
                            onClick={() => setConfirmShowPassword(!isConfirmShowPassword)}
                        >
                            {isConfirmShowPassword ? <Visibility/> : <VisibilityOff/>}
                        </IconButton>
                    </InputAdornment>,
                }}
                label="Confirm password"
            />
        </>
    )
}

export default PasswordChange;
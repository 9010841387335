import {Stack} from "@mui/material";
import Quote from "./Quote.js";

const QuoteWrapper = ({quotes}) => {

    return (
        <>
            <Stack spacing={3}>
                {quotes.map(
                    (o, id) => <Quote key={id} quote={o}/>
                )}
            </Stack>
        </>
    )
}

export default QuoteWrapper;
import React, {useState} from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {Checkbox, Snackbar, Tooltip} from "@mui/material";
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import axios from "axios";
import {Navigate} from "react-router-dom";
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import {styled} from "@mui/material/styles";
import properties from "../general/properties.js";
import ProjectAvatarGroup from "../user/ProjectAvatarGroup";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import {CardContentWithBackgroundWithImage} from "../components/layout.js";

const useStyles = makeStyles((theme) => ({
    root: {
        minHeight: 400,
    },
    media: {
        paddingTop: '5%',
        cursor: 'pointer',
        height: 300,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        borderRadius: 6,
        backgroundColor: '#BDBDBD',

        "&:hover": {
            opacity: .8,
        },
    }, header: {
        backgroundColor: '#F0F8FF',
    },
    title: {
        cursor: 'pointer',
    },
    mainContent: {
        minHeight: 80,
        backgroundColor: '#F0F8FF',
        color: 'black'
    }
}));

const ExpandMore = styled((props) => {
    const {expand, ...other} = props;
    return <IconButton {...other} />;
})(({theme, expand}) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
    }),
}));

export default function ProjectCard({projectData}) {
    const classes = useStyles();

    const [isProjectView, setProjectView] = useState(false);

    const [isOpenFav, setOpenFav] = useState(false);

    const [projectData2, setProjectData2] = useState(projectData);

    const handlePopupClose = () => {
        setOpenFav(false);
    };

    const handleSetProjectView = () => {
        setProjectView(true);
    }

    function toggleFavourite(data) {

        axios({
            method: 'post', url: properties.backendServer + '/sg/project/' + data.id + '/favourites',
        }).then(() => {
            let project = projectData.favourite = !projectData.favourite;
            setProjectData2({...data, project})
        }).catch(error => {
            console.log(error);
        });


    }

    const [expanded, setExpanded] = React.useState(false);

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    if (isProjectView) {
        return <Navigate to={`/projectView/${projectData.id}`}/>
    } else {
        return (<>
            <Card>
                <CardHeader className={classes.header}

                            avatar={<ProjectAvatarGroup authors={projectData.authors}/>}

                            title={projectData.title}
                            classes={{
                                title: classes.title
                            }}
                            subheader={projectData.genreList}
                />
                <CardContentWithBackgroundWithImage backgroundimg={projectData.imageFilePathWithOpacity}
                                                    className={classes.media}
                                                    onClick={handleSetProjectView}>
                    <Typography sx={{p: '5%', color: 'white'}} variant="h5">{projectData.logline}</Typography>
                </CardContentWithBackgroundWithImage>
                <CardContent className={classes.mainContent}>
                    <Typography variant="body2" component="p">
                        {projectData.pitchEllipsis}
                    </Typography>
                </CardContent>
                <CardActions disableSpacing>
                    <Tooltip title="add / remove from your favourites">
                        <IconButton aria-label="add to favorites" size="large"
                                    onClick={() => toggleFavourite(projectData)}>
                            <Checkbox checked={projectData2.favourite} icon={<FavoriteBorderIcon/>}
                                      checkedIcon={<FavoriteIcon sx={{color: "red"}}/>}/>
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="download available"
                             sx={{display: projectData2.showPdfDownload ? "flex" : 'none'}}>
                        <IconButton aria-label="download available" size="large">
                            <FileDownloadIcon sx={{color: "green"}}/>
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="extract available"
                             sx={{display: projectData2.extract.showExtract ? "flex" : 'none'}}>
                        <IconButton aria-label="download available" size="large">
                            <MenuBookIcon sx={{color: "green"}}/>
                        </IconButton>
                    </Tooltip>
                    {projectData.pitch != null &&
                        <ExpandMore
                            expand={expanded}
                            onClick={handleExpandClick}
                            aria-expanded={expanded}
                            aria-label="show more"
                        >
                            <ExpandMoreIcon/>
                        </ExpandMore>
                    }
                </CardActions>
                <Collapse in={expanded} timeout="auto" unmountOnExit>
                    <CardContent>
                        <Typography variant="body1"
                                    style={{whiteSpace: 'pre-line'}}>
                            {projectData.pitch}
                        </Typography>
                    </CardContent>
                </Collapse>

            </Card>
            <Snackbar
                open={isOpenFav}
                autoHideDuration={6000}
                onClose={handlePopupClose}
                message="Favourite not saved"
            />
        </>);
    }
}
import {Stack} from "@mui/material";
import DisplayCard from "./DisplayCard.js";
import StorefrontIcon from '@mui/icons-material/Storefront';
import MenuBookIcon from "@mui/icons-material/MenuBook.js";
import {VisibilityOff} from "@mui/icons-material";

const Features = () => {

    return (
        <Stack gap={2} pt={10} pb={10} spacing={2} backgroundColor={"white"} direction={{xs: 'column', sm: 'row'}}>

            <DisplayCard
                text={"Screenplay ready to ship to market?\n\nPut your work in the hands of registered industry figures, either directly or through representation."}
                width={"33%"}
                icon={<StorefrontIcon fontSize="large" color="primary"/>}/>
            <DisplayCard
                text={"Craft a Screenplay project. Decorate with a Netflix style hero image backdrop. Tease interest by uploading extracts into your online screenplay slider. "}
                width={"33%"}
                icon={<MenuBookIcon fontSize="large" color="primary"/>}/>
            <DisplayCard
                text={"Your work, your choice. ScriptVender is a private network. You get fine grained control over visibility of your profile and projects. Grant the rights to download the full screenplay, part, or none."}
                width={"33%"}
                icon={<VisibilityOff fontSize="large" color="primary"/>}/>
        </Stack>


    )


}

export default Features;
import React, {useEffect} from "react";
import * as PropTypes from "prop-types";
import {Stack} from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import makeStyles from "@mui/styles/makeStyles";
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import LoadingButton from '@mui/lab/LoadingButton';

const SaveCancel = (props) => {

    const useStyles = makeStyles((theme) => ({
        saveCancel: {
            justifyContent: 'space-evenly',
            marginTop: '20px',
            marginBottom: '20px'
        }
    }));

    const classes = useStyles();

    useEffect(() => {
        return () => {

        };
    }, [props.saveEnabled]);


    return (
        <>
            <Stack xs={12} md={6} direction="row" className={classes.saveCancel}>
                <Box xs={12} md={6}>
                    <LoadingButton
                        fullWidth
                        disabled={props.saveEnabled}
                        variant="contained"
                        color="primary"
                        loading={props.loading}
                        onClick={(e) => {
                            props.setLoading(true);
                            props.save(e)
                        }}
                        startIcon={<SaveIcon/>}
                    >Save
                    </LoadingButton>
                </Box>
                <Box xs={12} md={6}>
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        onClick={props.cancel}
                        startIcon={<CancelIcon/>}
                    >Cancel
                    </Button>
                </Box>
            </Stack>
        </>
    )
}

SaveCancel.propTypes = {
    save: PropTypes.func,
    cancel: PropTypes.func
};

export default SaveCancel;
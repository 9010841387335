import React, {useEffect, useState} from 'react';
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import {useNavigate, useParams} from 'react-router-dom';
import {Stack} from "@mui/material";
import axios from "axios";
import {HeroDisplayStretch} from "../components/layout";
import directing from "../directorschair.png";
import SmallCenteredFormHeader from "../components/SmallCenteredFormHeader.js";
import SmallActionButtons from "../components/SmallActionButtons";
import Box from "@mui/material/Box";
import properties from "../general/properties.js";
import login from "./Login.js";

const ValidateEmail = () => {

    const {uuid} = useParams();

    const [isRequestFailed, setRequestFailed] = useState(false);

    let navigate = useNavigate();


    const validateEmail = () => {

        axios.create().put(properties.backendServer + '/op/user/activation/' + uuid)
            .then(response => {

                navigate('/login/account%20validated');

            })
            .catch((error) => {
                setRequestFailed(true);
                navigate('/validateEmail');
            });


    }

    useEffect(() => {
        validateEmail();
    }, []);


    return (<HeroDisplayStretch sx={{backgroundImage: `url(${directing})`}}>
        <Container component="main" maxWidth="xs" sx={{borderRadius: "5px"}}>
            <Box sx={{
                flexDirection: 'column',
                alignItems: 'center',
                backgroundColor: '#F0F8FF',
                padding: "20px",
                width: '100%'
            }}>
                <SmallCenteredFormHeader title={"Validation failed"}/>

                <Stack gap={2} mb={2} sx={{color: 'gray'}}>
                    {isRequestFailed &&
                        <Typography sx={{paddingTop: "10px", alignItems: "left", color: 'mediumvioletred'}}>email
                            validation failed</Typography>}

                </Stack>
                <SmallActionButtons firstText={"Retry"} firstFunction={login} secondText={"Resend Validation Email"}
                                    firstFunction={login}
                                    direction={"column"}/>
            </Box>
            <ToastContainer autoClose={1500}/>
        </Container>
    </HeroDisplayStretch>);
}

export default ValidateEmail;

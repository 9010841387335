import {createContext, useState} from "react";


const UserContext = createContext({});

export function UserProvider({children}) {

    const addUser = () => {

        userLight.imageFilePath = localStorage.getItem("profileImg");
        userLight.backgroundProfileImage = localStorage.getItem('backgroundProfileImage');
        userLight.profileId = localStorage.getItem('profileId');
        userLight.industry = localStorage.getItem('industry');
        userLight.admin = localStorage.getItem('admin');
        userLight.authenticated = localStorage.getItem('authenticated') === 'true';

        setUserLight({...userLight, userLight})

    }

    const logoutUser = () => {

        localStorage.clear();
        addUser();

    }

    const [userLight, setUserLight] = useState({
        profileId: localStorage.getItem("profileId"),
        name: '',
        imageFilePath: localStorage.getItem("profileImg"),
        backgroundProfileImage: localStorage.getItem('backgroundProfileImage'),
        authenticated: localStorage.getItem('authenticated')
    });

    return (
        <UserContext.Provider value={{userLight, addUser, logoutUser}}>{children}</UserContext.Provider>
    )

}

export default UserContext;
import {Pagination, Stack} from "@mui/material";
import React from "react";

const SgPagination = ({handlePageChange, pageTotal, page}) => {

    return (
        pageTotal > 0 && <Stack spacing={2} sx={{bgcolor: '#F0F8FF', p: 5, mt: 10, br: 5, borderRadius: 2}}>
            <Pagination showFirstButton showLastButton page={page + 1}
                        onChange={handlePageChange} count={pageTotal} variant="outlined"
                        color="primary"/>
        </Stack>
    )

}

export default SgPagination
import React, {useState} from 'react';
import Container from '@mui/material/Container';
import directing from "../directorschair.png";
import {Stack} from "@mui/material";
import fadein from "../fadein4.jpg";
import axios from "axios";
import {Navigate, useNavigate} from "react-router-dom";
import {HeroDisplayStretch} from "../components/layout.js";
import SmallCenteredFormHeader from "../components/SmallCenteredFormHeader.js";
import SmallActionButtons from "../components/SmallActionButtons.js";
import Box from "@mui/material/Box";
import PasswordChange from "./PasswordChange.js";
import EmailAndName from "./EmailAndName.js";
import properties from "../general/properties.js";
import Typography from "@mui/material/Typography";

let SignUp = () => {

    let [user, setUser] = useState({name: '', lastName: '', email: '', password: '', confirmPassword: ''});
    const [emailValidation, setEmailValidation] = useState({message: '', error: false});
    const [passwordValidation, setPasswordValidation] = useState({message: '', error: false});
    const [confirmPasswordValidation, setConfirmPasswordValidation] = useState({message: '', error: false});
    const [nameValidation, setNameValidation] = useState({message: '', error: false});
    const [isRequestFailed, setRequestFailed] = useState(false);
    const [requestFailedMessage, setRequestFailedMessage] = useState('');


    let [isCreated, setCreated] = useState(false);

    const handleChange = (event) => {

        setUser({...user, [event.target.name]: event.target.value})

        if (event.target.value !== '') {

            setRequestFailed(false);
            if (event.target.id === 'name') {
                setNameValidation({message: '', error: false})
            } else if (event.target.id === 'email') {
                setEmailValidation({message: '', error: false})
            } else if (event.target.id === 'password') {
                setPasswordValidation({message: '', error: false})
            } else if (event.target.id === 'confirmPassword') {
                setConfirmPasswordValidation({message: '', error: false})
            }
        }
    }

    let navigate = useNavigate();

    const goToLogin = () => {

        navigate('/login');
    }

    let save = (event) => {


        axios.create().post(properties.backendServer + '/op/user', user)
            .then(result => {
                setCreated(true);
                return result.data
            })
            .then(({message}) => {
                if (!isCreated) {
                    console.log("message" + message);
                }
            })
            .catch(err => {
                if (err.message != null && err.message === 'Network Error') {
                    setRequestFailedMessage('network error');
                    setRequestFailed(true);
                }
                if (err.response != null && err.response.status === 400) {
                    err.response.data.formErrors.forEach(v => {

                        if (v.field === 'email') {
                            setEmailValidation({message: v.message, error: true});
                        } else if (v.field === 'password') {
                            setPasswordValidation({message: v.message, error: true});
                        } else if (v.field === 'name') {
                            setNameValidation({message: v.message, error: true});
                        } else if (v.field === 'confirmPassword') {
                            setConfirmPasswordValidation({message: v.message, error: true});
                        }

                    });
                }
                console.error(err);
            })

        event.preventDefault();
    }

    if (isCreated) {
        return <Navigate to="/login/please%20check%20email%20for%20validation%20link"/>
    }

    return (
        <HeroDisplayStretch sx={{backgroundImage: `url(${directing})`}}>
            <Container maxWidth="xs" sx={{borderRadius: "5px"}}>
                <Box sx={{
                    flexDirection: 'column',
                    alignItems: 'center',
                    backgroundColor: '#F0F8FF',
                    padding: "20px",
                    width: '100%'
                }}>
                    <SmallCenteredFormHeader img={fadein} title={"Create an account"}/>
                    <Stack gap={2}>
                        <EmailAndName user={user} handleChange={handleChange} emailValidation={emailValidation}
                                      setEmailValidation={setEmailValidation} nameValidation={nameValidation}
                                      setNameValidation={setNameValidation}/>
                        <PasswordChange user={user} onChange={handleChange} passwordValidation={passwordValidation}
                                        confirmPasswordValidation={confirmPasswordValidation}/>
                        {isRequestFailed &&
                            <Typography sx={{
                                paddingTop: "10px",
                                alignItems: "left",
                                color: 'mediumvioletred'
                            }}>{requestFailedMessage}</Typography>}
                    </Stack>
                    <SmallActionButtons firstText={"Create Account"} firstFunction={save} secondText={"Cancel"}
                                        secondFunction={goToLogin} direction={"column"}/>

                </Box>
            </Container>
        </HeroDisplayStretch>);

}

export default SignUp;
